import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import App from '@pages/App'
import { render } from 'react-dom'

import TagManager from 'react-gtm-module'
import { ThemeProvider } from 'styled-components'
import theme from './theme/variables'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID
}

// ### Cookiebot
// https://www.cookiebot.com/de/developer/
// https://github.com/yeutech-lab/react-cookiebot
/**
 * <script id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="711ef6a7-8495-4dc5-b724-583ccf8ecbb7" data-blockingmode="auto" type="text/javascript"></script>
 */
window.addEventListener('load', function () {
  setTimeout(() => {
    TagManager.initialize(tagManagerArgs)
  }, 5000)
})
const rootElement = document.getElementById('root')
/* if (rootElement.hasChildNodes()) {
  hydrate(
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>,
    rootElement
  )
} else { */
render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  rootElement
)
//}
