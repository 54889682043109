import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/agentur/linkedinrecruiting.json'
import Content from '@content/leistungen/linkedinrecruiting.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))

const LinkedinRecruiting = () => {
  return (
    <>
      <SEOHeader id="lp-linkedinrecruiting" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Warum LinkedIn Recruiting?</h2>
          <br />
          <p>
            LinkedIn Recruiting bietet aufgrund seiner Ausrichtung auf den
            berufsbezogenen Austausch und Schaffung beruflicher Beziehungen eine
            exklusive Plattform, um potenzielle Kandidaten zu suchen, zu filtern
            und direkt mit diesen in Kontakt zu treten. Aktives Recruiting auf
            LinkedIn wird bereits zu einem der wichtigen Instrumente der
            Personalbeschaffung gezählt und legt das Fundament einer
            zielführenden Talentgewinnung. Überzeugend beim LinkedIn Recruiting
            ist, dass Ihnen mehrere Wege für die Personalakquise offenstehen. So
            können Sie beispielsweise die Stellenanzeigen beim LinkedIn
            Recruiting nutzen, direkt selbst passende Fachkräfte filtern und
            diese über die Chat-Nachrichten persönlich anschreiben. Bedenken
            Sie, dass vor allem die Generation Z heute digital lebt. Sprechen
            Sie diese in ihrem „natürlichen“ Umfeld an, um sich mit einer
            starken Personalstruktur am Markt langfristig zu etablieren. Denn
            eins ist klar: Der demografische Wandel und der Fachkräftemangel
            werden über den zukünftigen Erfolg von Unternehmen entscheiden. Mit
            LinkedIn Recruiting sind Sie Ihren Mitbewerbern einen Schritt
            voraus.
          </p>
          <br />
          <h2>Welche Vorteile hat Recruiting auf LinkedIn?</h2>
          <br />
          <p>
            LinkedIn bietet für das Recruiting einige attraktive Funktionen, die
            die Personalsuche erleichtern und langfristig optimieren. Ein
            entscheidender Vorteil zu anderen Maßnahmen ist der große Pool an
            potenziellen Kandidaten, den Sie ausschöpfen können – und das
            weltweit.
          </p>
          <p>
            Nutzen Sie die Vorteile von LinkedIn Recruiting für Ihr
            Personalmarketing 4.0:
          </p>
          <li>
            als ausgezeichnete Arbeitgebermarke auf dem persönlichen Profil
            präsentieren
          </li>
          <li>
            kostenfreie und am Werbebudget orientierte Stellenanzeige aufgeben
          </li>
          <li>
            zielgerichtete Filterung von Profilen, die aufgrund der Merkmale,
            Qualifikationen und Kompetenzen wirklich zum Unternehmen passen
          </li>
          <li>
            Versenden von direkten Kontaktanfragen zur ersten Kontaktaufnahme
          </li>
          <li>professionelle Ansprache über die Chat-Funktion</li>
          <li>
            mit Talent Insights Echtzeitdaten zu Kandidaten und Unternehmen
            erhalten, den Talentmarkt sowie aktuelle Trends im Blick behalten
          </li>
          <p>
            Wichtig beim LinkedIn Recruiting ist es zu verstehen, dass dieses
            als Teil des Personalmarketings eine fundierte Herangehensweise
            erfordert. Planung, Strategie, Umsetzung, Evaluation und Optimierung
            sind die Kernelemente für das erfolgreiche Recruiting über LinkedIn.
            Setzen Sie beim LinkedIn Recruiting von Beginn an auf Profis im
            HR-Management.
          </p>
          <br />
          <h2>Wie funktioniert Recruiting über LinkedIn mit Peakconcepts?</h2>
          <br />
          <p>
            Wir von{' '}
            <NavLink to="/">
              <b>
                <u>Peakconcepts</u>
              </b>
            </NavLink>{' '}
            verstehen LinkedIn Recruiting als eine zielführende Erweiterung
            Ihrer ganzheitlichen Personalstrategie, die eine hervorragende
            Chance bietet, schneller und unkomplizierter mit potenziellen
            Mitarbeitern auf Augenhöhe in Kontakt zu treten. Wichtig dabei sind
            Offenheit und Ehrlichkeit. Authentizität ist einer der
            Schlüsselbegriffe beim LinkedIn Recruiting. Daher beginnt bei uns
            jede Zusammenarbeit für das LinkedIn Recruiting mit einem
            ausführlichen Erstgespräch und der Erfassung Ihres Status quo. So
            ist es unser Ziel, Ihre Unternehmensidentität bis in die Tiefe
            kennenzulernen, um ein LinkedIn Recruiting zu gestalten, das Ihnen
            langfristig die passenden Talente zum richtigen Zeitpunkt liefert.
          </p>

          <p>
            Auf dieser Grundlage entwickeln wir gemeinsam den „perfekten
            Mitarbeiter“ als Basis für die Personalsuche, setzen zusammen Ziele,
            erstellen eine umfangreiche Konzeption und LinkedIn Recruiting
            Strategie, setzen die daraus abgeleiteten Maßnahmen um, evaluieren
            die Bemühungen wie mit LinkedIn Analytics, optimieren die Umsetzung
            und übernehmen auf Wunsch die fortlaufende Betreuung.
          </p>
          <p>Was passiert beim LinkedIn Recruiting?</p>
          <li>Gestaltung eines Unternehmensprofils</li>
          <li>Kommunikations- und Content-Strategie</li>
          <li>Employer Branding</li>
          <li>
            Nutzung der erweiterten Suchfunktion und Verwendung der
            Suchkriterien
          </li>
          <li>Schaltung von Stellenanzeigen</li>
          <li>aktive Talentsuche, Vernetzung und Kontaktaufbau</li>
          <li>Follow-ups</li>
          <li>Interkation in branchenrelevanten Gruppen</li>

          <br />
          <h2>Starten Sie jetzt LinkedIn Recruiting mit Peakconcepts</h2>
          <br />
          <p>
            Vertrauen Sie beim LinkedIn Recruiting auf jahrelange Erfahrung,
            höchste Expertise und zahlreiche erfolgreich umgesetzte Projekte mit
            namhaften Unternehmen, die wir auch langfristig beim LinkedIn
            Recruiting betreuen. Zuverlässigkeit, Kompetenz, Transparenz und
            eine Zusammenarbeit auf Augenhöhe sind uns besonders wichtig. Bei
            allen Serviceleistungen garantieren wir für eine kompromisslose
            Qualität.
          </p>
          <p>
            Haben Sie noch Fragen zum LinkedIn Recruiting oder wollen Sie sich
            unverbindlich beraten lassen, nehmen Sie unkompliziert{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontaktaufnahme</u>
              </b>
            </NavLink>{' '}
            zu unseren Experten auf. Heben wir gemeinsam mit LinkedIn Recruiting
            Ihre Personalsuche auf ein neues Niveau.
          </p>
        </LPContent>
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default LinkedinRecruiting
