import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/agentur/leadgenerierung.json'
import Content from '@content/leistungen/leadgenerierung.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))

const Leadgenerierung = () => {
  return (
    <>
      <SEOHeader id="lp-leadgenerierung" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <p>
            Die <b>Vorteile</b> der Leadgenerierung sind:
          </p>
          <li>Sammeln von Kontaktdaten</li>
          <li>Aufbau von Reichweite</li>
          <li>Gewinnung von Neukunden</li>
          <li>Bildung eines positiven Images</li>
          <li>Kostengünstige Bewerbung von Produkten und Dienstleistungen</li>
          <li>Informationen zur genauen Zielgruppe</li>
          <p>
            Eine Leadgenerierung findet da statt, wo Ihre Zielgruppe sich
            aufhält. Heute ist das die digitale Welt, weshalb die
            Leadgenerierung sowohl im B2B als auch B2C ein wichtiger Teil der
            Online-Marketing- Strategie ist. Dabei setzen wir als
            Leadgenerierung Agentur genau hier an. Wir schaffen Lösungen und
            etablieren erfolgreiche Prozesse für die zielführende Gewinnung
            hochwertiger Leads. Gewinnbringend für jeden.
          </p>
          <br />
          <h2>Welche Arten von Leads gibt es?</h2>
          <br />
          <p>
            Die Leadgenerierung ist für ein Unternehmen letztlich die Grundlage
            zur Neukundengewinnung. Es gibt jedoch nicht nur den einen „Lead“,
            sondern unterschiedliche Arten bzw. Qualitäten von Leads bei der
            Leadgenerierung. Man kann sagen, dass Lead eben nicht gleich Lead
            ist. Die Qualitäten eines Leads unterscheiden sich im Hinblick auf
            den Fortschritt des Kunden im sogenannten Sales Funnel. Steht er
            noch am Anfang des Entscheidungsprozesses oder ist er bereits nah am
            Ende angekommen und damit quasi kaufbereit? Genau das definieren in
            der Leadgenerierung die folgenden Leadarten, wobei nur zwei davon
            als qualitativ gute Leads bezeichnet werden können:
          </p>
          <li>Marketing Accepted Leads (MAL)</li>
          <li>Marketing Qualified Leads (MQL)</li>
          <li>Sales Accepted Leads (SAL)</li>
          <li>Sales Qualified Leads (SQL)</li>
          <p>
            Marketing Accepted Leads (MAL) sind Kontakte, die hinsichtlich ihrer
            Eigenschaften, Ihres Wohnorts oder ihres Berufs als Lead für ein
            Unternehmen von Interesse sein könnten. Hierbei spricht man von
            potenziellen Leads, welche über passende Marketingbemühungen
            angesprochen werden.
          </p>
          <p>
            Ein Marketing Qualified Lead (MQL) ist eine Person, die durch
            Marketingbemühungen von sich aus Interesse am Unternehmen zeigt,
            indem sie einen Newsletter abonniert oder an einem Webinar
            teilgenommen hat. Diese Lead-Art der Leadgenerierung, führt dazu,
            dass der Kontakt durch sein Verhalten selbst seine Kontaktdaten
            weitergibt. Er ist im Wesentlichen auch geneigt, eine positive
            Kaufentscheidung in Erwägung zu ziehen. Der Entscheidungsprozess
            kann sich jedoch über mehrere Touchpoints hinziehen.
          </p>
          <p>
            Bei einem Sales Accepted Lead (SAL) handelt es sich um einen Lead,
            der durch eine Marketingaktion gewonnen und bereits vorqualifiziert
            wurde. Dieser kann nun vom Vertrieb mit einem konkreten Angebot
            kontaktiert werden. Das unterscheidet den SAL-Lead auch von MAL-
            oder MQL-Leads, welche oftmals noch nicht bereit für ein gezieltes
            Vertriebsgespräch sind.
          </p>
          <p>
            Der wohl wertvollste Lead in der Leadgenerierung ist der Sales
            Qualified Lead (SQL). Hier liegt die Wahrscheinlichkeit eines
            konkreten Abschlusses am höchsten. Bei dieser letzten Stufe bedarf
            es lediglich noch dem letzten Feinschliff des Vertriebs, um aus dem
            Kontakt einen glücklichen Kunden zu machen.
          </p>
          <p>
            Zu den qualitativ guten Leads zählen die Marketing Qualified Leads
            (MQL) und die Sales Qualified Leads (SQL). Bei den MQLs hat der
            Kunde, durch seine Interaktion bereits selbst sein Interesse
            bekundet. Passt er gleichsam zur Zielgruppe des Unternehmens, steigt
            die Chance, den Lead in einen Kunden zu verwandeln. Beim SQL wurde
            das Interesse durch den Vertrieb geweckt. Er kennt das Angebot, er
            weiß, was er will und was er bekommt. Es fehlt nur noch der letzte
            Schritt in der Leadgenerierung, den ein Vertriebsmitarbeiter
            begleitet.
          </p>
          <br />
          <h2>Warum Leads generieren?</h2>
          <br />
          <p>
            Die Rechnung ist einfach: Je mehr Leads Sie generieren, desto mehr
            Potenzial haben Sie, um Neukunden zu gewinnen und aus diesen, feste
            Stammkunden zu konvertieren. Mit einer strategischen Leadgenerierung
            erhöhen Sie Ihre Reichweite und erhalten mehr Aufmerksamkeit von
            potenziellen Interessenten. Bauen Sie mit Leadgenerierung eine
            fundierte Datenbank auf, die Sie immer wieder nutzen können. Wir von{' '}
            <NavLink to="/">
              <b>
                <u>Peakconcepts</u>
              </b>
            </NavLink>{' '}
            unterstützen Sie dabei. Mit einem umfassenden Leistungsangebot
            kümmern wir uns um eine effektive Leadgenerierung und setzen
            gezielte Maßnahmen DSGVO-konform um. Wir schaffen echte Mehrwerte
            und setzen auf innovative Instrumente, sodass Sie ein hohes,
            qualitatives Leadvolumen erhalten. Wir setzen dabei unter anderem
            auf das Inbound-Marketing und erhöhen damit langfristig Ihren
            Leadzufluss.
          </p>
          <p>Maßnahmen der Leadgenerierung sind:</p>
          <li>Landingpage</li>
          <li>Leadformulare</li>
          <li>Webseiten</li>
          <li>E-Mail-Marketing</li>
          <li>CTA</li>
          <li>Retargeting</li>
          <li>Blogs</li>
          <li>Kundenempfehlungen</li>
          <br />
          <h2>Wer benötigt eine Leadgenerierung?</h2>
          <br />
          <p>
            Eine Leadgenerierung sollte Teil eines jeden E-Commerce-Unternehmens
            ein, welches Produkte und Dienstleistungen verkauft. Mit einer
            effektiven Leadgenerierung lassen sich strategisch sinnvoll
            Neukunden gewinnen, Verkaufsprozesse zielführender gestalten und das
            Marketing wirkungsvoller planen. Das Ergebnis einer guten
            Leadgenerierung ist die Steigerung Ihrer Conversion Rate.
          </p>
          <p>
            Denken Sie darüber nach, wie Sie mehr Leads generieren können oder
            haben bisher noch keine Erfahrung mit der Leadgenerierung gemacht,
            dann sollten Sie sich an eine Leadgenerierung-Agentur, wie uns von
            Peakconcepts, wenden. Wir unterstützen Sie orientiert an Ihren
            individuellen Prozessen dabei, Leads zu generieren und langfristig
            ein System zur fokussierten Leadgenerierung in Ihren Marketing- und
            Vertriebsstrukturen zu etablieren. Selbstverständlich für uns: Auf
            Ihre individuellen Vorstellungen und Voraussetzungen vor Ort
            einzugehen. So berücksichtigen wir beispielsweise die Anzahl Ihres
            Personals. Damit wird die Leadgenerierung sowohl für große
            Unternehmen als auch für KMUs umsetzbar.
          </p>
          <br />
          <h2>Leadgenerierung von B2B bis B2C mit Peakconcepts</h2>
          <br />
          <p>
            Lust mit uns zusammen Leads zu generieren? Ob Neukunden,
            Geschäftspartner, Lieferanten oder Großabnehmer, wir sind Ihre
            Leadgenerierung{' '}
            <NavLink to="/agentur">
              <b>
                <u>Agentur</u>
              </b>
            </NavLink>{' '}
            . Unsere Zusammenarbeit startet mit einem umfassenden Erstgespräch,
            in dem es um Ihre Ansprüche an die Leadgenerierung geht. Dabei
            setzen wir uns neben strategischen Zielen auch reale Zahlen an Leads
            als Ziel, die Sie am Ende der Online- Leadgenerierung gewonnen haben
            möchten. In Anschluss geht es an die Grundlagenbildung, indem wir
            uns den Status-Quo ansehen, Ihre Zielgruppe analysieren, die
            richtigen Kanäle auswählen und Maßnahmen abstecken. Unser Ziel der
            Leadgenerierung B2B oder B2C: Interessenten im richtigen Moment mit
            den richtigen Tools auf dem richtigen Kanal anzusprechen. Ein dazu
            fundiertes Konzept ist das Geheimnis, um effektiv Leads zu
            generieren. Haben wir das Fundament der Leadgenerierung gelegt,
            sorgen wir für die Umsetzung. Darunter, je nach individueller
            Planung, das Erstellen von Landingpage, das Schalten von Anzeigen
            oder die Implementierung eines E-Mail-Marketings. Selbstverständlich
            arbeiten wir dabei immer eng mit Ihnen zusammen. Ein umfangreiches
            Controlling sorgt dafür, dass wir die Leadgenerierung nach und nach
            optimieren und verfeinern können, bis am Ende ein funktionierender
            und automatisierter Lead-Funnel steht. So smart geht Leadgenerierung
            heute – zumindest mit einem Partner an Ihrer Seite, auf den Sie sich
            verlassen können.
          </p>
          <p>
            Bringen wir Ihre Leadgenerierung auf eine neue Ebene des Erfolgs und
            lassen für Sie die einfache Formel „hochwertiges Leadvolumen +
            nachhaltige Konvertierungsstrategie = Neukunden“ aufgehen. Mit
            unserer Leadgenerierung Agentur sind Sie Ihren Mitbewerbern bereits
            einen entscheidenden Schritt voraus. Nehmen Sie jetzt unverbindlich{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontaktaufnahme</u>
              </b>
            </NavLink>{' '}
            zu uns auf.
          </p>
        </LPContent>
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default Leadgenerierung
