import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/webdesign/donauwoerth.json'
import Content from '@content/leistungen/design.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const KontaktShort = React.lazy(
  () => import('@components/sections/KontaktShort')
)
const Quote = React.lazy(() => import('@components/sections/Quote'))
const Technologien = React.lazy(
  () => import('@components/sections/Technologien')
)
const Keyfacts = React.lazy(() => import('@components/sections/Keyfacts'))
const Referenzen = React.lazy(() => import('@components/sections/Referenzen'))

const LPWebdesignDonauwoerth = () => {
  return (
    <>
      <SEOHeader id="lp-webdesign-donauwoerth" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Die Serviceleistungen bei einem Webdesign in Donauwörth</h2>
          <br />
          <p>
            Unsere umfassenden{' '}
            <NavLink to="/leistungen">
              <b>
                <u>Leistungen</u>
              </b>
            </NavLink>{' '}
            sind bei jedem Webdesign in Donauwörth für Sie inklusive:
          </p>
          <li>
            Professionelles Webdesign in Donauwörth für Sichtbarkeit,
            Performance und Erfolg
          </li>
          <li>Leistungsstarke Webentwicklung</li>
          <li>
            Umsetzung von responsiven Webseiten für ein Webdesign in Donauwörth,
            das auf allen Endgeräten funktioniert
          </li>
          <li>
            Umfangreicher Service und Support für sorgenfreies Hosting, Pflege
            und Optimierung
          </li>
          <p>
            Nutzen Sie unser Rundum-Sorglos-Paket für Ihr Webdesign in
            Donauwörth und konzentrieren Sie sich einfach auf Ihre Kernarbeit.
            Wir übernehmen den Rest für Sie.
          </p>
          <br />
          <h2>
            Machen wir Ihren Erfolg mit einem Webdesign in Donauwörth planbar
          </h2>
          <br />
          <p>
            Den ersten Meilenstein auf dem Weg zu Ihrem Webdesign in Donauwörth
            setzen wir mit einem umfassenden Beratungsgespräch, bei dem es in
            erster Linie um Ihre Wünsche, Vorstellungen und Ansprüche geht. Wir
            lassen dabei bereits unser fachliches Know-how einfließen, damit
            nach und nach erste Ideen eine Form finden. Haben wir alle
            Informationen, setzen wir für das Webdesign in Donauwörth erste
            Ziele und analysieren Ihre Zielgruppe sowie den Markt und
            Mitbewerber. Das ganzheitliche Bild dient im Anschluss als Grundlage
            für die Konzeptphase. In dieser entwerfen wir eine erste Struktur
            des Webdesigns in Donauwörth. Unsere Profis kreieren ein sogenanntes
            Wireframe, das als erste Skizzierung anschaulich vermittelt, wie das
            spätere Webdesign in Donauwörth optisch wirkt. Sind Sie damit
            zufrieden, geht es direkt in den Umsetzungsprozess, den Sie über
            unser innovatives Prototyping-Tool verfolgen können. Gerne
            übernehmen wir für Ihr Webdesign in Donauwörth die Programmierung
            und bringen die Seite nach einigen ausführlichen Testphasen live.
          </p>
          <br />
          <h2>
            Webdesign in Donauwörth – zünden Sie mit Ihrer Webseite die
            Erfolgsrakete
          </h2>
          <br />
          <p>
            Bei jedem Webdesign in Donauwörth ist es für uns selbstverständlich,
            dass wir unsere gesamte fachliche Kompetenz, technische Expertise
            und jahrelange Erfahrung einfließen lassen. Werte wie Ehrlichkeit,
            Zuverlässigkeit, Transparenz und die persönliche Nähe zu unseren
            Kunden sind für uns wichtig und genießen höchste Priorität. Unsere
            Qualitätsansprüche sind hoch, ebenso wie unsere Leistungen. Gerne
            unterstützen wir Sie bei Ihrem Webdesign in Donauwörth.
          </p>
          <p>
            Gehen wir ein Stück des Weges in die Digitalisierung gemeinsam.
            Nehmen Sie dazu unverbindlich{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            zu uns auf. Machen wir Ihr Webdesign in Donauwörth zur echten
            Erfolgsrakete.
          </p>
        </LPContent>
        <Referenzen category="design" />
        <Kundenmeinungen category="design" />
        <KontaktShort item={Content.contactShort} />
        <Keyfacts item={Content.keyfacts} />
        <Technologien technologie="web" />
        <Quote title={Content.quote.title} autor={Content.quote.autor} />
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default LPWebdesignDonauwoerth
