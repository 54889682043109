import Section from '@components/elements/Section'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding: 40px 0 !important;
`

const Title = styled.h1`
  color: #fff;
  text-align: center;
`

const SimpleHeader = ({ title }: { title: string }) => {
  return (
    <Section>
      <Container className="container">
        <Title>{title}</Title>
      </Container>
    </Section>
  )
}

export default SimpleHeader
