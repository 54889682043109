import SEOHeader from '@components/basics/SEOHeader'
import LeistungenOverview from '@components/sections/LeistungenOverview'
import Content from '@content/LeistungenOverview.json'
import React from 'react'

const Leistungen = () => {
  return (
    <>
      <SEOHeader id="leistungen" />
      <LeistungenOverview
        title={Content.services.title}
        items={Content.services.items}
        titleSize={1}
      />
    </>
  )
}

export default Leistungen
