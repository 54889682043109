import ContactForm from '@components/contact/ContactForm'
import Section from '@components/elements/Section'
import React from 'react'
import { Element } from 'react-scroll'

const Kontakt = ({ title }: { title?: string }) => {
  return (
    <div>
      <Element name="contact">
        <Section dark={false}>
          <ContactForm title={title} />
        </Section>
      </Element>
    </div>
  )
}

export default Kontakt
