import SEOHeader from '@components/basics/SEOHeader'
import SimpleHeader from '@components/basics/SimpleHeader'
import Section from '@components/elements/Section'
import Title from '@components/elements/Title'
import ContactSection from '@components/sections/Kontakt'
import React from 'react'
import styled from 'styled-components'

interface ItemProps {
  border?: boolean
}

const ContactBox = styled.div`
  background: #000;
  padding: 60px 0;
`

const Item = styled.div<ItemProps>`
  border-left: ${(props) => (props.border ? '1px solid #fff' : 'none')};
  text-align: center;
  height: 100%;
  h5,
  a,
  p {
    display: inline-block;
    color: #fff;
    font-weight: bold;
    margin: 0;
  }
  h5 {
    color: ${(props) => props.theme.primary};
  }
  a {
    &:hover {
      color: ${(props) => props.theme.primary};
    }
  }
  i {
    margin: 0 10px 0 0;
    color: #fff;
  }
  @media (max-width: 768px) {
    border-left: none;
  }
`

const Kontakt = () => {
  return (
    <>
      <SEOHeader id="kontakt" />
      <SimpleHeader title="Kontakt" />
      <Section dark={false}>
        <div className="container">
          <Title title="Wir sind gerne <span>für Sie da!</span>" dark={false} />
        </div>

        <Section dark={true}>
          <ContactBox>
            <div className="container">
              <div className="columns is-multiline">
                <div className="column">
                  <Item>
                    <div>
                      <i className="material-icons">schedule</i>
                      <h5>Erreichbarkeit</h5>
                    </div>
                    <p>Mo.-Fr.: 09:00 - 18:00 Uhr</p>
                  </Item>
                </div>
                <div className="column">
                  <Item border>
                    <div>
                      <i className="material-icons md-36">phone</i>
                      <h5>Telefon</h5>
                    </div>
                    <a
                      href={
                        'tel:' + process.env.REACT_APP_COMPANY_PHONE_FORMATTED
                      }>
                      {process.env.REACT_APP_COMPANY_PHONE}
                    </a>
                  </Item>
                </div>
                <div className="column">
                  <Item border>
                    <div>
                      <i className="material-icons">mail</i>
                      <h5>E-Mail</h5>
                    </div>
                    <a href={'mailto:' + process.env.REACT_APP_COMPANY_EMAIL}>
                      {process.env.REACT_APP_COMPANY_EMAIL}
                    </a>
                  </Item>
                </div>
              </div>
            </div>
          </ContactBox>
        </Section>
      </Section>
      <ContactSection />
    </>
  )
}

export default Kontakt
