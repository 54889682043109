import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/webdesign/augsburg.json'
import Content from '@content/leistungen/design.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const KontaktShort = React.lazy(
  () => import('@components/sections/KontaktShort')
)
const Quote = React.lazy(() => import('@components/sections/Quote'))
const Technologien = React.lazy(
  () => import('@components/sections/Technologien')
)
const Keyfacts = React.lazy(() => import('@components/sections/Keyfacts'))
const Referenzen = React.lazy(() => import('@components/sections/Referenzen'))

const LPWebdesignAugsburg = () => {
  return (
    <>
      <SEOHeader id="lp-webdesign-augsburg" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Unsere umfassenden Leistungen des Webdesigns für Augsburg</h2>
          <br />
          <p>
            Bei uns als Agentur für Webdesign für Augsburg ist es
            selbstverständlich, dass wir ganzheitlich denken und Sie alle{' '}
            <NavLink to="/leistungen">
              <b>
                <u>Leistungen</u>
              </b>
            </NavLink>{' '}
            aus einer Hand erhalten:
          </p>
          <li>
            Webdesign, das mit höchster Professionalität Ihre Marken-DNA digital
            erlebbar macht.
          </li>
          <li>
            Webentwicklung, die für eine leistungsstarke Performance sorgt.
          </li>
          <li>
            Responsive Webseiten, die Sie auf allen Endgeräten sichtbar machen.
          </li>
          <li>
            Service und Support, wo Hosting, Pflege und Optimierung garantiert
            sind.
          </li>
          <p>
            Sie gewinnen wieder die nötigen Kapazitäten, sich auf Ihr
            Kerngeschäft konzentrieren zu können und wir sorgen derweil für Ihre
            digitalen Spitzenleistungen.
          </p>
          <br />
          <h2>Überzeugende Konzepte beim Webdesign für Augsburg</h2>
          <br />
          <p>
            Von Beginn an legen wir beim Webdesign für Augsburg Wert auf eine
            strategische Zusammenarbeit. Wir starten mit einem Auftaktgespräch,
            in dem Sie uns Ihre Vorstellungen an die Webentwicklung und das
            Webdesign für Augsburg mitteilen. In Kombination mit unserer
            Expertise schaffen wir das Fundament für alle weiteren Schritte für
            Ihr Webdesign für Augsburg. Im Anschluss geht es um die Definition
            von Zielen, die Analyse der Zielgruppe und die Betrachtung Ihrer
            Mitbewerber. Die Informationen werden anschließend in der
            Konzeptphase verarbeitet, in der wir Meilensteine setzen und die
            erste Struktur Ihres neuen Webdesigns für Augsburg entstehen lassen.
            Das Resultat ist ein Wireframe, das Ihnen als grobe Skizzierung der
            späteren Webseite einen ersten Eindruck vermittelt. Entspricht alles
            Ihren Ansprüchen machen wir uns an die Umsetzung Ihres Webdesigns.
            Dabei erhalten Sie einen transparenten Einblick über die
            Fortschritte mit unserem innovativen Prototyping- Tool. Bei Bedarf
            übernehmen wir jederzeit die Programmierung und bringen nach einer
            umfänglichen Testphase Ihr Webdesign für Augsburg online.
          </p>
          <br />
          <h2>
            Webdesign, Webentwicklung und App-Entwicklung Augsburg – was können
            wir für Sie tun?
          </h2>
          <br />
          <p>
            Neben der Professionalität und Expertise zählen bei uns vor allem
            Werte wie Zuverlässigkeit, Kompetenz, Termintreue und Kundennähe.
            Wir arbeiten bei einem Webdesign für Augsburg nicht für Sie, sondern
            mit Ihnen zusammen. Diesen Unterschied werden Sie, ebenso wie es uns
            zahlreiche Kunden bestätigen, spüren und erleben. Qualität ist für
            uns kompromisslos. Gerne übernehmen wir Ihre App-Entwicklung und
            machen Sie fit für die mobile Zukunft.
          </p>
          <p>
            Machen Sie sich jetzt selbst ein Bild von uns und lassen Sie uns
            gemeinsam Ihr Webdesign für Augsburg auf eine neue Ebene heben.
            Nehmen Sie jetzt{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            zu uns auf.
          </p>
        </LPContent>
        <Referenzen category="design" />
        <Kundenmeinungen category="design" />
        <KontaktShort item={Content.contactShort} />
        <Keyfacts item={Content.keyfacts} />
        <Technologien technologie="web" />
        <Quote title={Content.quote.title} autor={Content.quote.autor} />
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default LPWebdesignAugsburg
