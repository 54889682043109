import React from 'react'
import styled from 'styled-components'

interface LogoContainerProps {
  totalItems: number
}
const LogoContainerDiv = styled.div<LogoContainerProps>`
  display: grid;

  @media (min-width: 993px) {
    grid-template-columns: repeat(6, 1fr);
    div:nth-child(6n) {
      border-right: none;
    }
    div:nth-child(${({ totalItems }) => (Math.ceil(totalItems / 6) - 1) * 6})
      ~ div {
      border-bottom: none;
    }
  }
  @media (max-width: 992px) and (min-width: 621px) {
    grid-template-columns: repeat(3, 1fr);
    div:nth-child(3n) {
      border-right: none;
    }
    div:nth-child(${({ totalItems }) => (Math.ceil(totalItems / 3) - 1) * 3})
      ~ div {
      border-bottom: none;
    }
  }
  @media (max-width: 620px) {
    grid-template-columns: repeat(2, 1fr);
    div:nth-child(2n) {
      border-right: none;
    }
    div:nth-child(${({ totalItems }) => (Math.ceil(totalItems / 2) - 1) * 2})
      ~ div {
      border-bottom: none;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    flex-wrap: wrap;
  }
`
const LogoItem = styled.div`
  position: relative;
  border-bottom: 1px solid #333;
  border-right: 1px solid #333;
  text-align: center;
  height: 120px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  img {
    max-height: 60%;
    max-width: 60%;
  }
  .tooltip {
    position: absolute;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    visibility: hidden;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.75);
    opacity: 0;
    -webkit-transition: visibility 0s, opacity 300ms linear;
    -moz-transition: visibility 0s, opacity 300ms linear;
    -ms-transition: visibility 0s, opacity 300ms linear;
    -o-transition: visibility 0s, opacity 300ms linear;
    transition: visibility 0s, opacity 300ms linear;
    p {
      font-size: 14px;
      color: #fff;
      margin: 0;
      padding: 10px;
    }
  }
  &:hover {
    cursor: help;
    .tooltip {
      visibility: visible;
      opacity: 1;
    }
  }
  @media (max-width: 992px) {
    height: 80px;
    img {
      max-height: 40px;
      max-width: calc(100% - 80px);
    }
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 1 0 16%;
    img {
      max-width: 80px;
    }
  }
  @media (max-width: 992px) and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    flex: 1 0 33%;
  }
  @media (max-width: 620px) and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
    flex: 1 0 49%;
  }
`

const LogoContainer = ({ items = [] }: { items?: any }) => {
  return (
    <LogoContainerDiv totalItems={items.length}>
      {items.map(
        (item: { src: string | undefined; title: string }, i: number) => (
          <LogoItem key={i}>
            <img
              src={item.src}
              alt={item.title}
              title={item.title}
              style={{ width: '100%' }}
            />
            <div className="tooltip">
              <p>{item.title}</p>
            </div>
          </LogoItem>
        )
      )}
    </LogoContainerDiv>
  )
}

export default LogoContainer
