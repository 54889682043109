import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/webdesign/noerdlingen.json'
import Content from '@content/leistungen/design.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const KontaktShort = React.lazy(
  () => import('@components/sections/KontaktShort')
)
const Quote = React.lazy(() => import('@components/sections/Quote'))
const Technologien = React.lazy(
  () => import('@components/sections/Technologien')
)
const Keyfacts = React.lazy(() => import('@components/sections/Keyfacts'))
const Referenzen = React.lazy(() => import('@components/sections/Referenzen'))

const LPWebdesignNoerdlingen = () => {
  return (
    <>
      <SEOHeader id="lp-webdesign-noerdlingen" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>
            Welche Leistungen erwarten Sie bei einem Webdesign in Nördlingen?
          </h2>
          <br />
          <p>
            Wir als ganzheitlich denkende Webdesign Agentur für Nördlingen gehen
            zusammen mit Ihnen die volle Distanz und bieten Ihnen umfassende{' '}
            <NavLink to="/leistungen">
              <b>
                <u>Serviceleistungen</u>
              </b>
            </NavLink>{' '}
            aus einer professionellen Hand:
          </p>
          <li>
            Hochwertiges Webdesign, das Ihre Marken-DNA in den Fokus bringt.
          </li>
          <li>
            Starke{' '}
            <NavLink to="/leistungen/webentwicklung">
              <b>
                <u>Webentwicklung</u>
              </b>
            </NavLink>{' '}
            , die eine maximale Performance sichert.
          </li>
          <li>
            Funktionierende responsive Webseiten, die auf jedem Endgerät ihre
            besten Seiten zeigen.
          </li>
          <li>
            Umfassender Service und Support mit Hosting, Pflege und Optimierung,
            damit Sie sich auf Ihr Kerngeschäft konzentrieren können.
          </li>
          <p>
            Mit unserem Webdesign in Nördlingen bieten wir Ihnen ein echtes
            Rundum-sorglos-Paket und flexible Leistungen, ganz nach Ihrem
            Bedarf.
          </p>
          <br />
          <h2>Webdesign in Nördlingen: durchdacht und messbar</h2>
          <br />
          <p>
            Starten wir zusammen mit Ihrem Webdesign in Nördlingen. In einem
            Auftaktgespräch lernen wir uns kennen und wissen danach, welche
            Wünsche und Vorstellung Sie haben. Diese dienen uns als Fundament.
            Gemeinsam legen wir Ziele fest, analysieren Ihre Zielgruppe und
            werfen einen umfassenden Blick auf den Markt. Sobald wir alles
            zusammen haben, beginnt die Konzeptphase, in der wir erste
            Meilensteine für Ihr Webdesign in Nördlingen setzen, den Zeitplan
            bestimmen und die erste Struktur entwickeln.
          </p>
          <p>
            Das Wireframe als grobe Skizzierung der späteren Webseite vermittelt
            Ihnen direkt einen ersten Eindruck. Nach der Abnahme setzen wir das
            Webdesign in Nördlingen um und halten Sie dabei mit unserem
            innovativen Prototyping Tool über den gesamten Prozess hinweg auf
            dem aktuellen Stand. Haben Sie das Design final für „perfekt“
            befunden, übernehmen wir auf Wunsch die Programmierung und gehen
            nach einer ausführlichen Testphase „live“.
          </p>
          <br />
          <h2>Webdesign in Nördlingen – wir bringen Sie zu Ihren Kunden</h2>
          <br />
          <p>
            Qualität, Kompetenz und Zuverlässigkeit – setzen Sie bei Ihrem
            Webdesign in Nördlingen auf bodenständigen Leistungen ohne
            Kompromisse. Unsere Kunden schätzen seit vielen Jahren unsere
            Ehrlichkeit, Transparenz und Erfahrung. Nutzen Sie auch gerne unsere
            weiteren Serviceleistungen, wie die{' '}
            <NavLink to="/leistungen/app-entwicklung">
              <b>
                <u>App-Entwicklung</u>
              </b>
            </NavLink>{' '}
            und profitieren Sie von einem Partner, der Ihnen alles aus einem
            Guss garantiert.
          </p>
          <p>
            Setzen Sie sich am besten gleich unverbindlich mit uns in{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            und lassen Sie uns zusammen die Hürden der digitalen Welt nehmen.
            Unser Webdesign in Nördlingen wird Sie und Ihre Kunden nicht
            überzeugen, sondern begeistern.
          </p>
        </LPContent>
        <Referenzen category="web" />
        <Kundenmeinungen category="web" />
        <KontaktShort item={Content.contactShort} />
        <Keyfacts item={Content.keyfacts} />
        <Technologien technologie="web" />
        <Quote title={Content.quote.title} autor={Content.quote.autor} />
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default LPWebdesignNoerdlingen
