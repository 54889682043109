import SEOHeader from '@components/basics/SEOHeader'
import ContentBox from '@components/sections/ContentBox'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import Kontakt from '@components/sections/Kontakt'
import Kontaktablauf from '@components/sections/Kontaktablauf'
import Kundenlogos from '@components/sections/Kundenlogos'
import Kundenmeinungen from '@components/sections/Kundenmeinungen'
import LeistungenOverview from '@components/sections/LeistungenOverview'
import Quote from '@components/sections/Quote'
import Content from '@content/home.json'
import React from 'react'
import { Helmet } from 'react-helmet'

const Home = () => {
  return (
    <>
      <SEOHeader id="home" />
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "LocalBusiness",
              "name": "Peakconcepts",
              "image": "https://peakconcepts.de/logos/peakconcepts.svg",
              "telephone": "07361 / 997 85 00",
              "email": "info@peakconcepts.de",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Anton-Huber-Straße 20",
                "addressLocality": "Aalen",
                "addressRegion": "Baden-Württemberg",
                "addressCountry": "Deutschland",
                "postalCode": "73430"
              },
              "openingHoursSpecification": {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
                "opens": "09:00",
                "closes": "18:00"
              },
              "url": "https://www.peakconcepts.de/"
            }
          `}
        </script>
      </Helmet>
      <HeroHeader item={Content.header} />

      <LeistungenOverview
        title={Content.services.title}
        items={Content.services.items}
      />
      <Quote title={Content.quote.title} autor={Content.quote.autor} />
      <ContentBox item={Content.content} titleSize={1} />
      <Kundenmeinungen category="home" />
      <ContentBox item={Content.content2} titleSize={2} />
      <Kundenlogos />
      <ContentBox item={Content.content3} titleSize={3} />
      <Kontaktablauf />
      <Kontakt title={Content.contact.title} />
    </>
  )
}

export default Home
