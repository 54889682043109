import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/webdesign/guenzburg.json'
import Content from '@content/leistungen/design.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const KontaktShort = React.lazy(
  () => import('@components/sections/KontaktShort')
)
const Quote = React.lazy(() => import('@components/sections/Quote'))
const Technologien = React.lazy(
  () => import('@components/sections/Technologien')
)
const Keyfacts = React.lazy(() => import('@components/sections/Keyfacts'))
const Referenzen = React.lazy(() => import('@components/sections/Referenzen'))

const LPWebdesignGuenzburg = () => {
  return (
    <>
      <SEOHeader id="lp-webdesign-guenzburg" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Diese Leistungen erwarten Sie bei einem Webdesign in Günzburg</h2>
          <br />
          <p>
            Vertrauen Sie unserem Leistungsversprechen, denn wir sorgen für
            umfassende Servicevorteile bei Ihrem Webdesign in Günzburg:
          </p>
          <li>Wir setzen ein professionelles Webdesign für Sie um.</li>
          <li>
            Mit unserer intelligente Webentwicklung stellen wir Ihre beste
            Performance sicher.
          </li>
          <li>
            Auf allen Endgeräten sorgen responsive Webseiten für Ihre
            Sichtbarkeit.
          </li>
          <li>
            Sie erhalten bei jedem Webdesign in Günzburg einen umfänglichen
            Support beim Hosting, der Pflege und Optimierung.
          </li>
          <p>
            Setzen Sie jetzt den ersten Schritt in Richtung Zukunft mit einem
            Rundum-sorglos-Paket bei Ihrem Webdesign. Legen Sie Ihren besten
            Auftritt in professionelle Hände und konzentrieren Sie sich auf Ihre
            Arbeit.
          </p>
          <br />
          <h2>Durchdachtes Webdesign in Günzburg, damit Erfolg messbar wird</h2>
          <br />
          <p>
            Unsere erfolgreiche Zusammenarbeit bei einem Webdesign beginnt mit
            einem Auftaktgespräch, das sich um Ihre Ansprüche, Wünsche und
            Vorstellungen dreht. Zusammen mit unserem Know-how legen wir das
            Fundament für die digitale Reise und das Webdesign in Günzburg. Wir
            stecken uns erste Ziele und schauen sowohl bei Ihrer Zielgruppe als
            auch bei Ihren Mitbewerbern genauer hin. Haben wir alle
            Informationen zusammen, geht es in unserem Kreativstudio an die
            Konzeptphase und wir planen die ersten Meilensteine für das
            Webdesign. Einen ersten Eindruck der späteren Webseite erhalten Sie
            bereits in einem von uns gestalteten Wireframe. Gefällt Ihnen die
            Skizzierung oder haben Sie uns Änderungswünsche mitgeteilt, die wir
            direkt einarbeiten, gehen wir in die Umsetzung des Webdesigns in
            Günzburg. Über den Fortschritt bleiben Sie über unser
            Prototyping-Tool auf dem Laufenden. Gerne sorgen wir optional für
            die Programmierung und bringen das Webdesign in Günzburg nach einer
            umfassenden Testphase live.
          </p>
          <br />
          <h2>Das Webdesign in Günzburg – was können wir für Sie tun?</h2>
          <br />
          <p>
            Bei uns zählen Werte wie Zuverlässigkeit, Kompetenz und Transparenz.
            Bei jedem Webdesign in Günzburg zählt der persönliche Kontakt zu
            Ihnen als unser Kunde. Unsere Qualität und Leistungsstärke in
            Kombination mit unseren hohen Ansprüchen und einer enormen
            Bodenständigkeit macht bei uns den Unterschied. Bei einem Webdesign
            in Günzburg greifen alle Prozesse ineinander, was uns erlaubt, Ihnen
            eine Präsenz zu gestalten, die Ihren Erfolg langfristig steigert.
          </p>
          <p>
            Nutzen Sie jetzt die Gelegenheit und nehmen Sie unverbindlich{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            zu uns auf. Ihr Weg in die Digitalisierung beginnt bei Ihrem
            Webdesign in Günzburg von Peakconcepts.
          </p>
        </LPContent>
        <Referenzen category="design" />
        <Kundenmeinungen category="design" />
        <KontaktShort item={Content.contactShort} />
        <Keyfacts item={Content.keyfacts} />
        <Technologien technologie="web" />
        <Quote title={Content.quote.title} autor={Content.quote.autor} />
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default LPWebdesignGuenzburg
