import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/agentur/mitarbeitergewinnung.json'
import Content from '@content/leistungen/mitarbeitergewinnung.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))

const Mitarbeitergewinnung = () => {
  return (
    <>
      <SEOHeader id="lp-mitarbeitergewinnung" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Warum digitale Mitarbeitergewinnung?</h2>
          <br />
          <p>
            Im Zuge der Digitalisierung sind vor allem digitale Kampagnen der
            Mitarbeitergewinnung zusehends bedeutungsvoller geworden und stellen
            heute den wohl effektivsten und schnellsten Weg der
            Mitarbeitergewinnung dar.
          </p>
          <p>
            Wirft man einen Blick auf die zahlreichen Möglichkeiten der
            digitalen Mitarbeitergewinnung, wird schnell klar – wer sich hier
            zielführend mit den richtigen Maßnahmen platzieren möchte, benötigt
            professionelle Unterstützung. Bei der Vielzahl an Online-Börsen,
            Jobportalen und Plattformen verliert man schnell den Überblick.
            Daneben steht die Herausforderung, eine ansprechende Stellenanzeige
            zu veröffentlichen, die sich von anderen abhebt. Für die
            Mitarbeitergewinnung bringen Jobportale den Vorteil mit, dass eine
            große Anzahl an Menschen erreicht wird, die auf der Suche nach einem
            neuen Job sind und entsprechende Qualifikationen mitbringen. In
            einem guten Konzept zur Mitarbeitergewinnung nehmen digitale
            Jobbörsen sicher einen Platz ein, aber nicht immer den zentralen.
          </p>
          <p>
            Eine entscheidende Rolle bei der Mitarbeitergewinnung spielt das
            Social Recruiting. Kein Kanal ist mehr in unserem täglichen Leben
            integriert als Facebook, Instagram, YouTube und WhatsApp. Hier
            treffen Sie Ihre potenziellen Mitarbeiter. Hier spielt sich das
            digitale Leben ab. Die Mitarbeitergewinnung in sozialen Netzwerken
            bietet unglaublich viele Vorteile und überzeugt mit
            unterschiedlichen Wegen, um Mitarbeiter zu gewinnen. Selbst dann,
            wenn diese noch gar nicht wissen, dass sie einen neuen Job suchen.
            Neben den Bemühungen auf der Facebook- und Instagramseite in Form
            von Content, Videos und Fotos selbst eignen sich vor allem bezahlte
            Kampagnen zur Mitarbeitergewinnung, um Zielgruppen aufmerksam auf
            offene Stellen zu machen. Auch unterstützen wir Sie gerne bei der{' '}
            <NavLink to="/leistungen/app-entwicklung">
              <b>
                <u>App-Entwicklung</u>
              </b>
            </NavLink>
            .
          </p>
          <p>
            Während Sie auf Facebook talentierte Nachwuchskräfte und
            interessante Absolventen treffen, sollten Sie XING und LinkedIn zur
            Mitarbeitergewinnung von Fach- und Führungspersonal nutzen. Mittels
            der umfassenden Filtereinstellungen können Sie Ihre Zielgruppe bis
            in die Tiefe definieren. Ein praktisches und sehr effizientes
            Vorselektieren, das Ihnen bei der Mitarbeitergewinnung viel Zeit und
            Kosten spart.
          </p>
          <p>
            Nutzen Sie unsere Erfahrung und{' '}
            <NavLink to="/referenzen">
              <b>
                <u>Referenzen</u>
              </b>
            </NavLink>
            . Zusammen gestalten wir ein Konzept zur Mitarbeitergewinnung in den
            digitalen Medien und bringen Sie mit professionellen Kampagnen auf
            den richtigen Kanälen nach vorne.
          </p>
          <p>
            Ihre Vorteile bei der Mitarbeitergewinnung mit Social Recruiting:
          </p>
          <li>Entwicklung von Mitarbeitergewinnung Strategien und Kampagnen</li>
          <li>passgenaue Recruiting-Werbeanzeigen</li>
          <li>zielgruppengerechte Ansprache</li>
          <li>Erhöhung der Sichtbarkeit</li>
          <li>konsistente Bewerbungsprozesse</li>
          <li>Steigerung der Aufmerksamkeit</li>
          <li>sofortige Kommunikation</li>
          <li>automatisierte Vorselektion</li>
          <li>fachlich passende Bewerber</li>
          <br />
          <h2>Employer Branding als Konzept der Mitarbeitergewinnung</h2>
          <br />
          <p>
            Erst dann, wenn Sie sich als attraktiver Arbeitgeber auf dem
            Arbeitsmarkt positionieren, können Mitarbeitergewinnungs-Strategien
            richtig greifen. Mit einem durchdachten Employer Branding legen Sie
            den Grundstein für Ihre Mitarbeitergewinnung. Die
            Arbeitgebermarkenbildung hat das Ziel, die Wahrnehmung Ihres
            Unternehmens positiv zu beeinflussen und Ihre Werte sowie Ihre
            Philosophie zu repräsentieren. Schaffen Sie mit einer nach außen
            sichtbaren, modernen und ansprechenden Unternehmenskultur einen
            Anreiz für Bewerber und festigen Sie Ihr Image als Unternehmen, in
            dem man gerne arbeitet. Eine Maßnahme zur Mitarbeitergewinnung ist
            unter anderem, Ihr Stammpersonal zu Markenbotschafter werden zu
            lassen.
          </p>
          <p>
            Sobald sich Mitarbeiter mit einem Unternehmen identifizieren können,
            ist das Fundament für eine effektive Mitarbeitergewinnung gelegt.
            Ein weiterer Vorteil zeigt sich langfristig gesehen. Denn haben Sie
            einmal Ihre Arbeitgebermarke aufgebaut und pflegen diese regelmäßig,
            so können Sie dauerhaft Potenziale zur Mitarbeitergewinnung
            aufrufen.
          </p>
          <p>
            Ihrer Vorteile bei der Mitarbeitergewinnung mit Employer Branding:
          </p>
          <li>Aufbau einer positiven Arbeitgebermarke</li>
          <li>laufender Bewerberfluss, der skalierbarer und planbarer wird</li>
          <li>
            Aufmerksamkeit bei qualifizierten Fachkräften aus der Region,
            deutschland- oder europaweit
          </li>
          <li>nachhaltige Implementierung eines Systems</li>
          <li>Steigerung der Qualität durch Vorqualifizierung</li>
          <li>strategische Maßnahmenplanung</li>
          <li>Kostentransparenz</li>
          <li>Entlastung der Personalabteilung</li>
          <li>erhöhte Reichweite</li>
          <li>gleichzeitige Mitarbeiterbindung</li>
          <br />
          <h2>Das Potenzial von Karriereseiten für die Mitarbeitergewinnung</h2>
          <br />
          <p>
            Schon längst findet der „War for Talents“ im digitalen Umfeld statt.
            Sowohl Fachkräfte aus der Millennial-Generation als auch die
            Absolventen aus der Generation Z nutzen für die Suche nach passenden
            Stellen das Internet. Ob durch eine Suchmaschine oder im Zuge einer
            Recherche in den sozialen Medien, letztlich werden potenzielle
            Bewerber auf Ihrer Webseite landen. Hier angekommen, sollte
            insbesondere die Karriereseite eine zentrale Anlaufstelle sein.
          </p>
          <p>
            Viele Unternehmen unterschätzen jedoch die Karriereseite als eines
            der wichtigsten Recruiting- Instrumente der Mitarbeitergewinnung.
            Jedoch zeigt sich, dass diese, als Teil einer fundierten
            Personalmarketing-Strategie, maßgeblich dafür verantwortlich ist, ob
            sich Interessenten wirklich bei Ihnen bewerben oder sich für Ihre
            Mitbewerber entscheiden. Ganz gleich, ob globaler Konzern oder
            traditioneller Familienbetrieb, eine strukturierte und überzeugende
            Karriereseite stellt sicher, dass Sie auf effektive Art und Weise
            Mitarbeiter gewinnen.
          </p>
          <p>Was beinhaltet eine Karriereseite zur Mitarbeitergewinnung?</p>
          <p>
            Auf einer Karriereseite stellen Sie sich als Unternehmen bei Ihren
            potenziellen Kandidaten vor und präsentieren sich als starke
            Arbeitgebermarke. In erster Linie überzeugen Sie hier mit einem
            authentischen und transparenten Auftritt. Bei der Gestaltung sowie
            dem Content ist darauf zu achten, dass Interessenten auf eine
            spannende und dialogfreudige Candidate Experience eingeladen werden.
            Übersichtlich, informativ, auf den Punkt gebracht, simpel
            strukturiert und dennoch ansprechend im modernen{' '}
            <NavLink to="/leistungen/design">
              <b>
                <u>Design</u>
              </b>
            </NavLink>{' '}
            – die optimale Karriereseite zur Mitarbeitergewinnung eröffnet Ihnen
            als zukünftiger Arbeitergeber viele Möglichkeiten.
          </p>
          <p>
            Starten Sie mit der Planung Ihres Konzepts zur Mitarbeitergewinnung
            über eine Karriereseite, ist es zunächst wichtig, sich zu fragen,
            was Sie Ihren Besuchern mitteilen möchten. Was können Bewerber bei
            Ihnen erwarten? Welche Benefits bieten Sie als Arbeitgeber, bieten
            Sie Flexibilität hinsichtlich Ihrer Arbeitszeiten? Nutzen Sie Ihre
            Vision oder Philosophie als Grundlage, um ansprechende Inhalte zur
            Mitarbeitergewinnung zu kreieren.
          </p>
          <p>Das gehört auf eine Karriereseite:</p>
          <li>Tätigkeitsbereich des Unternehmens</li>
          <li>Vision</li>
          <li>Arbeitskultur</li>
          <li>Unternehmensalltag</li>
          <li>Team</li>
          <li>Führungskräfte</li>
          <li>Leistungen</li>
          <li>Arbeitgebervorteile</li>
          <li>Einstiegs-, Aufstiegs- und Karrieremöglichkeiten</li>
          <li>Stellenanzeigen</li>
          <li>FAQ</li>
          <p>
            Tipp: Besonders gut im Zuge der Mitarbeitergewinnung kommt ein
            eigener Karriereblog an. Hier geben Sie und Ihre Mitarbeiter in
            spannenden Artikeln Einblick in Ihren Arbeitsalltag, teilen
            Erfahrungsberichte und witzige Anekdoten.
          </p>
          <p>
            Wie bei jeder Webseite ist es auch bei der Karriereseite essenziell,
            die Sichtbarkeit durch SEO- Maßnahmen in den Suchmaschinen zu
            steigern und mit allen Social-Media-Kanälen zu verknüpfen. Sorgen
            Sie dafür, dass Bewerber Sie ohne Umwege finden und Sie eine hohe
            Reichweite erzielen.
          </p>
          <p>
            Ein weiterer Fokus bei einer Karriereseite zur Mitarbeitergewinnung
            liegt auf dem Bewerbungsprozess, den Sie hier implementieren
            sollten. Statt langatmiger Formulare senken Sie die Hürden mit einem
            auf das Wesentliche reduzierten Verfahren oder einer
            benutzerfreundlichen One- Click-Bewerbung, bei der das Profil direkt
            aus LinkedIn oder Xing übertragen werden kann. Stellen Sie ferner
            sicher, dass Ihre Kontaktmöglichkeiten leicht einzusehen sind,
            sodass Bewerber sich jederzeit mit Ihnen in Verbindung setzen
            können.
          </p>
          <p>
            Sie möchten mit einer zukunftsorientierten Mitarbeitergewinnung
            Fachkräfte effektiv für sich gewinnen? Gerne gestalten wir für Sie
            als Experten eine Karriereseite, die durch Authentizität,
            Professionalität und Struktur überzeugt. Seien Sie Ihren
            Mitbewerbern einen Schritt voraus und erkennen Sie das Potenzial
            einer Karriereseite für ein Recruiting, auf das Sie sich jederzeit
            verlassen können. Gerne zeigen wir von Peakconcepts, welche
            Möglichkeiten Sie mit einer individuellen Karriereseite haben und
            inwiefern Ihnen diese im Zuge der Mitarbeitergewinnung zugutekommt.
          </p>
          <br />
          <h2>
            Bereit für ein wirkungsvolles Konzept der Mitarbeitergewinnung?
            Lassen Sie uns starten
          </h2>
          <br />
          <p>
            Bringen wir die Mitarbeitergewinnung in Ihrem Unternehmen auf ein
            neues Niveau und finden Talente, die wirklich zu Ihnen passen.
            Sprechen Sie in der richtigen Umgebung Ihre Wunschkandidaten an und
            gewinnen Sie auch in Zukunft einfacher Mitarbeiter zur richtigen
            Zeit.{' '}
            <NavLink to="/">
              <b>
                <u>Peakconcepts</u>
              </b>
            </NavLink>{' '}
            begleitet Sie dabei.
          </p>
          <p>
            Nutzen Sie die Gelegenheit und nehmen Sie unverbindlich{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            zu uns auf. Zusammen meistern wir nicht nur die
            Mitarbeitergewinnung, sondern stärken nachhaltig Ihre
            Personalstruktur. Wir wissen, wie es funktioniert.
          </p>
        </LPContent>
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default Mitarbeitergewinnung
