import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/agentur/recruiting.json'
import Content from '@content/leistungen/recruiting.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))

const Recruitingagentur = () => {
  return (
    <>
      <SEOHeader id="lp-agentur-recruiting" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Warum eine Recruiting Agentur engagieren?</h2>
          <br />
          <p>
            Warum aber sollten Sie mit einer Recruiting Agentur
            zusammenarbeiten? Schließlich haben Sie eventuell eigene
            Personalmitarbeiter oder betreiben seit jeher selbst die
            Personalsuche. Wir geben Ihnen natürlich recht, dass entsprechend
            schnell diese Frage aufkommen kann. Jedoch vergessen Sie nicht, dass
            wir derzeit erst am Anfang der Arbeitswelt 4.0 stehen. Die aktuellen
            Herausforderungen sind im Vergleich zu jenen in den letzten Jahren
            enorm gestiegen. Als professionelle Recruiting Agentur haben wir uns
            auf eine fundierte Gewinnung von Talenten im Online- und
            Offlinebereich spezialisiert und bringen neben höchster Expertise
            und einer jahrelangen Erfahrung auch die nötige Weitsicht mit, die
            es bedarf, um den Anforderungen von morgen gerecht zu werden. Je
            eher Sie jetzt handeln, umso größer wird der Vorsprung vor Ihren
            Mitbewerbern um die Rekrutierung der besten Talente sein. Ferner
            entlasten Sie mit uns als Recruiting Agentur erheblich Ihre
            Personalabteilung, die sich derweil um die wirklich wichtigen Dinge
            kümmern kann.
          </p>
          <p>
            Eine Zusammenarbeit mit uns als Ihre Recruiting Agentur wird damit
            zum wesentlichen Erfolgsschlüssel.
          </p>
          <br />

          <h2>Welche Vorteile hat eine Personal Recruiting Agentur?</h2>
          <br />
          <p>
            Nicht nur, dass wir als Recruiting Agentur Sie gegenwärtig bei der
            Personalsuche unterstützen. Ein wesentlicher Vorteil liegt darin,
            dass wir gemeinsam einen Prozess schaffen, der Ihnen auf Knopfdruck
            neues Personal beschert. Dazu beginnt jede Kooperation mit einem
            ausführlichen{' '}
            <NavLink to="/leistungen/beratung">
              <b>
                <u>Beratungsgespräch</u>
              </b>
            </NavLink>{' '}
            , in dem wir Ihren Status quo ebenso wie Ihre Ziele erfassen. In
            Kombination mit Ihren Unternehmenswerten erstellen wir als
            Recruiting Agentur eine ganzheitliche Strategie, definieren dazu
            passende Maßnahmen, kümmern uns um die Umsetzung und die
            fortlaufende Analyse sowie Optimierung.
          </p>
          <p>Ihre Vorteile mit einer Recruiting Agentur:</p>
          <li>Zeit- und Arbeitsersparnis</li>
          <li>Vorsprung vor Mitbewerbern</li>
          <li>laufender Zustrom an Talenten</li>
          <li>Positionierung als attraktive Arbeitgebermarke</li>
          <li>fundierte Strategieerstellung und Umsetzung</li>
          <li>höchste Fachexpertise</li>
          <li>
            Full Service von{' '}
            <NavLink to="/leistungen/design">
              <b>
                <u>Design</u>
              </b>
            </NavLink>{' '}
            bis zur Kommunikation
          </li>
          <li>Kontinuität und fortlaufende Optimierung der Kampagnen</li>
          <li>neue Ideen, Blickwinkel und Inspiration</li>
          <li>umfassendes Monitoring</li>
          <br />

          <h2>Welche Tools setzt eine Recruiting Agentur ein?</h2>
          <br />
          <p>
            Ohne Frage greifen wir als kreative und professionelle Recruiting
            Agentur auf das gesamte Spektrum der Tools zurück, die uns bei der
            smarten Personalakquise zur Verfügung stehen. Karriereportale,
            Webpräsenz, Content-Marketing, Social Recruiting und Employer
            Branding sind nur einige der Möglichkeiten, die wir für Sie
            gestalten, um ohne Umwege Ihre Zielgruppe zu erreichen. Mit uns
            haben Sie einen professionellen Partner für Ihre{' '}
            <NavLink to="/leistungen/webentwicklung">
              <b>
                <u>Webentwicklung</u>
              </b>
            </NavLink>{' '}
            und Ihr{' '}
            <NavLink to="/leistungen/online-marketing">
              <b>
                <u>Online-Marketing</u>
              </b>
            </NavLink>
            . Wichtig ist uns als Ihre Recruiting Agentur, dass jede Maßnahme
            wirklich zu Ihnen passt, damit Sie vor allem authentisch bleiben.
          </p>
          <p>
            Allen voran mit dem Employer Branding, das am Anfang jedes
            Recruiting-Prozesses aufgebaut werden sollte, schaffen wir es, Ihre
            Unternehmenskultur, Ihre Philosophie und Werte nach außen so zu
            kommunizieren, dass neue Kandidaten bereits vor einer
            Stellenausschreibung ein Auge auf Sie werfen. Dazu wäre eine Option,
            dass Ihr Stammpersonal zu Ihren Markenbotschaftern wird.
          </p>
          <br />

          <h2>Peakconcepts als Ihre Recruiting Agentur</h2>
          <br />
          <p>
            Als Recruiting Agentur gehen wir für Sie die Extrameile und
            erarbeiten auf dem Fundament Ihres aktuellen Stands eine Strategie,
            die weit über das Schalten von Anzeigen hinausgeht. Wie sieht Ihr
            derzeitiges Recruiting aus, was stellen Sie sich vor und was
            wünschen Sie sich? Auch diese Fragen fließen in unsere Arbeit ein.
            Erwarten Sie von uns als Ihre Recruiting Agentur einen Full Service,
            der auf Qualität und Professionalität beruht.
          </p>
          <p>Das können wir als Recruiting Agentur für Sie leisten:</p>
          <li>Mediaplanung der Personalmarketing-Kampagnen</li>
          <li>
            Stellenanzeigen Online, auf Social Media, in Print oder anderen
            Kanälen
          </li>
          <li>Content-Marketing</li>
          <li>Employer Branding</li>
          <li>Social Media Recruiting</li>
          <li>Recruiting-Videos</li>
          <li>Internetpräsenz</li>
          <li>Karriere-Webseite</li>
          <li>Karriereblog</li>
          <li>Azubi-Marketing</li>
          <br />
          <p>
            Überzeugt? Dann lassen Sie uns gemeinsam starten. Wir bieten Ihnen
            auch gerne ein Gespräch zur persönlichen Klärung Ihrer Fragen rund
            um das Thema Recruiting Agentur an. Nehmen Sie dazu einfach
            unverbindlich Kontakt zu uns auf und kommen Sie mit uns auf die
            personelle Überholspur.
          </p>
        </LPContent>
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default Recruitingagentur
