import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/agentur/mitarbeitergewinnung-pflege.json'
import Content from '@content/leistungen/mitarbeitergewinnung-pflege.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))

const MitarbeitergewinnungPflege = () => {
  return (
    <>
      <SEOHeader id="lp-mitarbeitergewinnung-pflege" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>
            Was hat sich bei der Mitarbeitergewinnung in der Pflege verändert?
          </h2>
          <br />
          <p>
            Seit vielen Jahren verändert sich der Arbeitsmarkt, bedingt durch
            unterschiedliche Einflussfaktoren, wie dem demografischen Wandel,
            kommt es zum Fachkräftemangel. Schon unlängst bewerben sich
            qualifizierte Kandidaten nicht ohne Weiteres mehr auf
            Stellenanzeigen eines Betriebs, sondern wollen gezielt angesprochen
            und überzeugt werden. Schließlich ist die Auswahl groß genug. Sie
            als Einrichtung stehen bei der Mitarbeitergewinnung in der Pflege
            vor der Herausforderung, neue Mitarbeiter gezielt anzusprechen und
            auf sich aufmerksam zu machen – noch bevor es Ihr Mitbewerber tut.
          </p>
          <br />
          <h2>
            Welche Methoden greifen heute noch bei der Mitarbeitergewinnung in
            der Pflege?
          </h2>
          <br />
          <p>
            Effektives Recruiting für die Pflege spielt sich nicht mehr in den
            Medien wie der Tageszeitung ab. Während früher klassische
            Stellenanzeigen noch eines der zuverlässigsten Tools zur
            Mitarbeitergewinnung in der Pflege waren, gilt es heute umzudenken.
            Stellen Sie sich die Frage: Wo hält sich Ihre Zielgruppe wirklich
            auf? Mehr denn je zählt es, die unendlichen Möglichkeiten moderner
            Kanäle zu nutzen und mit einem professionellen Konzept, durchdachten
            Strategien und effizienten Maßnahmen die Mitarbeitergewinnung Pflege
            auf die nächste Ebene zu bringen.
          </p>
          <br />
          <h3>
            Sie wollen alles aus der Mitarbeitergewinnung für die Pflege
            herausholen?
          </h3>
          <br />
          <p>
            Legen Sie mit uns von Peakconcepts das Fundament der
            Mitarbeitergewinnung in der Pflege und lassen Sie uns zusammen Ihre
            neuen Talente finden. Wir steigern Ihre Präsenz als attraktiver
            Arbeitgeber, erhöhen Ihre Reichweite und verhelfen Ihnen für das
            Recruiting von Pflegekräften zu mehr Aufmerksamkeit. Gewinnen Sie
            nicht nur jetzt, sondern langfristig Personal, wann immer Sie es
            brauchen. Und das beinah automatisch.
          </p>
          <br />
          <h3>
            Wie wir das bei der Mitarbeitergewinnung in der Pflege machen?
          </h3>
          <br />
          <p>
            Profitieren Sie bei uns von der Kombination aus jahrelanger
            Erfahrung, höchster Expertise und der Spezialisierung auf das
            Online-Recruiting von Pflegepersonal. Mitarbeitergewinnung in der
            Pflege geht heute den digitalen Weg. Genau auf diesem begleiten wir
            Sie ein Stück.
          </p>
          <br />
          <h2>Der Wandel zur digitalen Mitarbeitergewinnung in der Pflege</h2>
          <br />
          <p>
            Vergessen Sie am besten schnell, sich auf das Schalten von Annoncen
            in der Regionalzeitung oder die Suche über die Agentur für Arbeit
            für die Mitarbeitergewinnung in der Pflege zu verlassen. Selbst auf
            digitalen Jobbörsen ist es heute kaum mehr möglich, qualifizierte
            Fachkräfte zu gewinnen. Beim Recruiting in der Pflege kommt es genau
            jetzt auf ein Umdenken an, denn der effektivste, schnellste und
            nachhaltigste Weg führt heute digital zum Ziel. Im Fokus steht neben
            der eigenen Webpräsenz die Sichtbarkeit in den sozialen Medien –
            genau dort, wo sich für die Mitarbeitergewinnung Pflege Ihre
            Zielgruppe befindet. Sprechen Sie Pflegefachkräfte an, bevor diese
            überhaupt ihren alten Arbeitgeber verlassen haben oder motivieren
            Sie Wunschkandidaten über einen Wechsel nachzudenken. Mit digitalem
            Recruiting von Pflegekräften stehen Ihnen unendlich viele
            Möglichkeiten offen. Verleihen Sie Ihrer Einrichtung digitale
            Sichtbarkeit und positionieren Sie sich langfristig auf dem
            digitalen Arbeitsmarkt.
          </p>
          <br />
          <h2>Mitarbeitergewinnung in der Pflege mit Social Recruiting</h2>
          <br />
          <p>
            Eines der machtvollsten Werkzeuge bei der Mitarbeitergewinnung in
            der Pflege, haben Sie mit Social Recruiting in der Hand. Ob
            Facebook, Instagram, YouTube oder WhatsApp – soziale Medien sind ein
            wichtiger Teil unseres Alltags. Hier treffen Sie Ihre potenziellen
            Fachkräfte und sprechen diese in einer für sie vollkommen
            natürlichen Umgebung direkt an. Kombinieren Sie Posts und
            Werbeanzeigen. Geben Sie Einblicke in Ihre Pflegeeinrichtung. Lassen
            Sie authentischen Content, Videos und Fotos für sich sprechen und
            spielen Sie Stellenanzeigen über Kampagnen genau an für Sie
            relevante Talente aus.
          </p>
          <br />
          <h3>
            Gemeinsam nutzen wie die Vorteile vom Social Recruiting für Ihre
            Mitarbeitergewinnung in der Pflege:
          </h3>
          <br />
          <li>hohe Reichweite</li>
          <li>mehr Sichtbarkeit</li>
          <li>effizientes Vorselektieren</li>
          <li>
            unterschiedliche Plattformen (XING, LinkedIn, Facebook, Instagram
            u.v.m)
          </li>
          <li>Fachkräfte für einen Jobwechsel motivieren</li>
          <li>vereinfachter Bewerbungsprozess</li>
          <li>
            maßgeschneiderte Recruiting-Werbeanzeigen durch das richtige
            Targeting
          </li>
          <li>zielgruppengerechte Ansprache</li>
          <li>sofortige Kommunikation und direkter Dialog</li>
          <li>Verbesserung des Images der Arbeitgebermarke</li>
          <p>
            Gehen Sie bei der Mitarbeitergewinnung in der Pflege keine
            Kompromisse ein. Mit uns an Ihrer Seite verlassen Sie sich auf einen
            Profi mit jahrelanger Erfahrung und Know-how. Wir kreieren mit Ihnen
            einen individuellen Recruitingsprozess, der Ihnen langfristig
            Mehrwert bieten, aber auch Ihre aktuellen Herausforderungen löst.
          </p>
          <br />
          <h2>Employer Branding für die Personalgewinnung in der Pflege</h2>
          <br />
          <p>
            Bemühungen in den sozialen Medien und bezahlte Werbekampagnen
            greifen erheblich besser, wenn Sie von Ihrer Zielgruppe als
            attraktiver Arbeitgeber wahrgenommen werden. Genau hier kommt
            Employer Branding ins Spiel, das aus Ihnen eine unwiderstehliche
            Arbeitgebermarke macht. Die Bildung einer Marke sollte in jeder
            fundierten Strategie der Mitarbeitergewinnung in der Pflege einen
            Platz einnahmen, da diese sicherstellt, dass alle anderen Maßnahmen
            schneller an Wirkung gewinnen. Beim Employer Branding rücken Sie
            Ihre Ziele, Werte und Philosophie ins rechte Licht und zeigen
            potenziellen Bewerbern, was Sie als Arbeitgeber so interessant
            macht. Eine hervorragende Option bieten dafür Recruiting-Videos, in
            denen Ihre Mitarbeiter positiv von ihrem Arbeitsalltag berichten und
            zu Markenbotschaftern werden. Auf Ihr positives Image als
            Arbeitgebermarke für die Mitarbeitergewinnung in der Pflege können
            Sie somit immer und immer wieder zurückgreifen.
          </p>
          <br />
          <h3>
            Ihre Vorteile bei der Mitarbeitergewinnung in der Pflege mit
            Employer Branding:
          </h3>
          <br />
          <li>Aufbau einer positiven Arbeitgebermarke</li>
          <li>laufender Bewerberfluss, der skalierbarer und planbarer wird</li>
          <li>
            Aufmerksamkeit bei qualifizierten Fachkräften aus der Region,
            deutschland- oder europaweit
          </li>
          <li>nachhaltige Implementierung eines Systems</li>
          <li>Steigerung der Qualität durch Vorqualifizierung</li>
          <li>strategische Maßnahmenplanung</li>
          <li>Kostentransparenz</li>
          <li>Entlastung der Personalabteilung</li>
          <li>erhöhte Reichweite</li>
          <li>gleichzeitige Mitarbeiterbindung</li>
          <br />
          <h2>
            Bringen wir Ihr Recruiting für Pflegekräfte zusammen auf das nächste
            Level
          </h2>
          <br />
          <p>
            Setzen Sie mit uns den nächsten und alles entscheidenden Schritt zur
            Mitarbeitergewinnung in der Pflege und stellen Sie sich für die
            Zukunft stabil auf. Trotzen Sie dem Fachkräftemangel mit einem
            zuverlässigen Partner wie uns an Ihrer Seite. Wir bringen Sie mit
            Ihrem Personal zusammen.
          </p>
          <p>
            Stellen Sie jetzt unverbindlich Ihre Anfrage zur
            Mitarbeitergewinnung in der Pflege und gehören auch Sie bald zu
            einem unserer zahlreichen, zufriedenen Kunden mit einer ausgewogenen
            Personalstruktur. Bei Fragen nehmen Sie gerne{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            zu uns auf. Wir werden uns zeitnah bei Ihnen melden!
          </p>
        </LPContent>
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default MitarbeitergewinnungPflege
