import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/webdesign/heilbronn.json'
import Content from '@content/leistungen/design.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const KontaktShort = React.lazy(
  () => import('@components/sections/KontaktShort')
)
const Quote = React.lazy(() => import('@components/sections/Quote'))
const Technologien = React.lazy(
  () => import('@components/sections/Technologien')
)
const Keyfacts = React.lazy(() => import('@components/sections/Keyfacts'))
const Referenzen = React.lazy(() => import('@components/sections/Referenzen'))

const LPWebdesignHeilbronn = () => {
  return (
    <>
      <SEOHeader id="lp-webdesign-heilbronn" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>
            Unsere Leistungen im Bereich Webdesign und Webentwicklung für
            Heilbronn
          </h2>
          <br />
          <p>
            Verlassen Sie sich bei uns auf{' '}
            <NavLink to="/leistungen">
              <b>
                <u>Leistungen</u>
              </b>
            </NavLink>{' '}
            der Webentwicklung und des Webdesigns für Heilbronn aus einer Hand.
            Bei uns erwarten Sie umfassende Servicevorteile:
          </p>
          <li>
            Wir rücken Ihre Marke mit einem professionellen Webdesign für
            Heilbronn ins Rampenlicht.
          </li>
          <li>
            Eine intelligente Webentwicklung stellt Ihre beste Performance
            sicher.
          </li>
          <li>
            Mit einer responsiven Webseite ist die Sichtbarkeit auf allen
            Endgeräten garantiert.
          </li>
          <li>
            Mit unserem umfänglichen Support übernehmen wir bei der
            Digitalisierung in Heilbronn Hosting, Pflege und Optimierung.
          </li>
          <p>
            Starten Sie mit uns als Ihre Webdesign Agentur für Heilbronn, dann
            erhalten Sie ein Rundum-sorglos- Paket, das Ihren Erfolg
            sicherstellt, während Sie sich auf Ihre eigentliche Arbeit
            konzentrieren können.
          </p>
          <br />
          <h2>
            Von A bis Z durchdachtes Webdesign für Heilbronn – für messbaren
            Erfolg
          </h2>
          <br />
          <p>
            Ganz gleich, ob Webdesign, Webentwicklung oder{' '}
            <NavLink to="/leistungen/app-entwicklung">
              <b>
                <u>App-Entwicklung</u>
              </b>
            </NavLink>{' '}
            , wir starten in Heilbronn mit einem ersten Auftaktgespräch, bei dem
            es um Sie geht. Was sind Ihre Wünsche? Ihre Vorstellungen? Wohin
            soll die digitale Reise gehen? Hier legen wir den Grundstein für das
            spätere Webdesign. Zusammen definieren wir Ziele, stecken die
            Zielgruppe fest und schauen uns auf dem Markt um. Sobald wir alle
            nötigen Informationen zusammengefasst und zu Papier gebracht haben,
            geht es weiter zur Konzeptphase. Lassen Sie uns Meilensteine für das
            Webdesign für Heilbronn setzen und einen Zeitplan gestalten. Damit
            schaffen wir eine erste Struktur, an dem sich das weitere Vorgehen
            orientiert. Das anschließende Wireframe, welches unsere Experten
            entstehen lassen, zeigt die grobe Skizzierung der späteren Webseite.
            Damit erhalten Sie einen ersten visuellen Eindruck und können uns
            gerne Ihre Änderungswünsche mitteilen. Geben Sie uns dann das „Go“
            für das Webdesign für Heilbronn, geht es für uns an die Umsetzung.
            Dabei bleiben Sie über unser Prototyping-Tool stets über alle
            Prozesse informiert. Ist das perfekte{' '}
            <NavLink to="/leistungen/design">
              <b>
                <u>Webdesign</u>
              </b>
            </NavLink>{' '}
            vollbracht, übernehmen wir optional die Programmierung und bringen
            nach einer umgänglichen Testphase Ihre moderne, ansprechende und
            eindrucksvolle Webseite online.
          </p>
          <br />
          <h2>Webdesign für Heilbronn – Was können wir für Sie tun?</h2>
          <br />
          <p>
            Für uns als Webdesign für Heilbronn sind Zuverlässigkeit, Kompetenz
            und Transparenz sowie eine persönliche und kundennahe Zusammenarbeit
            etablierte Werte. Unsere Kunden bestätigen uns immer wieder, dass
            sie unsere Leistungen und die Qualität unseres Service sehr zu
            schätzen wissen. Daher machen wir beim Webdesign für Heilbronn keine
            Kompromisse und sorgen dafür, dass Sie bei uns hochwertige
            Servicevorteile genießen. Bei uns greifen alle Prozesse ineinander.
            Nutzen Sie unsere App-Entwicklung in Heilbronn für Ihre Präsenz auf
            allen Kanälen.
          </p>
          <p>
            Verschaffen Sie sich am besten selbst einen ersten Eindruck über
            unser Webdesign für Heilbronn und lassen Sie uns Ihre
            Digitalisierung gemeinsam auf ein neues Niveau heben.
          </p>
          <p>
            Bei weiteren Fragen zu Webdesign für Heilbronn, nehmen Sie gerne{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            zu uns auf.
          </p>
        </LPContent>
        <Referenzen category="design" />
        <Kundenmeinungen category="design" />
        <KontaktShort item={Content.contactShort} />
        <Keyfacts item={Content.keyfacts} />
        <Technologien technologie="web" />
        <Quote title={Content.quote.title} autor={Content.quote.autor} />
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default LPWebdesignHeilbronn
