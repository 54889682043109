import SEOHeader from '@components/basics/SEOHeader'
import SimpleHeader from '@components/basics/SimpleHeader'
import Section from '@components/elements/Section'
import React from 'react'
import styled from 'styled-components'

const items = [
  {
    title: 'AV - Allgemeine Vertragsbedingungen',
    link: '/pdfs/agb/AV_Allgemeine_Vertragsbedingungen_Bitkom.pdf'
  },
  {
    title: 'DL - Dienstleistungen',
    link: '/pdfs/agb/DL_Dienstleistungen_Bitkom.pdf'
  },
  {
    title: 'SaaS - Software as a Service',
    link: '/pdfs/agb/SaaS_Bitkom.pdf'
  },
  {
    title: 'VES - Erstellung Individualsoftware',
    link: '/pdfs/agb/VES_Erstellung_Individualsoftware_Bitkom.pdf'
  },
  {
    title: 'VPS - Pflege Standardsoftware',
    link: '/pdfs/agb/VPS_Pflege_Standardsoftware_Bitkom.pdf'
  },
  {
    title: 'VÜ - Überlassung Standardsoftware (Kauf)',
    link: '/pdfs/agb/VUE_Ueberlassung_Standardsoftware_Bitkom.pdf'
  },
  {
    title: 'WV - Werkverträge',
    link: '/pdfs/agb/WV_Werkvertraege_Bitkom.pdf'
  }
]

const Item = styled.div`
  margin: 0 0 10px;
  a {
    p.text {
      display: inline-block;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  i {
    margin: 0 10px 0 0;
    font-size: 24px;
    line-height: 1;
  }
`

const AGB = () => {
  return (
    <>
      <SEOHeader id="agb" />
      <SimpleHeader title="AGB" />
      <Section dark={false}>
        <div className="container">
          <h3>AGB - Allgemeine Geschäftsbedingungen (Bitkom)</h3>
          <br />
          {items.map((item, i) => (
            <Item key={i}>
              <a href={item.link} title={item.title} download>
                <i className="material-icons">picture_as_pdf</i>
                <p className="text">{item.title}</p>
              </a>
            </Item>
          ))}
        </div>
      </Section>
    </>
  )
}

export default AGB
