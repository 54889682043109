import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import { Item, LabelContainer } from '@components/sections/LPContent'
import LandingpageContent from '@content/landingpages/agentur/digitalisierung.json'
import Content from '@content/leistungen/digitalisierung.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)
const Technologien = React.lazy(
  () => import('@components/sections/Technologien')
)

const Digitalisierungsagentur = () => {
  return (
    <>
      <SEOHeader id="lp-agentur-digitalisierung" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Warum Digitalisierung mit Agentur?</h2>
          <br />
          <Item>
            <LabelContainer>
              <label>01</label>
              <p className="headline">Digitalisierungsstrategie</p>
            </LabelContainer>
            <p>
              Der Handlungsbedarf wird von den meisten Unternehmen zwar erkannt,
              doch oft fehlt das <b>Know-how</b> darüber, eine Firma erfolgreich
              zu digitalisieren und somit optimieren sowie zu integrieren. Eine
              professionelle Digitalisierungs Agentur, wie{' '}
              <NavLink to="/">
                <b>
                  <u>Peakconcepts</u>
                </b>
              </NavLink>
              , unterstützt Sie beim Aufbau und der Umsetzung einer{' '}
              <b>Digitalisierungsstrategie</b> basierend auf einer umfänglichen
              Analyse. Mit <b>effizienten Wissenstransfer</b> sorgen wir dafür,
              dass Sie Ihr Unternehmen auch zukünftig auf Digitalisierungskurs
              halten.
            </p>
          </Item>

          <Item>
            <LabelContainer>
              <label>02</label>
              <p className="headline">Beratung</p>
            </LabelContainer>
            <p>
              Mit einer Digitalisierung von unserer Agentur etablieren Sie
              beispielsweise in Ihrem Unternehmen{' '}
              <b>ganzheitliche IT-Lösungen</b>. Bei unserer professionellen{' '}
              <NavLink to="/leistungen/beratung">
                <b>
                  <u>Beratung</u>
                </b>
              </NavLink>{' '}
              holen wir Sie an Ihrem Status Quo ab und generieren eine auf Ihr
              Unternehmen individuell zugeschnittene Digitalisierungsstrategie.
            </p>
          </Item>

          <Item>
            <LabelContainer>
              <label>03</label>
              <p className="headline">Online-Marketing</p>
            </LabelContainer>
            <p>
              Bei einer Digitalisierung via Agentur übernehmen wir für Sie unter
              anderem das{' '}
              <NavLink to="/leistungen/online-marketing">
                <b>
                  <u>Online-Marketing</u>
                </b>
              </NavLink>
              . Dadurch steigern wir Ihre <b>Sichtbarkeit im Netz</b> und sorgen
              so für mehr Popularität Ihres Unternehmens. Dank einer
              intelligenten Marketingstrategie erreichen wir auf digitalen Wegen
              verstärkt Ihre Zielgruppe.
            </p>
          </Item>

          <Item>
            <LabelContainer>
              <label>04</label>
              <p className="headline">Webentwicklung</p>
            </LabelContainer>
            <p>
              Legen Sie Ihre{' '}
              <NavLink to="/leistungen/webentwicklung">
                <b>
                  <u>Webentwicklung</u>
                </b>
              </NavLink>{' '}
              in unsere erfahrenen und kompetenten Hände. Mit viel Know-how und
              der nötigen Kreativität des Expertenteams unserer Digitalisierungs
              Agentur erstellen wir für Sie Ihre <b>optimale Webpräsenz</b>.
            </p>
          </Item>

          <Item>
            <LabelContainer>
              <label>05</label>
              <p className="headline">Webdesign</p>
            </LabelContainer>
            <p>
              Mit Digitalisierung durch unsere Agentur verleihen wir mittels
              unverwechselbarem{' '}
              <NavLink to="/leistungen/design">
                <b>
                  <u>Webdesign</u>
                </b>
              </NavLink>{' '}
              unseren Kunden ein <b>einzigartiges Erscheinungsbild</b> mit hohem
              Wiedererkennungswert.
            </p>
          </Item>

          <Item>
            <LabelContainer>
              <label>06</label>
              <p className="headline">App-Entwicklung</p>
            </LabelContainer>
            <p>
              Setzen Sie auf{' '}
              <NavLink to="/leistungen/app-entwicklung">
                <b>
                  <u>App-Entwicklung</u>
                </b>
              </NavLink>{' '}
              aus unserem Hause und zeigen Sie sich mit Ihrem Unternehmen
              absolut Up-to-Date. Optimieren Sie so Ihre <b>Erreichbarkeit</b>{' '}
              für Ihre Kunden und diejenigen, die es noch werden wollen, auf
              sämtlichen digitalen Endgeräten.
            </p>
          </Item>

          <br />
          <h2>
            Als Unternehmen clever digitalisieren – mit einem durchdachten
            Konzept von Peakconcepts
          </h2>
          <br />
          <p>
            Beginnend mit einem Erstgespräch stecken wir Ihre{' '}
            <b>Ansprüche und Ziele</b> gemeinsam mit Ihnen ab. Im Anschluss
            verschafft sich das Expertenteam unserer Digitalisierungs Agentur
            einen Überblick über die <b>aktuelle Situation</b> und erstellt auf
            dieser Grundlage eine Digitalisierungsstrategie. Dem angeschlossen
            ist die <b>Einleitung der Optimierung</b>, die im Konzept
            theoretisch erarbeitet wurde. Mit Maßnahmen, wie der Umstellung auf
            schlanke und innovative IT-Systeme, Anwendungen und Tools werden
            Prozesse in kurzer Zeit erkennbar vereinfacht. So sparen Sie bereits
            ab dem ersten Tag beim Digitalisieren des Unternehmens Zeit und
            Geld. Ferner beraten wir Sie, wie die Implementierung{' '}
            <b>dauerhaft gelingt</b> und bieten <b>Schulungsprogramme</b> für
            Ihre Mitarbeiterinnen und Mitarbeiter.
          </p>
          <br />
          <h2>Digitalisieren mit Förderung: Das go-digital Programm</h2>
          <br />
          <p>
            Möchten Sie als Unternehmen digitalisieren, bieten wir Ihnen als
            autorisierte Digitalisierungs Agentur die Möglichkeit, mit dem
            digitalen Projekt <b>„go-digital“</b> die Fördergelder des
            Bundesministeriums für Wirtschaft und Energie (BMWi) zu nutzen. Sie
            haben mit uns als zertifizierte Agentur für Digitalisierung mit dem
            Modul „Digitale Markterschließung“ die Chance, sich bis zu 11.000 €
            für Ihre neue Firmenwebseite oder den effizienten Online-Shop zu
            sichern. Dabei entsteht für Sie <b>keinerlei Aufwand</b>, denn wir
            übernehmen dafür alle Formalitäten.
          </p>
          <br />
          <p>
            Wir sind Ihre Digitalisierungs Agentur 4.0 – lassen Sie uns zusammen
            das Beste aus Ihrem Unternehmen herausholen und in eine starke
            Zukunft investieren. Nehmen Sie jetzt unverbindlich{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            zu uns auf.
          </p>
        </LPContent>
        <Kundenmeinungen category="app" />
        <Technologien technologie="app" />
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default Digitalisierungsagentur
