/* eslint-disable react-hooks/exhaustive-deps */
import Section from '@components/elements/Section'
import useWindowDimensions from '@services/useWindowDimensions'
import React, { useEffect } from 'react'
import { scroller } from 'react-scroll'
import styled from 'styled-components'

import ResponsiveImage from '@components/basics/ResponsiveImage'
// @ts-ignore
const gsap = window.gsap
// @ts-ignore
const ScrollTrigger = window.ScrollTrigger
gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.config({
  autoRefreshEvents:
    'visibilitychange,DOMContentLoaded,load,orientationchange,resize'
})
const OuterContainer = styled.div`
  height: 200vh;
  max-height: 200vh;
  max-width: 100vw;
  width: 100vw;
  @media (max-width: 768px) {
    height: 100vh;
    max-height: 100vh;
  }
`
const InnerContainer = styled.div`
  background: transparent;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  width: 100%;
  padding: 0 0;
  display: flex;
  justify-content: center;

  overflow: hidden;
  align-items: center;
  color: #fff;
  will-change: transform;
  @media (max-width: 768px) {
    text-align: center;
    flex-direction: column-reverse;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    padding: 12% 3rem;
  }
`
const LabelContainer = styled.div`
  display: flex;
  flex: 0 2 40%;
  flex-direction: column;
  padding: 0 0 0 80px;
  box-sizing: border-box;
  will-change: transform;
  label,
  p {
    color: #fff;
  }
  label {
    font-size: 1.5rem;
  }
  p {
    font-size: 3.5rem;
  }
  button {
    width: fit-content;
  }
  @media (max-width: 1400px) {
    label {
      font-size: 1.2rem;
    }
    p {
      font-size: 2.8rem;
    }
  }
  @media (max-width: 1100px) {
    label {
      font-size: 1.35rem;
    }
    p {
      font-size: 2.5rem;
    }
  }
  @media (max-width: 970px) {
    label {
      font-size: 1.2rem;
    }
    p {
      font-size: 2rem;
    }
  }
  @media (max-width: 768px) {
    padding: 0 4rem;

    text-align: center;
    flex: 0 1 50%;
    label {
      font-size: 1rem;
      display: inline-block;
      line-height: 1.25;
    }
    p {
      font-size: 1.5rem;
    }
    button {
      width: 100%;
    }
  }
`
const ImageContainer = styled.div`
  display: flex;
  flex: 0 3 60%;
  will-change: transform;
  @media (max-width: 768px) {
    flex: 0 1 50%;
    padding: 2rem;
  }
`
const Effect = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-bottom: 600px solid rgba(255, 255, 255, 0.1);
  border-left: 600px solid transparent;
  will-change: transform;
  @media (max-width: 768px) {
    border-bottom: 100vh solid rgba(255, 255, 255, 0.1);
    border-left: 100vh solid transparent;
  }
`
enum AnimationElements {
  Triangle = 0,
  LabelContainer = 1,
  ImageContainer = 2
}
const HeroHeader = ({
  item
}: {
  item: {
    title: string
    label?: string
    button: { text: string; link?: string }
    image: { src: string; alt: string }
  }
}) => {
  let timeline: any
  let localInstances: any = []
  const {
    windowHeight,
    windowWidth,
    isTouchDeviceInLandscape
  } = useWindowDimensions()
  const scaleValue = (index: number) => {
    if (isTouchDeviceInLandscape) return 1
    if (windowWidth > 768) return 1.4
    return 1.1
  }

  const translateXValue = (index: number) => {
    if (isTouchDeviceInLandscape) return 0
    if (index === AnimationElements.Triangle) return 0

    if (windowWidth <= 768) return 0
    if (index === AnimationElements.ImageContainer) {
      return windowWidth
    }
    if (index === AnimationElements.LabelContainer) {
      const textWidth = windowWidth * 0.4 - 80
      const destination = windowWidth / 2
      const output = destination - 80 - textWidth / 2
      return output
    }
    return 0
  }

  const opacity = (index: number) => {
    if (isTouchDeviceInLandscape) return 1
    if (index === AnimationElements.Triangle) return 0
    return 1
  }

  const setScrollTriggers = () => {
    timeline = gsap.timeline({})
    timeline.to('#animated', {
      opacity: opacity,
      x: translateXValue,
      scale: scaleValue,
      scrollTrigger: {
        trigger: '#stickyContainer',
        start: 0,
        end: windowHeight,
        scrub: windowWidth > 768 ? 0.5 : true,
        pinSpacing: false,
        //pinReparent: true,
        anticipatePin: 0.3,
        invalidateOnRefresh: true,
        ease: 'power2',
        pin: windowWidth > 768 && !isTouchDeviceInLandscape
      }
    })
    ScrollTrigger.getAll().forEach((i: any) => localInstances.push(i))
  }
  useEffect(() => {
    setTimeout(() => {
      setScrollTriggers()
    }, 301)

    return () => {
      localInstances.forEach((instance: any) => {
        instance.kill()
      })
    }
  }, [isTouchDeviceInLandscape])

  const scrollDown = async () => {
    await scroller.scrollTo('contact', {
      duration: 1000,
      delay: 500,
      smooth: true
    })
    //@ts-ignore
    if ('activeElement' in document) document.activeElement.blur()
  }

  return (
    <Section dark noPadding>
      <OuterContainer id="outerContainer">
        <InnerContainer id="stickyContainer">
          <Effect id="animated" />
          <LabelContainer id="animated">
            <label>{item.label}</label>
            <p className="title">{item.title}</p>
            <button className="button is-primary " onClick={scrollDown}>
              {item.button.text}
            </button>
          </LabelContainer>

          <ImageContainer id="animated">
            <ResponsiveImage src={item.image.src} alt={item.image.alt} noLazy />
          </ImageContainer>
        </InnerContainer>
      </OuterContainer>
    </Section>
  )
}

export default HeroHeader
