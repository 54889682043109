import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/agentur/digital.json'
import Content from '@content/leistungen/digital.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)
const Technologien = React.lazy(
  () => import('@components/sections/Technologien')
)

const Digitalagentur = () => {
  return (
    <>
      <SEOHeader id="lp-agentur-digital" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Warum Peakconcepts als Ihre Digitalagentur?</h2>
          <br />
          <p>
            Als Digitalagentur mit einem umfangreichen Full-Service kümmern wir
            uns von A bis Z um Ihren digitalen Auftritt. Von der Analyse über
            die Konzeption bis hin zur Umsetzung, Implementierung und laufenden
            Optimierung sind wir zuverlässig und kompetent an Ihrer Seite. In
            unserer Arbeit kombinieren wir gekonnt Technik, Marketing und
            Kreativität, sodass Sie sich mit uns nachhaltig digital platzieren.
            Ebenso arbeiten wir als Digitalagentur für Sie stets transparent.
            Sie erhalten regelmäßige Reportings, Informationen und Auswertungen.
          </p>
          <p>Die Phasen einer Zusammenarbeit mit uns als Digitalagentur:</p>
          <li>umfangreiche Beratung</li>
          <li>gründliche Analyse des Status quo</li>
          <li>Festlegung von Zielen und Meilensteinen</li>
          <li>individuelle Erstellung eines Konzeptes</li>
          <li>Umsetzung der Maßnahmen</li>
          <li>Evaluation und Kontrolle</li>
          <li>Optimierung der Prozesse</li>
          <li>Wartung und Service</li>
          <p>
            Selbstverständlich können Sie sich bei uns als Digitalagentur darauf
            verlassen, dass wir gemäß gesetzlichen Regelungen den Datenschutz
            berücksichtigen und auf höchste Sicherheit achten.
          </p>
          <br />
          <h2>
            Starten Sie jetzt mit uns von Peakconcepts als Ihre Digitalagentur
          </h2>
          <br />
          <p>
            Lassen Sie uns Ihren nächsten Schritt in die digitale Zukunft
            gemeinsam gehen. Wir sind Ihre professionelle Digitalagentur. Bei
            uns erwarten Sie maßgeschneiderte Lösungen, zielführende
            Marketingkonzepte und auf Wunsch eine langfristige Betreuung Ihrer
            Internetzpräsenz. Lernen Sie uns als Digitalagentur in einem
            kostenfreien und unverbindlichen Erstgespräch kennen. Gerne
            beantworten wir all Ihre Fragen und beraten Sie über Ihre
            Möglichkeiten. Nehmen Sie jetzt{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            zu uns auf!
          </p>
        </LPContent>
        <Kundenmeinungen category="app" />
        <Technologien technologie="app" />
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default Digitalagentur
