import ResponsiveImage from '@components/basics/ResponsiveImage'
import SEOHeader from '@components/basics/SEOHeader'
import Section from '@components/elements/Section'

import HeroHeader from '@components/sections/HeroHeader'
import Content from '@content/agentur.json'
import React, { Suspense } from 'react'
import styled from 'styled-components'

const Technologien = React.lazy(
  () => import('@components/sections/Technologien')
)
const Title = React.lazy(() => import('@components/elements/Title'))
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))

interface InfoItemProps {
  right?: boolean
}

const InfoItem = styled.p<InfoItemProps>`
  background: #000;
  font-size: 24px;
  color: #fff;
  font-weight: bold;
  padding: 20px;
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
`

const Item = styled.div`
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  > div {
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    h2 {
      display: inline-block;
    }
    label {
      color: ${(props) => props.theme.primary};
      font-weight: bold;
      font-size: 6rem;
      line-height: 1;
      margin: 0 10px 0 0;
    }
  }
  p {
    font-size: 15px;
    margin-top: 5px;
  }
`

const Agentur = () => {
  return (
    <>
      <SEOHeader id="agentur" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <Section dark={false}>
          <Title title={Content.content.title} dark={false} titleSize={1} />
          <div className="columns is-variable is-6">
            <div className="column is-4 is-offset-2 is-offset-1-mobile is-10-mobile">
              <ResponsiveImage
                src="/images/agentur/inno-z.jpg"
                alt="Innovationszentrum Aalen"
              />
              <span>Firmensitz: Innovationszentrum Aalen</span>
            </div>
            <div className="column is-6 pt-6">
              <InfoItem>{Content.keyfacts.item1}</InfoItem>
              <div className="column is-8">
                <Item>
                  <div>
                    <label>01</label>
                    <h2>{Content.content.items[0].title}</h2>
                  </div>
                  <p>{Content.content.items[0].text}</p>
                </Item>
              </div>
            </div>
          </div>
          <div className="columns is-variable is-6">
            <div className="column is-12">
              <div className="column is-4 is-offset-3">
                <Item>
                  <div>
                    <label>02</label>
                    <h2>{Content.content.items[1].title}</h2>
                  </div>
                  <p>{Content.content.items[1].text}</p>
                </Item>
              </div>
            </div>
          </div>
          <div className="columns is-variable is-6">
            <div className="column is-offset-1-mobile is-10-mobile is-hidden-tablet">
              <ResponsiveImage src="/images/agentur/1.jpg" alt="Agentur1" />
            </div>
            <div className="column is-6 pt-6">
              <InfoItem>{Content.keyfacts.item2}</InfoItem>
              <div className="column is-8 is-offset-3">
                <Item>
                  <div>
                    <label>03</label>
                    <h2>{Content.content.items[2].title}</h2>
                  </div>
                  <p>{Content.content.items[2].text}</p>
                </Item>
              </div>
            </div>
            <div className="column is-4 is-hidden-mobile">
              <ResponsiveImage src="/images/agentur/1.jpg" alt="Agentur1" />
            </div>
          </div>
          <div className="columns">
            <div className="column is-4 is-offset-2 is-offset-1-mobile is-10-mobile">
              <ResponsiveImage src="/images/agentur/2.jpg" alt="Agentur2" />
            </div>
            <div className="column is-4 pt-5">
              <div className="column is-12">
                <Item>
                  <div>
                    <label>04</label>
                    <h2>{Content.content.items[3].title}</h2>
                  </div>
                  <p>{Content.content.items[3].text}</p>
                </Item>
              </div>
            </div>
          </div>
          <div className="columns">
            <div className="column is-7 is-offset-5 mt-6">
              <InfoItem>{Content.keyfacts.item3}</InfoItem>
            </div>
          </div>
          <div className="columns">
            <div className="column is-12">
              <div className="column is-4 is-offset-2">
                <Item>
                  <div>
                    <label>05</label>
                    <h2>{Content.content.items[4].title}</h2>
                  </div>
                  <p>{Content.content.items[4].text}</p>
                </Item>
              </div>
            </div>
          </div>
          <div className="columns is-centered">
            <div className="column is-12 mt-5">
              <ResponsiveImage
                src="/images/agentur/3.jpg"
                alt="Agentur3"
                style={{ width: '100vw' }}
              />
            </div>
          </div>
        </Section>
        <Technologien technologie="agency" />
        <Kontakt />
      </Suspense>
    </>
  )
}

export default Agentur
