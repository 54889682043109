import LogoContainer from '@components/elements/LogoContainer'
import Section from '@components/elements/Section'
import Title from '@components/elements/Title'
import React from 'react'
import styled from 'styled-components'

interface ItemsInterface {
  title: string
  src: string
  onlyShowOn?: 'mobile' | 'desktop'
}
const items: ItemsInterface[] = [
  {
    title: 'Otto',
    src: require('../../assets/logos/kunden/otto.svg').default
  },
  {
    title: 'Babyclub.de',
    src: require('../../assets/logos/kunden/babyclub.svg').default
  },
  {
    title: 'Neudorff',
    src: require('../../assets/logos/kunden/neudorff.svg').default
  },
  {
    title: 'Bodymedia Innovationdays',
    src: require('../../assets/logos/kunden/bodymedia-innovationdays.svg')
      .default
  },
  {
    title: 'Cellgarden',
    src: require('../../assets/logos/kunden/cellgarden.svg').default
  },
  {
    title: 'ConSol',
    src: require('../../assets/logos/kunden/consol.svg').default
  },
  {
    title: 'Cosmos Fitness',
    src: require('../../assets/logos/kunden/cosmos-fitness.svg').default
  },
  {
    title: 'Daimler TSS',
    src: require('../../assets/logos/kunden/daimler-tss.svg').default
  },
  {
    title: 'eHealthTec',
    src: require('../../assets/logos/kunden/eHealthTec.svg').default
  },
  {
    title: 'Novafon',
    src: require('../../assets/logos/kunden/novafon.svg').default
  },
  {
    title: 'Panasonic',
    src: require('../../assets/logos/kunden/panasonic.svg').default
  },
  {
    title: 'GS Immoservice',
    src: require('../../assets/logos/kunden/gs-immoservice.svg').default
  },
  {
    title: 'hierfoods',
    src: require('../../assets/logos/kunden/hierfoods.svg').default
  },
  {
    title: 'HNIDA Logistik',
    src: require('../../assets/logos/kunden/hnida-logistik.svg').default
  },
  {
    title: 'Impact One GmbH',
    src: require('../../assets/logos/kunden/impact-one.svg').default
  },
  {
    title: 'Inside Logistics',
    src: require('../../assets/logos/kunden/inside-logistics.svg').default
  },
  {
    title: 'JBcosmetics',
    src: require('../../assets/logos/kunden/jb-cosmetics.svg').default
  },
  {
    title: 'Jobcenter Ostalbkreis',
    src: require('../../assets/logos/kunden/jobcenter.svg').default
  },
  {
    title: 'Maier & Beuther',
    src: require('../../assets/logos/kunden/maier-beuther.svg').default
  },
  {
    title: 'MeetYourMaster GmbH',
    src: require('../../assets/logos/kunden/meetyourmaster.svg').default
  },
  {
    title: 'Metallbau Wiedemann',
    src: require('../../assets/logos/kunden/metallbau-wiedemann.svg').default
  },
  {
    title: 'Ministerium für Ländlichen Raum und Verbraucherschutz',
    src: require('../../assets/logos/kunden/mlr.svg').default
  },
  {
    title: 'o2',
    src: require('../../assets/logos/kunden/o2.svg').default
  },
  {
    title: 'Oldtimertrend',
    src: require('../../assets/logos/kunden/oldtimertrend.svg').default,
    onlyShowOn: 'desktop'
  },
  {
    title: 'PackPart',
    src: require('../../assets/logos/kunden/packpart.svg').default
  },
  {
    title: 'Remax',
    src: require('../../assets/logos/kunden/remax.svg').default
  },
  {
    title: 'SonnStrom GmbH',
    src: require('../../assets/logos/kunden/sonnstrom.svg').default
  },
  {
    title: 'OBI',
    src: require('../../assets/logos/kunden/obi.svg').default
  },
  {
    title: 'understood',
    src: require('../../assets/logos/kunden/understood.svg').default,
    onlyShowOn: 'desktop'
  },
  /* {
    title: 'wproduction',
    src: require('../../assets/logos/kunden/wproduction.svg').default
  }, */
  {
    title: 'Wüstenrot Immobilien',
    src: require('../../assets/logos/kunden/wuestenrot.svg').default
  }
]
const HideOnMobile = styled.div`
  @media (max-width: 600px) {
    display: none;
  }
`
const HideOnDesktop = styled.div`
  @media (min-width: 601px) {
    display: none;
  }
`
const Kundenlogos = ({ dark = true }: { dark?: boolean }) => {
  // Its ugly but i dont care, is because of prerender
  return (
    <Section dark={dark}>
      <div className="container">
        <Title title="Zufriedene <span>Kunden</span>" titleSize={0} />
        <HideOnDesktop>
          <LogoContainer
            items={items.filter(
              (i) => !i.onlyShowOn || i.onlyShowOn === 'mobile'
            )}
          />
        </HideOnDesktop>
        <HideOnMobile>
          <LogoContainer
            items={items.filter(
              (i) => !i.onlyShowOn || i.onlyShowOn === 'desktop'
            )}
          />
        </HideOnMobile>
      </div>
    </Section>
  )
}

export default Kundenlogos
