import { DarkContext } from '@pages/App'
import useScrollPosition from '@services/useScrollPosition'
import variables from '@themes/variables'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { useViewportSize } from 'web-api-hooks'

interface HeaderInterface {
  open?: boolean
  dark?: boolean
}
interface LogoInterface extends HeaderInterface {
  fadeOut: boolean
}
const NavBar = styled.div<HeaderInterface>`
  position: fixed;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  padding: 10px 16px 0;
  z-index: 10003;

  @media (max-width: 992px) {
    padding-top: 0;
  }
`
const Logo = styled.img<LogoInterface>`
  filter: ${({ open, dark }) => {
    if ((open && !dark) || (dark && !open))
      return 'grayscale(1) saturate(0) invert(1)'
    return null
  }};
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  opacity: ${({ fadeOut }) => (fadeOut ? 0 : 1)};
  transform: translateY(${({ fadeOut }) => (fadeOut ? '-16px' : 0)});
`
const RoundLogo = styled(Logo)`
  position: absolute;
  left: 16px;
  opacity: ${({ fadeOut }) => (fadeOut ? 1 : 0)};
  transform: translateY(${({ fadeOut }) => (fadeOut ? 0 : '16px')});
`
const Burger = styled.button<HeaderInterface>`
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  width: 30px;
  height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }

  div {
    width: 30px;
    height: 4px;
    background: ${({ dark, open }) => {
      if (open) {
        if (dark) return 'black'
        else return 'white'
      }
      if (dark) return 'white'
      return 'black'
    }};

    position: relative;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;

    :first-child {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
      transform-origin: 0% 0%;
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) =>
        open ? 'translateX(-20px)' : 'translateX(0)'};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
      transform-origin: 0% 100%;
    }
  }
`
const Menu = styled.nav<HeaderInterface>`
  display: flex;
  flex-direction: column;
  background: ${({ dark }) => (dark ? 'white' : 'black')};
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.6s ease-in-out;
  transition-delay: 0.2s;
  z-index: 10002;
  clip-path: polygon(100% 0, 100% 0%, 100% 100%, 100% 100%, 100% 50%);

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: ${({ open }) => (open ? 'flex' : 'none')};
  }
`
const MenuInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15% 0 10%;
  width: 100vw;
`

const ActiveNavLink = (props: any) => (
  <NavLink
    {...props}
    dark={undefined}
    activeStyle={{
      textDecoration: 'underline',
      textDecorationColor: variables.primary
    }}
  />
)
const BigLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15%;
  width: fit-content;
`
const BigLink = styled(ActiveNavLink)<HeaderInterface>`
  font-size: 60px;
  line-height: 75px;
  font-weight: 800;
  margin: 0;
  color: ${({ dark }) => (dark ? 'black' : 'white')};
  &:hover {
    color: ${(props) => (props.dark ? '#757575' : props.theme.primary)};
  }
  @media (max-width: 750px) {
    font-size: 50px;
    line-height: 65px;
  }
  @media (max-width: 550px) and (max-height: 750px), (max-width: 400px) {
    font-size: 32px;
    line-height: 40px;
  }
`
const SmallLinkContainer = styled.div`
  display: flex;
  margin: 0 0 0 15%;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`
const SmallLink = styled(ActiveNavLink)<HeaderInterface>`
  font-size: 30px;
  line-height: 30px;
  margin: 0;
  color: ${({ dark }) => (dark ? 'black' : 'white')};
  &:hover {
    color: ${(props) => (props.dark ? '#757575' : props.theme.primary)};
  }
  @media (max-width: 700px) and (max-height: 750px), (max-width: 400px) {
    font-size: 16px;
    line-height: 1.5;
  }
`
const Seperator = styled.div`
  ::before {
    font-size: 30px;
    line-height: 30px;
    padding: 0 10px;
    content: '|';
    @media (max-width: 700px) {
      display: none;
    }
  }
`

const DarkLocations = ['leistungen']
const LightLocations = [
  'home',
  'agentur',
  'referenzen',
  'kontakt',
  'jobs',
  'agb',
  'datenschutz',
  'impressum'
]
const Header = () => {
  const darkContext = useContext(DarkContext)
  const dark = (darkContext.get as unknown) as boolean

  const [menuOpen, setMenuOpen] = useState(false)
  const { pageYOffset } = useScrollPosition()
  const [, visualViewportHeight] = useViewportSize()

  useEffect(() => {
    if (menuOpen) document.documentElement.classList.add('is-clipped')
    else document.documentElement.classList.remove('is-clipped')
    return () => {
      document.documentElement.classList.remove('is-clipped')
    }
  }, [menuOpen])
  const closeMenu = (location?: string) => {
    if (
      location &&
      ((!dark && LightLocations.includes(location)) ||
        (dark && DarkLocations.includes(location)))
    ) {
      // Switching from Light to Dark, slow it down
      setTimeout(() => setMenuOpen(false), 800)
    } else setMenuOpen(false)
  }

  const fadeOut = useMemo(() => {
    /* Fade between Logos after X Pixel Scroll */
    return pageYOffset > 200
  }, [pageYOffset])

  return (
    <>
      <NavBar dark={dark} open={menuOpen}>
        <a href="/" style={{ margin: 0 }}>
          <Logo
            fadeOut={fadeOut}
            dark={dark}
            open={menuOpen}
            src={require('../../assets/logo.svg').default}
            alt="Peakconcepts App Entwicklung Aalen"
            width="120"
          />
          <RoundLogo
            fadeOut={fadeOut}
            dark={dark}
            open={menuOpen}
            src={require('../../assets/logo-round.svg').default}
            alt="Peakconcepts App Entwicklung Aalen"
            width="30"
            height="30"
          />
        </a>
        <Burger
          open={menuOpen}
          onClick={() => setMenuOpen((o) => !o)}
          dark={dark}>
          <div />
          <div />
          <div />
        </Burger>
      </NavBar>
      <CSSTransition
        mountOnEnter
        in={menuOpen}
        timeout={200}
        classNames="animated-header">
        <Menu open={menuOpen} dark={dark}>
          <MenuInner
            style={{
              height:
                visualViewportHeight ||
                document.documentElement?.clientHeight ||
                window.innerHeight
            }}>
            <BigLinkContainer>
              <BigLink
                dark={dark}
                to="/"
                exact
                onClick={() => closeMenu('home')}>
                Startseite
              </BigLink>
              <BigLink
                dark={dark}
                to="/leistungen"
                onClick={() => closeMenu('leistungen')}>
                Leistungen
              </BigLink>
              <BigLink
                dark={dark}
                to="/agentur"
                onClick={() => closeMenu('agentur')}>
                Agentur
              </BigLink>
              <BigLink
                dark={dark}
                to="/referenzen"
                onClick={() => closeMenu('referenzen')}>
                Referenzen
              </BigLink>
              <BigLink
                dark={dark}
                to="/kontakt"
                onClick={() => closeMenu('kontakt')}>
                Kontakt
              </BigLink>
            </BigLinkContainer>

            <SmallLinkContainer>
              <SmallLink
                dark={dark}
                to="/jobs"
                onClick={() => closeMenu('jobs')}>
                Jobs
              </SmallLink>
              <Seperator />

              <SmallLink dark={dark} to="/agb" onClick={() => closeMenu('agb')}>
                AGB
              </SmallLink>
              <Seperator />
              <SmallLink
                dark={dark}
                to="/impressum"
                onClick={() => closeMenu('impressum')}>
                Impressum
              </SmallLink>
              <Seperator />
              <SmallLink
                dark={dark}
                to="/datenschutz"
                onClick={() => closeMenu('datenschutz')}>
                Datenschutz
              </SmallLink>
            </SmallLinkContainer>
          </MenuInner>
        </Menu>
      </CSSTransition>
    </>
  )
}

export default Header
