import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/webdesign/schwaebisch-hall.json'
import Content from '@content/leistungen/design.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const KontaktShort = React.lazy(
  () => import('@components/sections/KontaktShort')
)
const Quote = React.lazy(() => import('@components/sections/Quote'))
const Technologien = React.lazy(
  () => import('@components/sections/Technologien')
)
const Keyfacts = React.lazy(() => import('@components/sections/Keyfacts'))
const Referenzen = React.lazy(() => import('@components/sections/Referenzen'))

const LPWebdesignSchwaebischHall = () => {
  return (
    <>
      <SEOHeader id="lp-webdesign-schwaebisch-hall" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Die Leistungen im Webdesign für Sie in Schwäbisch Hall</h2>
          <br />
          <p>
            Ein ganzheitliches Denken ist bei uns in unseren Serviceleistungen
            für ein Webdesign in Schwäbisch Hall selbstverständlich. Bei uns
            erhalten Sie zudem alles aus einer erfahrenen Hand:
          </p>
          <li>professionelles Webdesign für Ihr authentisches Markenimage</li>
          <li>
            starke Performance bei der{' '}
            <NavLink to="/leistungen/webentwicklung">
              <b>
                <u>Webentwicklung</u>
              </b>
            </NavLink>
          </li>
          <li>
            wichtige responsive Webseiten für Ihre Präsenz auf allen Endgeräten
          </li>
          <li>
            umfassender Service und Support inklusive Hosting, Pflege und
            Optimierung
          </li>
          <p>
            Bei uns erwartet Sie ein Rundum-sorglos-Paket beim Webdesign in
            Schwäbisch Hall, das keine Wünsche offenlässt. Wir machen einen
            perfekten Job, damit Sie sich auf Ihren konzentrieren können.
          </p>
          <br />
          <h2>Webdesign in Schwäbisch Hall mit messbaren Erfolgen</h2>
          <br />
          <p>
            Wir setzen den ersten Schritt auf unserem gemeinsamen Weg für Ihr
            Webdesign in Schwäbisch Hall mit einem Auftaktgespräch, in dem es um
            Ihre Wünsche und Vorstellungen geht. Darauf basierend lassen wir
            unser fachliches Know-how einfließen und zeigen Ihnen, was alles
            möglich ist. Im Anschluss definieren wir Ziele, informieren uns
            genau über Ihre Zielgruppe und analysieren Ihre Mitbewerber. Wenn
            das Fundament Ihres Webdesigns in Schwäbisch Hall gelegt ist, geht
            es für uns in die Konzeptphase und wir lassen ein Wireframe
            entstehen. Dabei handelt es sich um eine grobe Skizzierung der
            späteren Webseite und Sie erhalten direkt einen ersten Eindruck.
            Gerne verraten Sie uns Ihre Änderungsideen und geben uns letztlich
            Ihre „Go“ für die Umsetzung. Damit Sie immer wissen, in welcher
            Phase sich Ihr Webdesign in Schwäbisch Hall gerade befindet, setzen
            wir unser smartes Prototyping-Tool ein. Auf Wunsch übernehmen wir
            zum Abschluss die Programmierung, testen Ihre Seite umfassend und
            schalten Sie live.
          </p>
          <br />
          <h2>
            Welche Wünsche haben Sie für Ihr Webdesign in Schwäbisch Hall?
          </h2>
          <br />
          <p>
            Bei uns verlassen Sie sich auf eine professionelle Arbeitsweise,
            höchste Ansprüche an Qualität und ein stilvolles Webdesign in
            Schwäbisch Hall. Für uns sind Zuverlässigkeit, Kompetenz,
            Transparenz und eine partnerschaftliche Zusammenarbeit ebenso
            wichtig wie fachliches Know-how, Leistungen und Service. Lassen Sie
            uns zusammen Ihr Webdesign in Schwäbisch Hall auf das neueste Level
            heben.
          </p>
          <p>
            Ergreifen Sie jetzt Ihre Chance und nehmen Sie unverbindlich{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            zu unseren Experten auf. Gemeinsam lassen wir Ihr Webdesign in
            Schwäbisch Hall zu etwas Großem werden, das Ihre Kunden nicht nur
            überzeugt, sondern nachhaltig begeistert.
          </p>
        </LPContent>
        <Referenzen category="design" />
        <Kundenmeinungen category="design" />
        <KontaktShort item={Content.contactShort} />
        <Keyfacts item={Content.keyfacts} />
        <Technologien technologie="web" />
        <Quote title={Content.quote.title} autor={Content.quote.autor} />
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default LPWebdesignSchwaebischHall
