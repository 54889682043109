import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/agentur/employerbranding.json'
import Content from '@content/leistungen/employerbranding.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))

const EmployerBrandingagentur = () => {
  return (
    <>
      <SEOHeader id="lp-agentur-employerbranding" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Employer Branding Agentur: Warum Employer Branding?</h2>
          <br />
          <p>
            Als Employer Branding{' '}
            <NavLink to="/agentur">
              <b>
                <u>Agentur</u>
              </b>
            </NavLink>{' '}
            wissen wir, dass sich der Arbeitsmarkt stets im Umbruch befindet.
            Schon längst sind die Zeiten vorbei, als qualifizierte Bewerber noch
            Schlange bei Ihnen standen, um sich auf eine Stelle mit fachlichen
            Anforderungen zu bewerben. Zweifelsohne kommen weitere Faktoren wie
            Globalisierung, Digitalisierung, der demografische Wandel und der
            Generationswechsel hinzu, was Employer Branding Agenturen nicht nur
            für große Unternehmen, sondern vor allem für mittelständische
            Handwerksbetriebe und regionale Industriefirmen sowie Bauunternehmen
            unabdingbar macht. Es reicht heute also nicht mehr aus, eine
            Stellenanzeige in der regionalen Zeitung zu schalten und auf eine
            Flut an Bewerbungen zu hoffen. Vielmehr wird es Zeit, mit Analysen,
            Maßnahmen und Strategien aktives Employer Branding zusammen mit uns
            als Agentur zu betreiben, um gekonnt den Arbeitsmarkt zu erobern.
            Die neue Form des Personalrekrutierung sorgt für einen Shift in
            Ihrer Personalorganisation und stellt sicher, dass Sie ein Team
            zusammenstellen, dass wirklich zu Ihrem Unternehmen passt.
            Zeitgleich wirkt sich das Employer Branding positiv auf die
            Mitarbeiterbindung und Arbeitsleistung insgesamt aus. Denn in einem
            Umfeld, wo Werte und eine sinnstiftende Kultur gelebt werden, fühlen
            sich auch Mitarbeiter wohl und arbeiten deutlich motivierter.
          </p>
          <br />
          <h2>
            Ihre Vorteile mit uns als professionelle Agentur für Employer
            Branding
          </h2>
          <br />
          <p>
            Den Weg zu einer bekannten Arbeitgebermarke, bei der man sich gerne
            bewirbt, brauchen Sie nicht allein zu gehen. Nutzen Sie unsere{' '}
            <NavLink to="/leistungen">
              <b>
                <u>Leistungen</u>
              </b>
            </NavLink>{' '}
            als Employer Branding Agentur und ein ausgefeiltes Konzept, das wir
            bei Ihnen implementieren. Sie gewinnen deutlich mehr Sichtbarkeit,
            Präsenz und Aufmerksamkeit – natürlich direkt bei Ihrer Zielgruppe.
            Wir als Ihre Agentur Employer Branding nutzen unter anderem das
            Potenzial von Social Media und sprechen Ihre neuen Talente in ihrer
            gewohnten Umgebung an. Dabei begleiten wir Sie ganzheitlich. Mit
            einer umfassenden Analyse beginnend, starten wir die Konzeption,
            übernehmen die strategische Umsetzung und dauerhafte
            Implementierung. Mittels Monitorings können wir als spezialisierte
            Employer Branding Agentur letztlich die Prozesse nachhaltig
            optimieren.
          </p>
          <p>Ihre Vorteile mit Ihrer Employer Branding Agentur:</p>
          <li>Aufbau einer positiven Arbeitgebermarke</li>
          <li>
            qualifizierte Fachkräfte aus der Region, deutschland- oder
            europaweit
          </li>
          <li>skalierbarer und planbarer Bewerberfluss</li>
          <li>strategisches Vorgehen</li>
          <li>nachhaltiges System</li>
          <li>mehr Qualität durch Vorqualifizierung</li>
          <li>Regulation der Kosten</li>
          <li>Entlastung der Personalabteilung</li>
          <li>mehr Reichweite und Präsenz</li>
          <li>Mitarbeiterbindung</li>
          <br />
          <h2>Employer Branding Agentur: Employer Branding Beispiele</h2>
          <br />
          <p>
            Gerne möchten wir als Employer Branding Agentur Ihnen nachfolgend
            anhand einiger Beispiele bekannter Unternehmen zeigen, wie wirksames
            Employer Branding funktioniert – mal kreativ, mal klar strukturiert,
            mal originell, aber immer für potenzielle Bewerber interessant.
          </p>
          <b>Lufthansa</b>
          <p style={{ marginTop: 16 }}>
            Die deutsche Fluggastgesellschaft Lufthansa machte vor einiger Zeit
            mit einem besonders ansprechenden Employer Branding auf sich
            aufmerksam. Sie initiierte dafür die Kampagne „We are Lufthansa“.
            Dafür holte sich das Unternehmen seine Mitarbeitenden an Bord,
            entwickelte eine zündende Karrierewebseite, brachte den Karriereblog
            „Be Lufthansa“ an den Start und hob mit einem umfassenden Austausch
            auf allen relevanten Social-Media-Kanälen ab. In Hinblick auf den
            Content variiert die Lufthansa mit Impressionen aus dem
            Arbeitsalltag, bildet Studienergebnissen ab, zeigt Bilder von
            Events, nimmt Videos auf, setzt immer wieder Verlinkungen zum Blog
            und bettet ausgeschriebene Vakanzen geschickt ein.
          </p>
          <b>Klinikum Dortmund</b>
          <p style={{ marginTop: 16 }}>
            Kreativ im Employer Branding wird es in einem Umfeld, mit dem man
            sicher nicht rechnet: einem Klinikum. In dem Fall in Dortmund. Statt
            sich auf den klassischen Weg der Mitarbeitersuche zu begeben,
            beschreitet die Klinik aus dem Ruhrpott mit Employer Branding einen
            ganz neuen Pfad. Das Ergebnis ihres Muts: über 80.000 Followerinnen
            und Followern bei TikTok. Die Idee dahinter? Die Mitarbeitenden
            nahmen kleine Videos aus dem Krankenhaus auf, die nicht nur einen
            „normalen“ Einblick in den Berufsalltag verschiedener Bereiche
            zeigen, sondern richtig humorvoll gestaltet sind. Als professionelle
            Employer Branding Agentur ziehen wir vor so viel Engagement den Hut.
          </p>
          <b>Heineken</b>
          <p style={{ marginTop: 16 }}>
            Auch die niederländische Brauerei Heineken zeigt, wie man Employer
            Branding gekonnt auf den Punkt bringt. Grundlage ist auch hier eine
            strukturierte Karrierewebseite, die geschickt die Vorteile der
            Arbeitgebermarke in den Fokus rückt. „Mitarbeiterporträts“
            unterstützen dies mit authentischen Einblicken ins Unternehmen, in
            die Aufgaben und die Arbeitsatmosphäre. Statt langer Recherche um
            offene Stellen, hat Heineken einen smarten Filter auf der Seite mit
            aktuellen Stellenangeboten platziert, sodass Interessierte direkt
            anhand ihrer Qualifikationen nach der passenden Position filtern
            können. Ebenso zur Employer Branding Kampagne gehört ein kurzes
            Imagevideo. Solche spannenden Clips zeigen eine ganz besondere
            Wirkung, weshalb sie auch bei uns als Employer Branding Agentur auf
            Wunsch zum Einsatz kommen.
          </p>
          <b>BMW</b>
          <p style={{ marginTop: 16 }}>
            BWM als einer der bekanntesten Automobilhersteller setzt bei seinem
            Employer Branding auf eine Karrierewebseite gepaart mit eigenen
            Social-Media-Kanälen und einer innovativen Ansprache, die
            Fortschritt, Technologie, Kreativität und Automobile ins Rampenlicht
            rückt. Darüber hinaus stehen die Mitarbeiterenden selbst im
            Mittelpunkt der Kampagne. Auf der Karrierepage liefert der
            Hersteller alle wichtigen Informationen über Benefits, die Ein- und
            Aufstiegsoptionen und gibt eine Menge Tipps rund um die Bewerbung.
            Unser Tipp als Employer Branding Agentur: Platzieren Sie auf Ihrer
            Karriereseite einen möglichst einfach gehaltenen Bewerbungsprozess.
            Das reduziert die Hemmschwelle, sich direkt zu bewerben. Ergänzend
            aktivierte BMW seine Social-Media-Kanäle und teilte hier
            interessante Geschichten des Personals, postete Veranstaltungen und
            immer wieder offene Vakanzen. Abgerundet wird das Konzept mit
            Kurzvideos – sogar mehrsprachig. Was einem internationalen
            Unternehmen mehr als gerecht wird, finden wir als Employer Branding
            Agentur.
          </p>
          <b>ALDI Süd</b>
          <p style={{ marginTop: 16 }}>
            Employer Branding sorgt auch beim Discounter ALDI Süd für Aufsehen.
            Und das besonders einfallsreich. Beim Kölner „Absolventenkongress“
            setzte das Unternehmen auf eine Anwesenheit „inkognito“. In einer
            „Blackbox“ bekamen Bewerber die Möglichkeit, sich vollkommen anonym
            bei einem Personalmitarbeiter vorzustellen. Recruiting in absoluter
            Dunkelheit – eine innovative Idee, die Hürden abbaut und
            oberflächliche Vorurteile aus dem Weg räumt.
          </p>
          <p>
            Sie sehen, professionelles Employer Branding wirkt. Lassen Sie uns
            als Ihre Employer Branding Agentur schauen, was wir für Sie tun
            können.
          </p>
          <br />

          <h2>
            Diese Leistungen erwarten Sie bei Ihrer Agentur für Employer
            Branding
          </h2>
          <br />
          <p>
            Vertrauen Sie auf Know-how – so wie bei Ihren neuen Fachkräften. Bei
            uns als professionelle Employer Branding Agentur erhalten Sie einen
            umfassenden Full-Service für Ihr Personal Recruiting. Dabei starten
            wir zusammen mit einem umfangreichen Beratungsgespräche, bei dem es
            um Ihre Ansprüche, Vorstellungen und Wünsche geht. Basierend auf den
            ersten gesteckten Zielen führen wir als Employer Branding Agentur
            fundierte Analysen Ihrer Stellenangebote, Ihrer aktuellen
            Arbeitgebermarkenpräsenz und Ihrer Zielgruppe durch. Alle Ergebnisse
            fließen anschließend in eine Employer Branding Strategie ein und wir
            konzeptionieren einen digitalen Personal-Recruiting-Prozess. Diesen
            implementieren wir, sofern Sie mit allem zufrieden sind, in Ihren
            Strukturen und stellen sicher, dass alles einwandfrei läuft. Zudem
            übernehmen wir als Ihre Employer Branding Agentur die Schulung Ihrer
            Mitarbeiter. Danach gehen auch schon direkt die ersten Anzeigen in
            den sozialen Medien live.
          </p>
          <p>
            Unsere Employer Branding Agentur bietet Ihnen ein Tool mit
            interessanten Benefits, die den Bewerberflow noch effizienter
            gestalten:
          </p>
          <li>Vorselektion anhand individueller Kriterien</li>
          <li>
            Bewerbermanagement im Self-Service über Ihren persönlichen Zugang
          </li>
          <li>sicheres Onboarding</li>
          <p>
            Gehen Sie mit uns als Ihre Employer Branding Agentur und starken{' '}
            <NavLink to="/referenzen">
              <b>
                <u>Referenzen</u>
              </b>
            </NavLink>{' '}
            in eine stark aufgestellte Zukunft. Denn bedenken Sie immer, dass
            die richtigen Mitarbeiter einer der wichtigsten Schlüssel für Ihren
            Erfolg sind.
          </p>
          <br />
          <h2>
            Bereit für die Magie Ihrer Marke? Wir sind Ihre Employer Branding
            Agentur
          </h2>
          <br />
          <p>
            Lassen Sie Ihre Marke für sich sprechen und präsentieren sich Ihren
            potenziellen Talenten von Ihrer authentischen Seite. Sprechen Sie
            Ihre Wunschkandidaten mit uns als Ihre Employer Branding Agentur zur
            richtigen Zeit am richtigen Ort mit den richtigen Worten an.
            Implementieren Sie ein Personal-Recruiting-Prozess, der Ihnen
            langfristig zur Seite steht und Ihnen genau dann neue Mitarbeiter
            generiert, wann Sie es benötigen.
          </p>
          <p>
            Nehmen Sie jetzt unverbindlich{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            zu unser Employer Branding Agentur auf. Das Team der Peakconcepts
            Employer Branding Agentur freut sich darauf, Sie kennenzulernen und
            Sie auf dem Weg zu einem erfolgreichen Team zu begleiten.
          </p>
        </LPContent>
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default EmployerBrandingagentur
