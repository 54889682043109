import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/webdesign/goeppingen.json'
import Content from '@content/leistungen/design.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const KontaktShort = React.lazy(
  () => import('@components/sections/KontaktShort')
)
const Liste = React.lazy(() => import('@components/sections/Liste'))
const Quote = React.lazy(() => import('@components/sections/Quote'))
const Technologien = React.lazy(
  () => import('@components/sections/Technologien')
)
const Keyfacts = React.lazy(() => import('@components/sections/Keyfacts'))
const Referenzen = React.lazy(() => import('@components/sections/Referenzen'))

const LPWebDesignGoeppingen = () => {
  return (
    <>
      <SEOHeader id="lp-webdesign-goeppingen" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Diese Leistung im Bereich Webdesign erwarten Sie in Göppingen</h2>
          <br />
          <p>
            Als ganzheitlich denkende Webdesign Agentur bieten wir Ihnen
            umfassende{' '}
            <NavLink to="/leistungen">
              <b>
                <u>Leistungen</u>
              </b>
            </NavLink>{' '}
            aus einer kompetenten Hand:
          </p>
          <li>
            Professionelles Webdesign, das Ihre Marken-DNA ins Rampenlicht
            rückt.
          </li>
          <li>Smarte Webentwicklung, die eine starke Performance sichert.</li>
          <li>
            Innovative Responsive Webseiten, die auf jedem Endgerät
            funktionieren.
          </li>
          <li>
            Erstklassiger Service und Support, damit Sie sich keine Gedanken um
            Hosting, Pflege und Optimierung machen müssen.
          </li>
          <p>
            Unser <b>Rundum-sorglos-Paket</b> beim Webdesign in Göppingen
            sichert Ihren Erfolg, während Sie sich auf Ihre Kernarbeit
            konzentrieren.
          </p>
          <br />
          <h2>
            Durchdachtes Konzept beim Webdesign in Göppingen – für messbare
            Erfolge
          </h2>
          <br />
          <p>
            In einem Auftaktgespräch stecken wir Ihre Wünsche und Vorstellung
            ab, die zusammen mit unserem Know-how das Fundament des Webdesigns
            und der Webentwicklung in Göppingen legen. Darauf aufbauend legen
            wir gemeinsam Ziele fest, schauen uns Ihre Zielgruppe an und
            analysieren den Markt. Haben wir alle Informationen zusammen, geht
            es in die Konzeptphase, setzen Meilensteinen, fertigen einen
            Zeitplan an und schaffen die erste Struktur des Webdesigns in
            Göppingen. Direkt im Anschluss kreieren unsere Profis ein Wireframe
            (grobe Skizzierung der späteren Webseite), damit Sie einen ersten,
            optischen Eindruck gewinnen. Sind Sie mit allem zufrieden, mündet
            der Prozess in der Umsetzung Ihres Webdesigns. Mit einem innovativen
            Prototyping Tool bleiben Sie über den gesamten Prozess hinweg auf
            dem Laufenden. Ist am Ende das perfekte Webdesign entstanden, sorgen
            wir auf Wunsch für die Programmierung und sagen nach einer
            ausführlichen Testphase „Go live“.
          </p>
          <br />
          <h2>
            Webdesign, Webentwicklung und App Entwicklung Göppingen – was können
            wir für Sie tun?
          </h2>
          <br />
          <p>
            Ehrlich, kompetent und zuverlässig – Werte, die uns bei Ihrem
            Webdesign in Göppingen wichtig sind. Viele Kunden schätzen unsere
            bodenständigen Leistungen, bei denen wir in Sachen Qualität keine
            Kompromisse machen. Gerne bieten wir Ihnen weitere Serviceleistungen
            wie die App Entwicklung in Göppingen an. Bei uns geht alles Hand in
            Hand.
          </p>
          <br />
          <p>
            Überzeugen Sie sich einfach selbst und nehmen Sie gerne{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            zu uns auf. Zusammen meistern wir die Herausforderungen der
            digitalen Welt und begleiten Sie mit einem überzeugenden Webdesign
            in eine erfolgreiche Zukunft, die gestern bereits begonnen hat.
          </p>
        </LPContent>
        <Referenzen category="web" />
        <Kundenmeinungen category="web" />
        <KontaktShort item={Content.contactShort} />
        <Keyfacts item={Content.keyfacts} />
        <Technologien technologie="web" />
        <Liste
          title={LandingpageContent.why.title}
          dark={false}
          dots={false}
          items={LandingpageContent.why.items}
        />
        <Quote title={Content.quote.title} autor={Content.quote.autor} />
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default LPWebDesignGoeppingen
