import SEOHeader from '@components/basics/SEOHeader'
import SimpleHeader from '@components/basics/SimpleHeader'
import Section from '@components/elements/Section'
import Title from '@components/elements/Title'
import Quote from '@components/sections/Quote'
import Content from '@content/jobs.json'
import parse from 'html-react-parser'
import React, { useState } from 'react'
import styled from 'styled-components'

interface ItemProps {
  border?: boolean
}
interface JobProps {
  open: boolean
}

const Item = styled.div<ItemProps>`
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: ${(props) => (props.border ? '20px' : 'none')};
  border: ${(props) =>
    props.border ? '1px solid ' + props.theme.gray : 'none'};
  h3 {
    line-height: 1;
    font-weight: 600;
    margin: 0;
  }
  p {
    margin: 0;
  }
  i {
    font-size: 1.75rem;
    line-height: 1;
    margin: 5px 10px 0 0;
  }
  @media (max-width: 768px) {
    h3 {
      font-size: 1.25rem;
    }
    i {
      font-size: 1.25rem;
      margin: 0 10px 0 0;
    }
  }
`

const PItem = styled.p`
  text-align: center;
  margin: 40px 0 80px;
`

const JobItem = styled.div<JobProps>`
  background: #fff;
  padding: 30px;
  padding-top: ${({ open }) => (open ? '30px' : '0px')};
  padding-bottom: ${({ open }) => (open ? '30px' : '0px')};
  height: ${({ open }) => (open ? 'auto' : '0px')};
  clip-path: ${({ open }) =>
    open
      ? 'polygon(0% 0%, 100% 0, 100% 100%, 0 100%);'
      : 'polygon(0% 0%, 100% 0, 100% 0, 0 0);'};
  box-sizing: border-box;
  overflow: hidden;
  margin: 0 0 20px;
  -webkit-transition: clip-path 500ms ease-in-out;
  -moz-transition: clip-path 500ms ease-in-out;
  -ms-transition: clip-path 500ms ease-in-out;
  -o-transition: clip-path 500ms ease-in-out;
  transition: clip-path 500ms ease-in-out;
  .item {
    margin: 0 0 30px;
    h5 {
      text-transform: uppercase;
    }
    ul {
      list-style-type: square;
      margin: 0 0 0 20px;
      li {
        margin: 0;
      }
    }
  }
`

const Collapsible = styled.div<JobProps>`
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  border: ${(props) => (props.open ? 'none' : '1px solid ' + props.theme.gray)};
  padding: 30px;
  margin: ${(props) => (props.open ? '0' : '0 0 20px;')};
  background: ${(props) => (props.open ? '#FFF' : 'transparent')};
  cursor: pointer;
  -webkit-transition: background 300ms ease-in-out;
  -moz-transition: background 300ms ease-in-out;
  -ms-transition: background 300ms ease-in-out;
  -o-transition: background 300ms ease-in-out;
  transition: background 300ms ease-in-out;
  h3 {
    color: ${(props) => (props.open ? '#000' : props.theme.primary)};
    text-transform: uppercase;
    line-height: 1;
  }
  i {
    margin: 0;
    font-size: 36px;
    line-height: 1;
    color: ${(props) => (props.open ? '#000' : '#fff')};
  }
  @media (max-width: 768px) {
    margin: ${(props) => (props.open ? '0' : '0 0 5px;')};
    h3 {
      font-size: 1.25rem;
    }
  }
`

const Job = ({ item, open }: { item: any; open: boolean }) => {
  return (
    <JobItem className="no-user-select" open={open}>
      <p>{item.text}</p>
      <br />
      {item?.tasks?.length > 0 && (
        <div className="item">
          <h5>Deine Aufgaben:</h5>
          <ul>
            {item.tasks.map((item: any, i: number) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </div>
      )}
      {item?.profile?.length > 0 && (
        <div className="item">
          <h5>Das bist du:</h5>
          <ul>
            {item.profile.map((item: any, i: number) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </div>
      )}
      {item?.skills?.length > 0 && (
        <div className="item">
          <h5>Das solltest du mitbringen:</h5>
          <ul>
            {item.skills.map((item: any, i: number) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </div>
      )}
      <p>{parse(Content.cta.text, { trim: true })}</p>
    </JobItem>
  )
}

const Jobs = () => {
  const [openJob, setOpenJob] = useState<number>()
  return (
    <>
      <SEOHeader id="jobs" />
      <SimpleHeader title="Jobs" />
      <Section dark={false}>
        <div className="container">
          <Title margin={false} title={Content.whatYouGet.title} dark={false} />
          <div className="columns is-centered">
            <div className="column is-9-desktop is-10-tablet is-12-mobile">
              <PItem>{Content.whatYouGet.text}</PItem>
              <div className="columns is-multiline">
                {Content.whatYouGet.items.map((item, i) => (
                  <div key={i} className="column is-6-desktop is-12-tablet">
                    <Item>
                      <i className="material-icons">{item.icon}</i>
                      <div>
                        <h3>{item.title}</h3>
                        <p>{item.text}</p>
                      </div>
                    </Item>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <br />
        </div>
      </Section>
      <Section>
        <div className="container">
          <Title title="Offene <span>Jobs</span>" />
          {Content.jobs.map((item, i) => (
            <div key={i}>
              <Collapsible
                open={openJob === i}
                onClick={() => setOpenJob((oj) => (oj === i ? -1 : i))}>
                <h3 className="no-user-select">{item.title}</h3>
                <i className="material-icons">
                  {openJob === i
                    ? 'keyboard_arrow_down'
                    : 'keyboard_arrow_right'}
                </i>
              </Collapsible>
              <Job item={item} open={openJob === i} />
            </div>
          ))}
        </div>
      </Section>
      <Quote
        dark={false}
        title={Content.quote.title}
        autor={Content.quote.autor}
      />
    </>
  )
}

export default Jobs
