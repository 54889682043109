import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import { Item, LabelContainer } from '@components/sections/LPContent'
import LandingpageContent from '@content/landingpages/agentur/leadgenerierung-agentur.json'
import Content from '@content/leistungen/leadgenerierung-agentur.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)

const LeadgenerierungAgentur = () => {
  return (
    <>
      <SEOHeader id="lp-agentur-leadgenerierung" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Die Vorteile unserer Agentur für Leadgenerierung</h2>
          <br />

          <p>
            Dass die <b>Generierung von Leads</b> das A und O für den Erfolg
            eines Unternehmens ist, steht außer Frage. Doch fehlt es vielen an
            nötigen Kapazitäten, denn eine wirkungsvolle Leadgenerierung bedarf
            einer Strategie, eines Prozesses und einer Nachbearbeitung. Wer bei
            der Neukundengewinnung auf unsere Leadgenerierung Agentur{' '}
            <NavLink to="/">
              <b>
                <u>Peakconcepts</u>
              </b>
            </NavLink>{' '}
            setzt, genießt hingegen zahlreiche <b>Vorteile</b>:
          </p>

          <Item>
            <LabelContainer>
              <label>01</label>
              <p className="headline">Individuelles Leistungsangebot</p>
            </LabelContainer>
            <p>
              Sie profitieren von unserem Konzept eines{' '}
              <b>individuellen Leistungsangebots</b> rund um die Leadgenerierung
              für Ihr Unternehmen. Dabei greifen wir auf unsere eigenen,
              effektiven Ressourcen, wie jahrelange Erfahrung, breitgefächerte
              Expertise sowie ein ausgedehntes Netzwerk mit wertvollen Kontakten
              zurück.
            </p>
          </Item>

          <Item>
            <LabelContainer>
              <label>02</label>
              <p className="headline">DSGVO konform</p>
            </LabelContainer>
            <p>
              Sie etablieren mit unserer Leadgenerierung Agentur Maßnahmen,
              welche <b>DSGVO konform</b> sind und somit Ihrem User die
              Sicherheit eines geschützten Umgangs mit dessen Daten bieten.
            </p>
          </Item>

          <Item>
            <LabelContainer>
              <label>03</label>
              <p className="headline">Strategische Ausrichtung</p>
            </LabelContainer>
            <p>
              Dank <b>strategischer Ausrichtung</b> nutzen Sie die von unserer
              Leadgenerierung Agentur gestalteten, relevanten Inhalte auch
              Langfristig für die effiziente Neukundengewinnung Ihres
              Unternehmens.
            </p>
          </Item>

          <Item>
            <LabelContainer>
              <label>04</label>
              <p className="headline">Zuverlässiger Partner</p>
            </LabelContainer>
            <p>
              Sie konzentrieren sich auf Ihr <b>Kerngeschäft</b>, während wir
              als Ihr Partner für Sie professionell neue Leads generieren.
            </p>
          </Item>

          <Item>
            <LabelContainer>
              <label>05</label>
              <p className="headline">Full Service Agentur</p>
            </LabelContainer>
            <p>
              Als{' '}
              <NavLink to="/agentur">
                <b>
                  <u>Full Service Agentur</u>
                </b>
              </NavLink>{' '}
              bieten wir Ihnen ein <b>breites Leistungsspektrum</b>, sodass Sie
              mit uns auf einen Partner setzen, der Ihnen die gesamte Bandbreite
              für eine erfolgreiche Unternehmensdigitalisierung bietet.
            </p>
          </Item>

          <br />
          <p>
            <b>Besonders wichtig zu wissen</b>: Dank einer hohen Erfolgsquote
            der Neukundengewinnung sowie -bindung über unsere Agentur
            Leadgenerierung amortisieren sich Ihre Kosten besonders schnell!
            Überzeugen Sie sich gerne persönlich von der Qualität und dem Erfolg
            unserer Arbeit oder werfen Sie ein Blick auf zahlreiche zufriedene
            Kunden aus unseren Projekten in unseren{' '}
            <NavLink to="/referenzen">
              <b>
                <u>Referenzen</u>
              </b>
            </NavLink>
            .
          </p>
          <br />
          <h2>
            Neukunden smarter gewinnen – mit der Strategie von Peakconcepts als
            Ihre professionelle Leadgenerierung Agentur
          </h2>
          <br />
          <p>
            Im Rahmen der Leadgenerierung unserer Agentur arbeiten wir mit Ihnen
            gemeinsam in folgenden Schritten:
          </p>

          <Item>
            <LabelContainer>
              <label>01</label>
              <p className="headline">Erstgespräch</p>
            </LabelContainer>
            <p>
              Wir starten mit einem <b>Erstgespräch</b> und stecken dabei Ihre
              Vorstellungen, Ziele und Wünsche bei der Neukundengewinnung ab.
            </p>
          </Item>

          <Item>
            <LabelContainer>
              <label>02</label>
              <p className="headline">Status-Quo</p>
            </LabelContainer>
            <p>
              Anschließend machen wir uns ein umfassendes Bild Ihres{' '}
              <b>Status-Quo</b>, der die Grundlage für alle weiteren Maßnahmen
              bildet.
            </p>
          </Item>

          <Item>
            <LabelContainer>
              <label>03</label>
              <p className="headline">Zielgruppe-Analyse</p>
            </LabelContainer>
            <p>
              Ohne Zielgruppe, keine hochwertigen Leads! Im nächsten Schritt{' '}
              <b>definieren</b> wir daher gemeinsam Ihre <b>Kunden</b>, damit
              wir später die richtigen Menschen zum richtigen Zeitpunkt im
              richtigen Umfeld mit den richtigen Maßnahmen erreichen.
            </p>
          </Item>

          <Item>
            <LabelContainer>
              <label>04</label>
              <p className="headline">Konzeption</p>
            </LabelContainer>
            <p>
              Basierend auf der <b>umfassenden Konzeption</b> starten wir als
              Ihre zuverlässige Agentur für Leadgenerierung in die{' '}
              <b>Umsetzung</b>, erstellen beispielsweise Landingpage, schalten
              Anzeigen oder setzen einen Newsletter auf.
            </p>
          </Item>

          <Item>
            <LabelContainer>
              <label>05</label>
              <p className="headline">Controlling</p>
            </LabelContainer>
            <p>
              Damit wir Ihre Neukundengewinnung stetig verbessern, beschäftigen
              wir uns anschließend mit einem <b>umfangreichen Controlling</b>{' '}
              und verfeinern die Maßnahmen entsprechend den Ergebnissen.
            </p>
          </Item>
          <br />
          <p>
            Lassen Sie uns zusammen das <b>Fundament Ihres Erfolges</b> legen
            und Ihnen mit unserer Expertise als Leadgenerierung Agentur einen
            Wettbewerbssprung verschaffen. Die Formel ist ganz einfach:
            hochwertiges Leadvolumen + nachhaltige Konvertierungsstrategie =
            zufriedene Neukunden und damit mehr Umsatz für Ihr Unternehmen. Wir
            freuen uns, Sie dabei unterstützen zu dürfen. Nehmen Sie jetzt
            unverbindlich{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            zu uns auf.
          </p>
        </LPContent>
        <Kundenmeinungen category="app" />

        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default LeadgenerierungAgentur
