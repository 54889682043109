import axios from '@services/api'

export const sendContactForm = async ({
  name,
  company,
  phone,
  email,
  message
}: {
  name: string
  company: string
  phone: string
  email: string
  message: string
}): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    axios
      .post(process.env.REACT_APP_CLOUDFUNCTION_URL as string, {
        name,
        company,
        phone,
        email,
        message,
        apiKey: process.env.REACT_APP_CLOUDFUNCTION_API_KEY
      })
      .then(function (response: any) {
        // handle success
        console.log(response)
        return resolve(true)
      })
      .catch(function (error: any) {
        // handle error
        console.log(error)
        return reject()
      })
  })
}
