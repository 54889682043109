import Section from '@components/elements/Section'
import Title from '@components/elements/Title'
import React from 'react'
import styled from 'styled-components'

interface ItemsProps {
  title: string
  text: string
}

interface ItemProps {
  dark?: boolean
  dots?: boolean
}

const ItemContainer = styled.div`
  @media (min-width: 992px) {
    padding: 0 30px;
  }
`

const Item = styled.div<ItemProps>`
  position: relative;
  padding: 0 0 60px;
  padding-left: ${(props) =>
    props.dots ? '80px' : 0}; /* Distance between Title and Dots */
  label {
    color: ${({ theme }) => theme.primary};
    font-size: 4rem;
    font-weight: 900;
    &.dots {
      ::before {
        position: absolute;
        z-index: 10;
        left: 0px;
        top: 19px;
        content: '';
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.primary};
      }
      ::after {
        position: absolute;
        left: 16px;
        top: 19px;
        content: '';
        width: 4px; /* Width of Border */
        height: 100%;
        /* CSS Trick for getting bigger dashes :-D */
        background-image: ${({ theme }) =>
          /* Change the 40% to adjust the spacing of the items */
          `linear-gradient(to bottom, ${theme.primary} 40%, rgba(255, 255, 255, 0) 0%)`};
        background-position: left;
        background-size: 4px 10px; /* Width of Border and The Amount. Increase to get less dashed, decrease for more */
        background-repeat: repeat-y;
      }
    }
  }
  :last-child {
    label {
      &.dots {
        ::after {
          /* Remove the Bottom Line on the Last Item */
          height: 0px;
        }
      }
    }
  }
  p.headline {
    color: ${({ dark }) => (dark ? '#FFF' : '#000')};
    font-size: 1.5rem;
    line-height: 1;
    font-weight: bold;
    margin: 0 0 10px;
  }
  p {
    color: ${({ dark }) => (dark ? '#FFF' : '#000')};
  }
  @media (max-width: 991px) {
    padding: 0 0 30px;
    padding-left: ${(props) => (props.dots ? '60px' : 0)};
  }
`
const LabelContainer = styled.div`
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: end;
  -moz-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  & > label {
    margin: 0;
    line-height: 4.5rem;
    margin-right: 10px;
  }
`
const Liste = ({
  dark = true,
  dots = true,
  title,
  items = []
}: {
  dark?: boolean
  dots?: boolean
  title: string
  items: ItemsProps[]
}) => {
  return (
    <Section dark={dark}>
      <div className="container">
        <Title title={title} dark={dark} titleSize={0} />
        <ItemContainer>
          {items.map((item, i) => (
            <Item dark={dark} dots={dots} key={i}>
              <LabelContainer>
                <label className={dots ? 'dots' : ''}>{`${i <= 10 && '0'}${
                  i + 1
                }`}</label>
                <p className="headline">{item.title}</p>
              </LabelContainer>
              <p>{item.text}</p>
            </Item>
          ))}
        </ItemContainer>
      </div>
    </Section>
  )
}

Liste.defaultProps = {
  dark: true,
  dots: true
}

export default Liste
