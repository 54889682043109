import SEOHeader from '@components/basics/SEOHeader'
import TagManager from 'react-gtm-module'
// Sections
import Section from '@components/elements/Section'
import Title from '@components/elements/Title'
import HeroHeader from '@components/sections/HeroHeader'
import Kontakt from '@components/sections/Kontakt'
import Liste from '@components/sections/Liste'
import Content from '@content/godigital.json'
import parse from 'html-react-parser'
import React, { useEffect } from 'react'

const GoDigital = () => {
  useEffect(() => {
    TagManager.dataLayer({ dataLayer: { event: 'goDigital' } })
  }, [])
  return (
    <>
      <SEOHeader id="goDigital" />
      <HeroHeader item={Content.header} />
      <Section dark={false} bottomPadding={false}>
        <div className="container">
          <Title title={Content.description.title} dark={false} titleSize={1} />
          <p>{Content.description.text}</p>
          <ul className="list">
            {Content.description.items.map((item, i) => (
              <li key={i}>{parse(item, { trim: true })}</li>
            ))}
          </ul>
          <p>
            <strong>{Content.description.info}</strong>
          </p>
        </div>
      </Section>
      <Section dark={false} bottomPadding={false}>
        <div className="container">
          <Title title={Content.eligible.title} dark={false} />
          <p>{parse(Content.eligible.subline, { trim: true })}</p>
          <ul className="list">
            {Content.eligible.items.map((item, i) => (
              <li key={i}>{parse(item, { trim: true })}</li>
            ))}
          </ul>
          <p>{parse(Content.eligible.text, { trim: true })}</p>
          <ul className="list">
            {Content.eligible.examples.map((item, i) => (
              <li key={i}>{parse(item, { trim: true })}</li>
            ))}
          </ul>
          <p>{parse(Content.eligible.info, { trim: true })}</p>
        </div>
      </Section>
      <Section dark={false}>
        <div className="container">
          <Title title={Content.data.title} dark={false} />
          <p>{Content.data.text}</p>
          <ul className="list">
            {Content.data.items.map((item, i) => (
              <li key={i}>{parse(item, { trim: true })}</li>
            ))}
          </ul>
        </div>
      </Section>
      <Liste title={Content.ablauf.title} items={Content.ablauf.items} />
      <Kontakt title={Content.contact.title} />
    </>
  )
}

export default GoDigital
