import parse from 'html-react-parser'
import React from 'react'
import styled from 'styled-components'

interface TitleProps {
  as: any
  dark?: boolean
  margin?: boolean
  fontSize?: string
}

const TitleElement = styled.div<TitleProps>`
  color: ${(props) => (props.dark ? '#FFF' : '#000')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '2.5rem')};
  font-weight: bold;
  line-height: 1.25;
  margin-bottom: ${(props) => (props.margin ? props.theme.spacing : 0)};
  text-align: center;

  span {
    color: ${(props) => (props.dark ? '#FFF' : '#000')};
    font-size: ${(props) => (props.fontSize ? props.fontSize : '2.5rem')};
    font-weight: bold;
    line-height: 1.25;
    border-bottom: 4px solid ${(props) => props.theme.primary};
    padding-bottom: 0px;
    margin: 0;
  }
  @media (max-width: 1023px) {
    margin-bottom: ${(props) => (props.margin ? props.theme.spacingMobile : 0)};
  }
`

const Title = ({
  dark = true,
  margin = true,
  title,
  titleSize = 2,
  fontSize
}: {
  dark?: boolean
  margin?: boolean
  title: string
  titleSize?: number
  fontSize?: string
}) => {
  return (
    // @ts-ignore
    <TitleElement
      as={titleSize > 0 ? `h${titleSize}` : 'p'}
      dark={dark}
      margin={margin}
      fontSize={fontSize}>
      {parse(title, { trim: true })}
    </TitleElement>
  )
}

export default Title
