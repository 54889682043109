import Section from '@components/elements/Section'
import Title from '@components/elements/Title'

export const Alchimedus = ({ dark = false }: { dark?: boolean }) => {
  return (
    <Section dark={dark}>
      <div className="container">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 80
          }}>
          <Title
            title="ALCHIMEDUS®, DER WEGWEISER ZU WAHREN HÖCHSTLEISTUNGEN!"
            dark={dark}
            titleSize={0}
            margin={false}
            fontSize="2.3rem"
          />
          <span style={{ textAlign: 'center' }}>
            <i>
              <b>
                Systemische Organisations­entwicklung und agile Transformation
              </b>
            </i>
          </span>
        </div>
        <div className="columns">
          <div className="column is-10 is-offset-1 is-offset-0-mobil">
            <div className="columns is-multiline">
              <div className="column is-half">
                <h5>Ihr Nutzen: Ertrag, Engagement, Erfolg.</h5>
                <p>
                  Gemeinsam erarbeiten wir in einer Potenzialanalyse Ihres
                  Unternehmens pragmatische, innovative und handlungsorientierte
                  Herangehensweisen, durch die Ihre Mitarbeiter in die
                  Bestleisterprozesse integriert werden.
                </p>
              </div>
              <div
                className="column is-half"
                style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  width="300px"
                  src="/images/alchimedus/Value-4.0.jpg"
                  alt="ALCHIMEDUS Strategy"
                />
              </div>
              <div className="column is-half">
                <h3>Das Ergebnis</h3>
                <p>
                  Die richtige Arbeit mit den richtigen Mitteln durch den
                  richtigen Mitarbeiter!
                </p>
                <p>
                  Spürbare Umsetzungsideen und Zukunftsprojekte für Ihren
                  sichtbaren Erfolg!
                </p>
                <p>
                  Mit der integrativen Alchimedus-Methode beziehen Sie Visionen,
                  Ziele und das Können Ihrer Mitarbeiter in den
                  unternehmerischen Optimierungsprozess ein und fördern so
                  Engagement und Motivation.
                </p>
                <p>
                  Mehr Infos:{' '}
                  <a href="www.alchimedus-methode.de" target="_blank">
                    www.alchimedus-methode.de
                  </a>
                </p>
              </div>
              <div className="column is-half">
                <h3>Ihre Vorteile auf einen Blick</h3>
                <li>Minimaler Beratungsaufwand, maximale Effizienz</li>
                <li>Kostensenkung bei höherer Kundenzufriedenheit</li>
                <li>
                  Entdeckung und Nutzung der Potenziale Ihres Unternehmens und
                  Ihrer Mitarbeiter
                </li>
                <li>Mindestens drei Zukunftsprojekte für Ihren Erfolg</li>
                <li>
                  Wissenschaftlich fundiertes Metasystem aus über 180
                  Managementmodellen.
                </li>
                <p>
                  <b>PRAGMATISCH. SCHNELL. ZUKUNFTSORIENTIERT.</b>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <img
            src="/images/alchimedus/alchimedus.jpg"
            alt="ALCHIMEDUS Master"
            style={{ height: 300 }}
          />
        </div>
      </div>
    </Section>
  )
}
