/* Hook für die Rückgabe der Window-Dimensions */

import { useState, useEffect } from 'react'

function getScrollPosition() {
  const { pageYOffset } = window
  return {
    pageYOffset
  }
}

export default function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = useState(getScrollPosition())

  useEffect(() => {
    function handleScroll() {
      setScrollPosition(getScrollPosition())
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return scrollPosition
}
