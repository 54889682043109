import React, { useEffect, useState } from 'react'

import '@css/style.scss'
import '@css/index.css'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import DynamicScrollToTop from '@components/basics/DynamicScrollToTop'
// Elements
import Header from '@components/basics/Header'
import Footer from '@components/basics/Footer'

// Pages
import Home from '@pages/Home'

import Agentur from '@pages/Agentur'
import Jobs from '@pages/Jobs'
import Referenzen from '@pages/Referenzen'
import Kontakt from '@pages/Kontakt'
import GoDigital from '@pages/GoDigital'
import Impressum from '@pages/Impressum'
import Datenschutz from '@pages/Datenschutz'
import AGB from '@pages/AGB'

// Leistungen
import Leistungen from '@pages/Leistungen'
import Beratung from '@pages/Leistungen/Beratung'
import Design from '@pages/Leistungen/Design'
import AppEntwicklung from '@pages/Leistungen/AppEntwicklung'
import WebEntwicklung from '@pages/Leistungen/WebEntwicklung'
import OnlineMarketing from '@pages/Leistungen/OnlineMarketing'

// Landingpages
import LPWebentwicklungStuttgart from '@pages/Landingpages/Webentwicklung/Stuttgart'
import LPWebdesignAalen from '@pages/Landingpages/Webdesign/Aalen'
import LPWebdesignSchwaebischGmuend from '@pages/Landingpages/Webdesign/SchwaebischGmuend'
import LPWebdesignLudwigsburg from '@pages/Landingpages/Webdesign/Ludwigsburg'
import LPWebdesignNoerdlingen from '@pages/Landingpages/Webdesign/Noerdlingen'
import LPAppentwicklungStuttgart from '@pages/Landingpages/Appentwicklung/Stuttgart'

// Agentur
import Webentwicklungsagentur from '@pages/Landingpages/Agentur/Webentwicklungsagentur'
import Appentwicklungsagentur from '@pages/Landingpages/Agentur/Appentwicklungsagentur'
import Digitalisierungsagentur from '@pages/Landingpages/Agentur/Digitalisierungsagentur'
import Digitalagentur from '@pages/Landingpages/Agentur/Digitalagentur'
import LeadgenerierungAgentur from '@pages/Landingpages/Agentur/LeadgenerierungAgentur'
import Leadgenerierung from '@pages/Landingpages/Agentur/Leadgenerierung'
import EmployerBrandingAgentur from '@pages/Landingpages/Agentur/EmployerBrandingagentur'
import SocialRecruitingAgentur from '@pages/Landingpages/Agentur/SocialRecruitingagentur'
import LinkedinRecruiting from '@pages/Landingpages/Agentur/LinkedinRecruiting'
import PersonalMarketingAgentur from '@pages/Landingpages/Agentur/PersonalMarketingAgentur'
import RecruitingAgentur from '@pages/Landingpages/Agentur/Recruitingagentur'
import Mitarbeitergewinnung from '@pages/Landingpages/Agentur/Mitarbeitergewinnung'
import MitarbeitergewinnungPflege from '@pages/Landingpages/Agentur/MitarbeitergewinnungPflege'
import MitarbeitergewinnungHandwerk from '@pages/Landingpages/Agentur/MitarbeitergewinnungHandwerk'

// Webdesign
import LPWebDesignUlm from './Landingpages/Webdesign/Ulm'
import LPWebDesignGoeppingen from './Landingpages/Webdesign/Goeppingen'
import LPWebdesignReutlingen from './Landingpages/Webdesign/Reutlingen'
import LPWebdesignWuerzburg from './Landingpages/Webdesign/Wuerzburg'
import LPWebdesignAugsburg from './Landingpages/Webdesign/Augsburg'
import LPWebdesignHeilbronn from './Landingpages/Webdesign/Heilbronn'
import LPWebdesignIngolstadt from './Landingpages/Webdesign/Ingolstadt'
import LPWebdesignAnsbach from './Landingpages/Webdesign/Ansbach'
import LPWebdesignTuebingen from './Landingpages/Webdesign/Tuebingen'
import LPWebdesignSchwaebischHall from './Landingpages/Webdesign/SchwaebischHall'
import LPWebdesignGuenzburg from './Landingpages/Webdesign/Guenzburg'
import LPWebdesignDonauwoerth from './Landingpages/Webdesign/Donauwoerth'
import MitarbeiterFinden from './Landingpages/Agentur/MitarbeiterFinden'
import MarketingAgenturAalen from './Landingpages/Agentur/MarketingAgenturAalen'

export const DarkContext = React.createContext({ get: null, set: null })

const InnerNavigation = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/leistungen" exact component={Leistungen} />
      <Route path="/leistungen/beratung" component={Beratung} />
      <Route path="/leistungen/design" component={Design} />
      <Route path="/leistungen/app-entwicklung" component={AppEntwicklung} />
      <Route path="/leistungen/webentwicklung" component={WebEntwicklung} />
      <Route path="/leistungen/online-marketing" component={OnlineMarketing} />
      <Route path="/agentur" component={Agentur} />
      <Route path="/jobs" component={Jobs} />
      <Route path="/kontakt" component={Kontakt} />
      <Route path="/referenzen" exact component={Referenzen} />
      <Route path="/go-digital" component={GoDigital} />
      <Route path="/impressum" component={Impressum} />
      <Route path="/datenschutz" component={Datenschutz} />
      <Route path="/agb" component={AGB} />

      {/** Landingpages */}
      <Route
        path="/leistungen/webentwicklung-stuttgart"
        component={LPWebentwicklungStuttgart}
      />
      <Route
        path="/leistungen/webdesign-schwaebisch-gmuend"
        component={LPWebdesignSchwaebischGmuend}
      />
      <Route path="/leistungen/webdesign-aalen" component={LPWebdesignAalen} />
      <Route path="/leistungen/webdesign-ulm" component={LPWebDesignUlm} />
      <Route
        path="/leistungen/webdesign-goeppingen"
        component={LPWebDesignGoeppingen}
      />
      <Route
        path="/leistungen/webdesign-ludwigsburg"
        component={LPWebdesignLudwigsburg}
      />
      <Route
        path="/leistungen/webdesign-noerdlingen"
        component={LPWebdesignNoerdlingen}
      />
      <Route
        path="/leistungen/webdesign-reutlingen"
        component={LPWebdesignReutlingen}
      />
      <Route
        path="/leistungen/webdesign-wuerzburg"
        component={LPWebdesignWuerzburg}
      />
      <Route
        path="/leistungen/webdesign-augsburg"
        component={LPWebdesignAugsburg}
      />
      <Route
        path="/leistungen/webdesign-heilbronn"
        component={LPWebdesignHeilbronn}
      />
      <Route
        path="/leistungen/webdesign-ingolstadt"
        component={LPWebdesignIngolstadt}
      />
      <Route
        path="/leistungen/webdesign-ansbach"
        component={LPWebdesignAnsbach}
      />
      <Route
        path="/leistungen/webdesign-tuebingen"
        component={LPWebdesignTuebingen}
      />
      <Route
        path="/leistungen/webdesign-schwaebisch-hall"
        component={LPWebdesignSchwaebischHall}
      />
      <Route
        path="/leistungen/webdesign-guenzburg"
        component={LPWebdesignGuenzburg}
      />
      <Route
        path="/leistungen/webdesign-donauwoerth"
        component={LPWebdesignDonauwoerth}
      />
      <Route
        path="/leistungen/app-entwicklung-stuttgart"
        component={LPAppentwicklungStuttgart}
      />
      <Redirect from="/leistungen/ux-ui-design" to="/leistungen/design" />
      <Redirect from="/leistungen/it-beratung" to="/leistungen/beratung" />
      <Redirect from="/referenzen/*" to={'/referenzen'} />

      {/** Agentur */}
      <Route
        path="/webentwicklung-agentur"
        component={Webentwicklungsagentur}
      />
      <Route
        path="/app-entwicklung-agentur"
        component={Appentwicklungsagentur}
      />
      <Route
        path="/digitalisierungs-agentur"
        component={Digitalisierungsagentur}
      />
      <Route path="/digitalagentur" component={Digitalagentur} />
      <Route
        path="/leadgenerierung-agentur"
        component={LeadgenerierungAgentur}
      />
      <Route path="/leadgenerierung" component={Leadgenerierung} />
      <Route path="/mitarbeitergewinnung" component={Mitarbeitergewinnung} />
      <Route
        path="/mitarbeitergewinnung-pflege"
        component={MitarbeitergewinnungPflege}
      />
      <Route
        path="/mitarbeitergewinnung-handwerk"
        component={MitarbeitergewinnungHandwerk}
      />
      <Route
        path="/employer-branding-agentur"
        component={EmployerBrandingAgentur}
      />
      <Route
        path="/social-recruiting-agentur"
        component={SocialRecruitingAgentur}
      />
      <Route path="/linkedin-recruiting" component={LinkedinRecruiting} />
      <Route
        path="/personalmarketing-agentur"
        component={PersonalMarketingAgentur}
      />
      <Route
        path="/marketing-agentur-aalen"
        component={MarketingAgenturAalen}
      />
      <Route path="/recruiting-agentur" component={RecruitingAgentur} />
      <Route path="/mitarbeiter-finden" component={MitarbeiterFinden} />
      <Redirect from="*" to="/404.html" />
    </Switch>
  )
}
function App() {
  const [isDark, setIsDark] = useState(true)
  // const [cookiesEnabled, setCookiesEnabled] = useState(false)
  window.onbeforeunload = function () {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    const html = document.getElementById('html')
    html.style = `background: ${isDark ? '#000' : '#FFF'};`
  }, [isDark])
  return (
    <DarkContext.Provider value={{ get: isDark, set: setIsDark }}>
      <Router>
        <DynamicScrollToTop />
        <Header />
        <InnerNavigation />
        <Footer />
      </Router>
    </DarkContext.Provider>
  )
}

export default App
