/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import { Blurhash } from 'react-blurhash'
import styled from 'styled-components'
import blurhashes from '../../blurhashes.json'
interface ResponsiveImageInterface
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  customSizes?: number[]
  noLazy?: boolean
}
const RelativeContainer = styled.div`
  position: relative;
  width: fit-content;
  height: fit-content;
`
const AbsoluteBlurHashContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`
const ResponsiveImage = (props: ResponsiveImageInterface) => {
  const [loading, setLoading] = useState(true)

  const { src, customSizes, noLazy } = props

  const matchingBlurHash = blurhashes.find((b: any) => b.path === src)
  if (!src) return null
  const onLoad = () => {
    setLoading(false)
  }

  const fileEnding = src.split('.').pop()
  const srcWithoutExtension = (src as string).replace(`.${fileEnding}`, '')
  const sizesWithFallback = customSizes || [1200, 800, 380, 200, 1]
  if (process.env.NODE_ENV === 'production' && !src.endsWith('.svg'))
    return (
      <RelativeContainer>
        <picture>
          {/* Very big */}
          {sizesWithFallback.map((size, index) => {
            const suffix = index === 0 ? '' : `_${5 - index}x`
            return (
              <>
                <source
                  media={`(min-width: ${size}px)`}
                  srcSet={`${srcWithoutExtension}${suffix}.webp`}
                  type="image/webp"
                />
                <source
                  media={`(min-width: ${size}px)`}
                  srcSet={`${srcWithoutExtension}${suffix}.${fileEnding}`}
                />
              </>
            )
          })}

          <img
            {...props}
            onLoad={onLoad}
            src={src}
            loading={noLazy ? undefined : 'lazy'}
          />
        </picture>
        {!noLazy && loading && matchingBlurHash && (
          <AbsoluteBlurHashContainer>
            <Blurhash
              hash={matchingBlurHash.hash}
              resolutionX={64}
              resolutionY={64}
              width={'100%'}
              height={'100%'}
              punch={1}
            />
          </AbsoluteBlurHashContainer>
        )}
      </RelativeContainer>
    )
  return (
    <RelativeContainer>
      <img
        {...props}
        onLoad={onLoad}
        src={src}
        loading={noLazy ? undefined : 'lazy'}
      />
      {!noLazy && loading && matchingBlurHash && (
        <AbsoluteBlurHashContainer>
          <Blurhash
            hash={matchingBlurHash.hash}
            resolutionX={64}
            resolutionY={64}
            width={'100%'}
            height={'100%'}
            punch={1}
          />
        </AbsoluteBlurHashContainer>
      )}
    </RelativeContainer>
  )
}

export default ResponsiveImage
