import Section from '@components/elements/Section'
import Title from '@components/elements/Title'
import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { Element } from 'react-scroll'
import styled from 'styled-components'

const dark = false

const Container = styled.div`
  @media (max-width: 600px) {
    .content-box {
      margin-top: 60px;
    }
  }
`

const ItemContainerLarge = styled.div`
  label {
    color: ${(props) => props.theme.primary};
    font-size: 9rem;
    line-height: 1;
    font-weight: bold;
  }
`
const ItemContainerSmall = styled.div`
  display: -webkit-box; 
  display: -moz-box;       
  display: -ms-flexbox;      
  display: -webkit-flex;     
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;  
  -webkit-box-align: start;
  -moz-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  padding: ${({ theme }) => '0 ' + theme.horizontalSpacingMobile};
  label {
    color: ${(props) => props.theme.primary};
    font-size: 4rem;
    vertical-align: text-top;
    font-weight: bold;
    line-height: 4rem;
    margin: 0;
    width: 90px;
  }
  .textContainer {
    position: relative;
    display: -webkit-box; 
    display: -moz-box;       
    display: -ms-flexbox;      
    display: -webkit-flex;     
    display: flex;
    -webkit-box-flex: 1;      
    -moz-box-flex: 1;        
    -webkit-flex: 1;          
    -ms-flex: 1;              
    flex: 1;  
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;  
    padding: 5px 20px;
    ::after {
      position: absolute;
      left: 0px;
      top: 0px;
      content: '';
      width: 3px; /* Width of Border */
      height: 100%;
      /* CSS Trick for getting bigger dashes :-D */
      background-image: ${({ theme }) =>
        /* Change the 40% to adjust the spacing of the items */
        `linear-gradient(to bottom, ${theme.primary} 40%, rgba(255, 255, 255, 0) 0%)`};
      background-position: left;
      background-size: 4px 10px; /* Width of Border and The Amount. Increase to get less dashed, decrease for more */
      background-repeat: repeat-y;
    }
  }
  @media (max-width: 600px) { 
    padding: 0;
    label {
      font-size: 3rem;
      line-height: 3rem;
      width: 70px;
    }
  }
`

const Item = ({
  item,
  i
}: {
  item: { title: string; text: string }
  i: number
}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 768 })
  if (isSmallScreen)
    return (
      <ItemContainerSmall key={i}>
        <label>{`${i <= 10 && '0'}${i + 1}`}</label>
        <div className="textContainer">
          <h3>{item.title}</h3>
          <p>{item.text}</p>
        </div>
      </ItemContainerSmall>
    )
  return (
    <div className="column" key={i}>
      <ItemContainerLarge>
        <label>{`${i <= 10 && '0'}${i + 1}`}</label>
        <h3>{item.title}</h3>
        <p>{item.text}</p>
      </ItemContainerLarge>
    </div>
  )
}
const items = [
  {
    title: 'Anfrage stellen',
    text:
      'Schreiben Sie uns jetzt eine Nachricht und vereinbaren einen Termin für ein kostenloses Erstgespräch.'
  },
  {
    title: 'Erstgespräch',
    text:
      'Gemeinsam mit einem Experten analysieren wir Ihre aktuelle Situation und prüfen ob und wie wir Ihnen helfen können.'
  },
  {
    title: 'Projektstart',
    text:
      'Sie erhalten einen individuellen Plan abgestimmt auf Ihre aktuelle Situation für Ihr Projekt.'
  }
]

const Kontaktablauf = () => {
  return (
    <Element name="procedure">
      <Section dark={dark}>
        <Container className="container">
          <Title
            title="Der <span>Kontaktablauf</span>"
            dark={dark}
            titleSize={0}
          />
          <div className="column content-box">
            <div className="columns">
              {items.map((item, i) => (
                <Item item={item} key={i} i={i} />
              ))}
            </div>
          </div>
        </Container>
      </Section>
    </Element>
  )
}

export default Kontaktablauf
