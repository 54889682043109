const variables = {
  primary: '#FFC000',
  error: 'red',
  text: 'white',
  secondary: '#494949',
  background: '#7C7C7C',
  gray: '#434343',
  spacing: '80px',
  spacingMobile: '40px',
  horizontalSpacingMobile: '20px'
}
export default variables
