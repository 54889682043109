import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/webdesign/ingolstadt.json'
import Content from '@content/leistungen/design.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const KontaktShort = React.lazy(
  () => import('@components/sections/KontaktShort')
)
const Quote = React.lazy(() => import('@components/sections/Quote'))
const Technologien = React.lazy(
  () => import('@components/sections/Technologien')
)
const Keyfacts = React.lazy(() => import('@components/sections/Keyfacts'))
const Referenzen = React.lazy(() => import('@components/sections/Referenzen'))

const LPWebdesignIngolstadt = () => {
  return (
    <>
      <SEOHeader id="lp-webdesign-ingolstadt" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Ihre Vorzüge mit einem durchdachten Webdesign in Ingolstadt</h2>
          <br />
          <p>
            Weltweite Vernetzung, pausenlos online und Zugriff auf Produkte
            sowie Dienstleistungen, wo auch immer wir gerade sind – das heutige
            Zeitalter gibt uns nahezu grenzenlose Möglichkeiten, was
            gleichzeitig hohe Anforderungen an Sie und natürlich auch an Ihr
            Webdesign in Ingolstadt stellt. Täglich drängen neben bereits
            etablierten Marken neue Start-ups auf den Markt und die Konkurrenz
            scheint täglich zu wachsen. Wichtig ist hier, dass Sie sich von der
            Masse abheben, um nicht unterzugehen. Mit der laufenden
            Digitalisierung wird es zunehmend komplexer sich als Laie selbst um
            das Webdesign und die Webentwicklung in Ingolstadt zu kümmern.
            Machen Sie es sich daher einfach uns setzen Sie auf einen
            professionellen Partner wie uns von{' '}
            <NavLink to="/">
              <b>
                <u>Peakconcepts</u>
              </b>
            </NavLink>
            . Wir bringen neben jahrelanger Erfahrung eine umfassende Expertise
            mit und konnten so bereits zahlreiche renommierte Unternehmen aus
            unterschiedlichen Branchen mit einem Webdesign in Ingolstadt zu
            ihren Zielen verhelfen. Unsere Vorzüge zeigen sich ferner in der
            Projektbetreuung, der permanenten Weiterentwicklung und der starken
            Kundennähe. Was wir können? Ein Webdesign in Ingolstadt gestalten,
            dass Ihre Besucher zu einem unvergesslichen Erlebnis einlädt.
          </p>
          <p>
            Auf Basis Ihrer Vorstellungen und Wünsche sorgen wir bei einem
            Webdesign in Ingolstadt für die Planung, die Konzeption, die
            Strukturierung, die Umsetzung, die Implementierung und das
            Monitoring, um Optimierungen vornehmen zu können. Unser Ziel ist es,
            dass am Ende eine Webseite entsteht, die authentisch Ihre Marke
            repräsentiert und sich an den Ansprüchen Ihrer Zielgruppe
            orientiert.
          </p>
          <br />

          <h2>
            Das sind unsere umfassenden Leistungen bei einem Webdesign in
            Ingolstadt
          </h2>
          <br />
          <p>
            Sie erhalten bei einem Webdesign in Ingolstadt bei uns alle{' '}
            <NavLink to="/leistungen">
              <b>
                <u>Leistungen</u>
              </b>
            </NavLink>{' '}
            aus einer Hand. Dafür halten wir einen Rundum-sorglos-Service für
            Sie bereit:
          </p>
          <li>professionelles Webdesign für Ihre digitale Marken-DNA</li>
          <li>
            hochwertige Webentwicklung für eine leistungsstarke Performance
          </li>
          <li>
            smarte responsive Webseiten für Sichtbarkeit auf allen Endgeräten
          </li>
          <li>
            umfänglicher Service und Support mit garantiertem Hosting, Pflege
            und Optimierung
          </li>
          <p>
            Verlassen Sie sich bei Webdesign und Web- sowie{' '}
            <NavLink to="/leistungen/app-entwicklung">
              <b>
                <u>App-Entwicklung</u>
              </b>
            </NavLink>{' '}
            in Ingolstadt auf unsere Experten und kümmern Sie sich in aller Ruhe
            um Ihr Kerngeschäft.
          </p>
          <br />

          <h2>
            Durchdachtes Konzept beim Webdesign in Ingolstadt, das messbare
            Erfolge liefert
          </h2>
          <br />
          <p>
            Wir starten bei jedem Webdesign in Ingolstadt mit einem
            Auftaktgespräch, bei dem Sie im Fokus stehen. Was sind Ihre Wünsche?
            Was stellen Sie sich vor? Welche Ideen haben Sie? In Verbindung mit
            unserer Expertise entstehen erste Idee und Ansätze. Wir analysieren
            zudem Ihre Zielgruppe und lassen die Ergebnisse in den ersten
            Entwurf des Webdesigns in Ingolstadt einfließen. Im Anschluss
            arbeiten wir in der Konzeptphase einen Zeitplan aus und gestalten
            eine vorläufige Struktur Ihres neuen Webdesigns in Ingolstadt. Das
            hierbei entstandene Wireframe als grobe Skizzierung Ihrer späteren
            Webseite vermittelt Ihnen einen ersten Eindruck Ihres Webdesigns in
            Ingolstadt. Sind Sie zufrieden, geht es mit der Umsetzung weiter und
            Sie bleiben über unser innovatives Prototyping-Tool über alle
            Fortschritte auf dem Laufenden. Sollten Sie sich optional für die
            <NavLink to="/leistungen/webentwicklung">
              {' '}
              <b>
                <u>Webentwicklung</u>
              </b>
            </NavLink>{' '}
            in Ingolstadt entscheiden, übernehmen wir die Programmierung und
            schalten nach einer umfänglichen Testphase Ihr Webdesign in
            Ingolstadt live.
          </p>
          <br />

          <h2>Was meint die Webentwicklung in Ingolstadt?</h2>
          <br />
          <p>
            Bei der Webentwicklung in Ingolstadt geht es um die Programmierung
            einer Webseite. Diese umfasst neben dem Entwurf sowie der
            letztlichen Implementierung auch die stetige Weiterentwicklung, die
            laufende Wartung und die regelmäßig durchzuführenden Updates. Eine
            hervorragende Möglichkeit ist auch die App-Entwicklung in
            Ingolstadt, sodass Sie die hohen Anforderungen Ihrer Zielgruppen mit
            innovativem Service noch besser erfüllen können.
          </p>
          <br />

          <h2>Was macht eine Webentwicklung in Ingolstadt?</h2>
          <br />
          <p>
            Im Rahmen der Webentwicklung in Ingolstadt stellen wir die gesamte
            Entwicklung von Webseiten, Online-Shops und Web-Apps sicher und
            gestalten Schnittstellen für eine plattformübergreifende
            Kundenkommunikation, die maßgeblich für eine ganzheitliche
            Sichtbarkeit sorgt.
          </p>
          <p>Das erhalten Sie bei der Webentwicklung in Ingolstadt:</p>
          <li>Planung und Entwurf von interaktiven Webseiten</li>
          <li>
            Erstellung eines Entwurfs individueller Webdesigns mit dynamischen
            Inhalten
          </li>
          <li>Implementierung der Webseiteninhalte</li>
          <li>professionelles Webhosting</li>
          <li>
            regelmäßige Wartungen und Weiterentwicklungen nach aktuellen
            Webstandards
          </li>
          <p>
            Somit umfasst die Webentwicklung in Ingolstadt alle Maßnahmen, die
            das Frontend ebenso wie das Backend betreffen. Im Frontend
            entwickeln wir maßgeschneiderte Lösungen, die dem Kunden direkt
            zugutekommen und im Backend organisieren wir sämtliche Daten in
            Datenbanken.
          </p>
          <p>Das beinhaltet die Webentwicklung in Ingolstadt:</p>
          <li>geschäftliche oder private Webseitengestaltung</li>
          <li>Entwicklung von Online-Shops</li>
          <li>Betreuung, Wartung und Weiterentwicklung von Webseiten</li>
          <li>Entwicklung und Realisierung von mobilen Apps</li>
          <li>Einbindung von Marktplätzen</li>
          <li>Implementierung von Softwarelösungen</li>
          <li>Arbeiten am Frontend und Backend</li>
          <br />

          <h2>
            Webdesign, Webentwicklung und App-Entwicklung Ingolstadt – was
            können wir für Sie tun?
          </h2>
          <br />
          <p>
            Bei uns haben Zuverlässigkeit, Kompetenz, Termintreue und Kundennähe
            noch einen besonders hohen Stellwert, weshalb sich unsere Kunden auf
            Bodenständigkeit, Professionalität, Expertise und Qualität verlassen
            können. Neben dem Webdesign und der Webentwicklung übernehmen wir
            auch gerne die App-Entwicklung in Ingolstadt für Ihren mobilen
            Auftritt. Starten Sie noch heute mit uns die Digitalisierung in
            Ingolstadt und lassen Sie uns virtuelle Erlebnisse kreieren, die
            niemand so schnell vergessen wird. Nehmen Sie jetzt unverbindlich{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            zu uns auf.
          </p>
        </LPContent>
        <Referenzen category="design" />
        <Kundenmeinungen category="design" />
        <KontaktShort item={Content.contactShort} />
        <Keyfacts item={Content.keyfacts} />
        <Technologien technologie="web" />
        <Quote title={Content.quote.title} autor={Content.quote.autor} />
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default LPWebdesignIngolstadt
