import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/webdesign/aalen.json'
import Content from '@content/leistungen/design.json'
import React, { Suspense } from 'react'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const KontaktShort = React.lazy(
  () => import('@components/sections/KontaktShort')
)
const Liste = React.lazy(() => import('@components/sections/Liste'))
const Quote = React.lazy(() => import('@components/sections/Quote'))
const Technologien = React.lazy(
  () => import('@components/sections/Technologien')
)
const Keyfacts = React.lazy(() => import('@components/sections/Keyfacts'))
const Referenzen = React.lazy(() => import('@components/sections/Referenzen'))

const LPWebDesignAalen = () => {
  return (
    <>
      <SEOHeader id="lp-webdesign-aalen" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Warum ist das Webdesign in Aalen so wichtig?</h2>
          <br />
          <p>
            Das Webdesign in Aalen ist Ihre digitale Visitenkarte. Hier
            verschaffen sich Besucher und Interessenten einen ersten Eindruck
            und entscheiden, ob Sie zu Kunden Ihrer Produkte oder
            Dienstleistungen werden. Sie selbst wissen am besten, dass die
            Anforderungen Ihrer Zielgruppe permanent steigen und Sie vor immer
            neuen Herausforderungen stehen. Gefragt sind Lösungen und
            Möglichkeiten.
          </p>
          <p>
            Was wünschen sich Ihre Kunde wirklich? Was sind ihre Bedürfnisse?
            Welche Hürden müssen sie nehmen und vor allem, wie überwinden sie
            diese? Ein Webdesign in Aalen gibt Ihrer Zielgruppe Antwort und geht
            auf die Wünsche, Ziele und Träume der Besucher ein. Wir gestalten
            Seiten, bei denen die Lösung und starke Argumente im Vordergrund
            stehen. Ohne aufdringlich zu wirken. Gerne unterstützen Sie die
            Webdesigner Aalen bei der Gestaltung einer professionellen Webseite,
            die zielorientiert performt.
          </p>
          <p>
            Wir gehen mit Ihnen die volle Distanz und einen Schritt weiter. Beim
            Webdesign in Aalen übernehmen wir die Planung, Strukturierung und
            Implementierung Ihrer Webseite als stabiles Fundament für mehr
            Sichtbarkeit und Reichweite. Ziel ist es dabei, Ihre Vorstellungen
            in Einklang mit den Erwartungen Ihrer Zielgruppe zu bringen. Wir
            hauchen in unserer Design Agentur Aalen Ihrer Marken-Identität
            digitales Leben ein.
          </p>
          <br />

          <h2>Warum eine Design Agentur Aalen beauftragen?</h2>
          <br />
          <p>
            Während es im Jahr 1992 gerade einmal 10 Webseiten gab, sind es
            heute Milliarden und alle buhlen um die Aufmerksamkeit von
            Zielgruppen, Kunden und Interessenten. Ein starkes Webdesign in
            Aalen ist heute wichtiger denn je, damit Sie auf ganzer Linie
            überzeugen.
          </p>
          <p>
            Was ein starkes Webdesign in Aalen ausmacht? Ein optisch
            unverwechselbares Corporate Design, das Ihre Marke gekonnt
            repräsentiert, eine hohe Nutzerfreundlichkeit mit einer durchdachten
            Struktur und die Gestaltung einer spannenden Customer Journey, die
            einen unvergesslichen Besuch von Anfang bis Ende garantiert. Wie Sie
            eine solche Webseite schaffen? Mit den Webdesignern Aalen von
            Peakconcepts.
          </p>
          <p>
            Seit vielen Jahren kreieren wir Webdesigns, die halten, was sie
            versprechen. Die wirken. Die überzeugen. Die Herzen höherschlagen
            lassen. Heute geht es beim Webdesign in Aalen schon längst nicht
            mehr um das „Ob“, sondern einzig um das „Wie“. Setzen Sie von Beginn
            an auf die professionelle Arbeit unserer Design Agentur Aalen und
            werden Sie mit Ihrer Webseite nicht nur von vielen Milliarden,
            sondern gewinnen Sie echte Relevanz bei Ihrer Zielgruppe.
          </p>
          <p>
            Bedenken Sie, auch im Netz gibt es nur eine Chance für den ersten
            Eindruck, der innerhalb von wenigen Sekunden entsteht. Die digitale
            Welt dreht sich immer schneller, binnen Minuten gehen zahlreiche
            neue Webseiten online und eine Technologie löst die nächste ab. Wir
            behalten für Sie den Überblick und unterstützen Sie mit dem
            Webdesign in Aalen dabei, sich langfristig zu positionieren.
          </p>
          <p>
            Als Ihre Design Agentur Aalen verlassen Sie sich bei Peakconcepts
            auf einen umfassenden Service:
          </p>
          <li>
            Individuelles Webdesign: Wir setzen mit dem Webdesign in Aalen Ihre
            Marke gekonnt ins Rampenlicht, ohne dabei aufdringlich zu wirken.{' '}
          </li>
          <li>
            Innovative Webentwicklung: Wir arbeiten am Front- und Backend für
            die beste Performance Ihrer Seite.
          </li>
          <li>
            Responsive Webseiten: Wir optimieren Ihr Webdesign in Aalen für alle
            Endgeräte.
          </li>
          <li>
            Service und Support: Wir entlasten Sie und übernehmen Hosting,
            Pflege und Optimierung.
          </li>
          <p>
            Nutzen Sie jetzt das Rundum-sorglos-Paket für Ihr Webdesign in Aalen
            und schaffen Sie damit langfristigen Erfolg statt kurzfristiger
            Aufmerksamkeit.
          </p>
          <br />

          <h2>
            Starker Partner für Ihre digitalen Herausforderungen: Peakconcepts
            bietet professionelles Webdesign für Aalen & mehr
          </h2>
          <br />
          <p>
            Ganz gleich, ob Sie einen zuverlässigen Partner für die App
            Entwicklung Stuttgart oder Ihr neues Webdesign in Aalen suchen –
            Peakconcepts bietet Unternehmen aus der Region mit jeder Menge
            Know-how und Erfahrung maßgeschneiderte Lösungen für jede
            Herausforderung.
          </p>
          <p>
            Einsatz moderner Technik: Bei unserer Arbeit setzen wir auf Tools
            auf dem aktuellen Stand der Technik. So sind nicht nur erstklassige
            Resultate garantiert, wir können Ihr Projekt auch besonders zügig
            umsetzen.
          </p>
          <p>
            Transparente Arbeitsabläufe: In unserer Agentur haben Sie einen
            direkten Ansprechpartner für Ihren Auftrag. Dieser informiert Sie
            nicht nur über alle Fortschritte, sondern beantwortet auch alle
            aufkommenden Fragen.
          </p>
          <p>
            Faire Preise: Unsere Kunden sind nicht nur von unserer
            professionellen Arbeit begeistert, sondern auch von unseren fairen
            Konditionen – fordern Sie am besten noch heute Ihr persönliches
            Angebot für Ihr Webdesign in Aalen an und überzeugen Sie sich
            selbst.
          </p>
        </LPContent>
        <Referenzen category="web" />
        <Kundenmeinungen category="web" />
        <KontaktShort item={Content.contactShort} />
        <Keyfacts item={Content.keyfacts} />
        <Technologien technologie="web" />
        <Liste
          title={LandingpageContent.why.title}
          dark={false}
          dots={false}
          items={LandingpageContent.why.items}
        />
        <Quote title={Content.quote.title} autor={Content.quote.autor} />
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default LPWebDesignAalen
