import { DarkContext } from '@pages/App'
import Observer from '@researchgate/react-intersection-observer'
import useWindowDimensions from '@services/useWindowDimensions'
//import 'intersection-observer' // optional polyfill
import React, { useContext } from 'react'
import styled from 'styled-components'
interface TitleProps {
  dark?: boolean
  noPadding?: boolean
  bottomPadding?: boolean
}

const SectionElement = styled.section<TitleProps>`
  background: ${(props) => (props.dark ? '#000' : '#FFF')};
  padding-top: ${(props) => props.theme.spacing};
  padding-bottom: ${(props) => (props.bottomPadding ? props.theme.spacing : 0)};
  @media (max-width: 1023px) {
    padding-top: ${(props) => props.theme.spacingMobile};
    padding-bottom: ${(props) =>
      props.bottomPadding ? props.theme.spacingMobile : 0};
  }
  @media (max-width: 600px) {
    padding-left: ${({ theme, noPadding }) =>
      !noPadding && theme.horizontalSpacingMobile};
    padding-right: ${({ theme, noPadding }) =>
      !noPadding && theme.horizontalSpacingMobile};
    .container {
      padding: 0;
    }
  }
  ${({ noPadding }) =>
    noPadding &&
    `
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    
  `}
`

const Section = ({
  children,
  dark = true,
  noPadding,
  bottomPadding = true
}: {
  children: any
  dark?: boolean
  noPadding?: boolean
  bottomPadding?: boolean
}) => {
  const darkContext = useContext(DarkContext)
  const { windowHeight } = useWindowDimensions()

  const handleIntersection = (event: any) => {
    if (event.isIntersecting) {
      // @ts-ignore
      darkContext.set(dark)
    }
  }
  const options = {
    onChange: handleIntersection,
    rootMargin: `-58px 0px -${windowHeight - 60}px 0px`
  }
  return (
    <Observer {...options}>
      <SectionElement
        dark={dark}
        noPadding={noPadding}
        bottomPadding={bottomPadding}>
        {children}
      </SectionElement>
    </Observer>
  )
}

Section.defaultProps = {
  dark: true
}

export default Section
