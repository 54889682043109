import ResponsiveImage from '@components/basics/ResponsiveImage'
import Section from '@components/elements/Section'
import Title from '@components/elements/Title'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

interface ItemProps {
  index: number
  mobileTitleMargin?: boolean
}

const Item = styled.div<ItemProps>`
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: ${(props) =>
    props.index % 2 === 0 ? 'row' : 'row-reverse'};
  -ms-flex-direction: ${(props) =>
    props.index % 2 === 0 ? 'row' : 'row-reverse'};
  flex-direction: ${(props) => (props.index % 2 === 0 ? 'row' : 'row-reverse')};
  margin: 0;
  min-height: 50vh;
  h3 {
    font-size: 3rem;
    margin: 0 0 5px;
  }
  p {
    font-size: 1.25rem;
  }
  hr {
    width: 80px;
    background: ${(props) => props.theme.primary};
    margin: 10px 0;
    text-align: left;
  }
  .column {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  @media (max-width: 991px) {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    margin: 60px 0 20px !important;
    div:first-of-type {
      padding: 0 1.5rem;
    }
    h3 {
      font-size: 2.5rem;
    }
  }
  @media (max-width: 575px) {
    h3 {
      font-size: 2rem;
      margin-top: ${({ mobileTitleMargin }) =>
        mobileTitleMargin ? '0' : '-50px'};
    }
    p {
      font-size: 1rem;
    }
  }
`

const StyledLink = styled(NavLink)`
  display: inline-block;
  color: #000;
  font-weight: 500;
  padding: 10px 30px;
  margin: 0;
  background: ${(props) => props.theme.primary};
`

interface ServiceItemProps {
  icon?: string
  title: string
  text: string
  src: string
  button: {
    link: string
    text: string
  }
  mobileTitleMargin?: boolean
}

const LeistungenOverview = ({
  items,
  title,
  titleSize = 0
}: {
  items: ServiceItemProps[]
  title: string
  titleSize?: number
}) => {
  return (
    <Section dark={false}>
      <div className="container">
        <Title title={title} dark={false} titleSize={titleSize} />
        {items.map((item: ServiceItemProps, i: number) => (
          <Item
            className="columns is-vcentered"
            index={i}
            key={i}
            mobileTitleMargin={item.mobileTitleMargin}>
            <div className="column is-6">
              <h3>{item.title}</h3>
              <hr />
              <p>{item.text}</p>
              <StyledLink to={item.button.link}>{item.button.text}</StyledLink>
            </div>
            <div className="column is-6">
              <ResponsiveImage src={item.src} alt={item.title} />
            </div>
          </Item>
        ))}
      </div>
    </Section>
  )
}

export default LeistungenOverview
