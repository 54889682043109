import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/agentur/socialrecruiting.json'
import Content from '@content/leistungen/socialrecruiting.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))

const SocialRecruitingagentur = () => {
  return (
    <>
      <SEOHeader id="lp-agentur-socialrecruiting" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Warum Social Recruiting?</h2>
          <br />
          <p>
            Social Media Recruiting überzeugt mit einer immensen Reichweite. Die
            Mitgliederzahlen auf den meisten Plattformen übersteigen die
            Millionengrenze. Sie erreichen mit Social Media Recruiting
            zahlreiche potenzielle Fachkräfte – je nach Bedarf regional,
            deutschland-, europa- oder sogar weltweit. Die Ansprache des
            Bewerbers können wir als Social Recruiting Agentur bis in die Tiefe
            personalisieren, sodass Sie schon zu Beginn einen positiven Eindruck
            hinterlassen. Sie stärken zudem nachhaltig Ihre Arbeitgebermarke.
            Anhand von bestimmten Parametern sorgen wir als Social Recruiting
            Agentur dafür, dass genau die Kandidaten angesprochen werden, die
            Sie sich wünschen. Damit erreichen wir nicht nur aktiv Suchende,
            sondern auch jene hochwertigen Fachkräfte, die offen für bessere
            Jobangebote sind und noch gar nicht wissen, dass Sie zu Ihnen
            wechseln wollen. Die Ergebnisse des Social Recruitings sind ferner
            messbar und damit zu jederzeit optimierbar.
          </p>
          <br />
          <h2>
            Ihre Vorteile mit uns als professionelle Social Media Recruiting
            Agentur
          </h2>
          <br />
          <p>
            Wir, von{' '}
            <NavLink to="/">
              <b>
                <u>Peakconcepts</u>
              </b>
            </NavLink>{' '}
            , als professionelle Social Recruiting Agentur bringen das
            Verständnis mit, wie Unternehmen authentisch auf den
            Social-Media-Plattformen agieren müssen, um für ihre Wunschbewerber
            wirklich interessant zu werden. Ob Karriereportale wie LinkedIn oder
            Xing oder Freizeitnetzwerke wie Facebook oder Instagram, wir
            schneidern Recruiting Ads nach Maß, abgestimmt auf Ihre Zielgruppe
            und die richtige Ansprache entsprechend der Plattform.
          </p>
          <p>
            Mit unserem Full-Service als E-Recruiting Agentur erhalten Sie
            unwiderstehliche Vorteile und{' '}
            <NavLink to="/leistungen">
              <b>
                <u>Leistungen</u>
              </b>
            </NavLink>{' '}
            :
          </p>
          <li>Entwicklung von Kampagnen und Strategien</li>
          <li>maßgeschneiderte und effiziente Recruiting-Werbeanzeigen</li>
          <li>zielgruppengenaue und plattformausgerichtete Ansprache</li>
          <li>mehr Sichtbarkeit</li>
          <li>Implementierung eines einfachen Bewerbungsprozesses</li>
          <li>erhöhte Aufmerksamkeit</li>
          <li>direkte Kommunikation</li>
          <li>automatisierte Vorselektion</li>
          <li>
            hochwertige Bewerber mit der fachlichen passenden Qualifikation
          </li>
          <p>
            Als Ihre Social Recruiting Agentur begleiten wir Sie ganzheitlich
            auf dem Weg zu Ihrem Erfolgsteam. Wir übernehmen für Sie die
            Planung, Konzeption, Umsetzung sowie Implementierung und stellen
            sicher, dass Sie mit Ihren Wunschkandidaten matchen. Schließlich
            geht es um Ihre Zukunft.
          </p>
          <br />
          <h2>Die Leistungen Ihrer E-Recruiting Agentur</h2>
          <br />
          <p>
            Als Social Recruiting Agentur unterstützen wir Sie mit den richtigen
            Kampagnen in den sozialen Medien, um einen Mitarbeiterstamm
            aufzubauen, mit dem Sie zusammen langfristig Ihre Ziele erreichen.
            Statt „Helfer“ bewerben sich bei Ihnen echte Fachkräfte, die Sie
            wirklich brauchen. Und immer dann, wann Sie diese brauchen. Mit uns
            als Ihre Social Recruiting Agentur bleiben Sie maximal flexibel.
            Zusammen starten wir ein erstes, umfassendes Beratungsgespräch und
            stecken uns gemeinsam anhand Ihrer Vorstellungen erste Ziele. Im
            Anschluss führen wir als E-Recruiting Agentur eine Analyse Ihrer
            aktuellen Präsenz und Ihrer Zielgruppe durch und wählen anhand der
            Ergebnisse strategisch günstigste sowie relevante
            Social-Media-Kanäle aus. Der Prozess mündet anschließend in die
            Konzeption einer Strategie und der Planung sowie Entwicklung von
            Kampagnen. Wir als Ihre Social Recruiting Agentur erstellen
            zielgruppengerechte Anzeigen, verleihen ihnen mit den ansprechenden
            Bildern und kreativen Worten eine magische Anziehungskraft, wecken
            Emotionen und monitoren die Ergebnisse, um mögliche
            Optimierungspotenziale zu identifizieren und vorzunehmen.
          </p>

          <p>
            Bei uns als Ihre Social Recruiting Agentur verlassen Sie sich auf
            ein Rundum-sorglos-Paket mit{' '}
            <NavLink to="/referenzen">
              <b>
                <u>Referenzen</u>
              </b>
            </NavLink>{' '}
            aus einer professionellen Hand.
          </p>
          <br />
          <h2>
            Bereit für mehr Sichtbarkeit? Wir sind Ihre Social Recruiting
            Agentur
          </h2>
          <br />
          <p>
            Sie wollen in den nächsten ein bis vier Wochen über
            hochqualifizierte Bewerbungen Ihre ersten Fachkräfte einstellen? Wir
            als Social Recruiting Agentur machen es möglich. Wir sind Ihr
            smarter Personalvermittler und machen Sie zum Arbeitgeber, bei dem
            man sich gerne bewirbt.
          </p>
          <p>
            Setzen Sie sich jetzt mit den Experten der Social Media Recruiting
            Agentur Peakconcepts in Verbindung. Wir freuen uns auf Ihre{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontaktaufnahme</u>
              </b>
            </NavLink>
            .
          </p>
        </LPContent>
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default SocialRecruitingagentur
