import Section from '@components/elements/Section'
import parse from 'html-react-parser'
import React from 'react'
import styled from 'styled-components'

interface LPContentProps {
  dark?: boolean
}

interface ItemProps {
  dark?: boolean
  dots?: boolean
}

export const Item = styled.div<ItemProps>`
  position: relative;
  label {
    color: ${({ theme }) => theme.primary};
    font-size: 4rem;
    font-weight: 900;
  }
  p {
    color: ${({ dark }) => (dark ? '#FFF' : '#000')};
  }
  p.headline {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
  }
`
export const LabelContainer = styled.div`
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: end;
  -moz-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  & > label {
    margin: 0;
    line-height: 4.5rem;
    margin-right: 10px;
  }
`

const LPContentDiv = styled.div<LPContentProps>`
  background: ${(props) => (props.dark ? '#000' : '#FFF')};
  h1,
  h2,
  h3 {
    color: ${(props) => (props.dark ? '#FFF' : '#000')};
    span {
      display: inline-block;
      color: ${(props) => (props.dark ? '#FFF' : '#000')};
      border-bottom: 4px solid ${(props) => props.theme.primary};
      padding-bottom: 0px;
      margin: 0;
    }
  }
`

const LPContent = ({
  dark = false,
  content,
  children
}: {
  dark?: boolean
  content: any
  children?: any
}) => {
  return (
    <Section dark={dark}>
      <LPContentDiv dark={dark}>
        <div className="container">
          <div>
            <h1>{parse(content.description.title, { trim: true })}</h1>
            <br />
            {content.description.items.map((item: string, i: number) => (
              <p key={i}>{parse(item, { trim: true })}</p>
            ))}
            <br />
            <br />
            {content.description.title2 && (
              <>
                <h2>{parse(content.description.title2, { trim: true })}</h2>
                <br />
              </>
            )}

            {content.description.items2 && (
              <>
                {content.description.items2.map((item: string, i: number) => (
                  <p key={i}>{parse(item, { trim: true })}</p>
                ))}
                <br />
              </>
            )}

            {content.description.list && (
              <>
                {content.description.list.map(
                  (item: { title: string; text: string }, i: number) => (
                    <Item dark={dark} key={i}>
                      <LabelContainer>
                        <label>{`${i <= 10 && '0'}${i + 1}`}</label>
                        <p className="headline">
                          {parse(item.title, { trim: true })}
                        </p>
                      </LabelContainer>
                      <p>{parse(item.text, { trim: true })}</p>
                    </Item>
                  )
                )}
                <br />
              </>
            )}
            {content.description.simpleList && (
              <>
                {content.description.simpleList.map(
                  (item: { title: string; text: string }, i: number) => (
                    <>
                      {item.title && (
                        <h4>{parse(item.text, { trim: true })}</h4>
                      )}
                      {item.text && <p>{parse(item.text, { trim: true })}</p>}
                    </>
                  )
                )}
                <br />
              </>
            )}
          </div>
          {content?.description2 && (
            <div>
              {content?.description2.label && (
                <>
                  <p>{parse(content.description2.label, { trim: true })}</p>
                  <br />
                </>
              )}
              {content?.description2.title && (
                <>
                  <h3>{parse(content.description2.title, { trim: true })}</h3>
                  <br />
                </>
              )}

              {content?.description2.text && (
                <p>{parse(content.description2.text, { trim: true })}</p>
              )}
              {content?.description2?.items?.map((item: string, i: number) => (
                <p key={i}>{parse(item, { trim: true })}</p>
              ))}
              {content?.description2.info && (
                <p>{parse(content?.description2?.info, { trim: true })}</p>
              )}
            </div>
          )}
        </div>
        {children && <div className="container">{children}</div>}
      </LPContentDiv>
    </Section>
  )
}

export default LPContent
