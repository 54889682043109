import TagManager from 'react-gtm-module'
import Title from '@components/elements/Title'
import { sendContactForm } from '@services/contact'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import ResponsiveImage from '@components/basics/ResponsiveImage'

const ColumnsStyler = styled.div`
  .field {
    input[type='text'],
    input[type='email'],
    textarea {
      border-color: #d3d3d3;
      box-shadow: 0 0 2pt 0.5pt #d3d3d3;
      &:hover {
        border-color: #adadad;
      }
    }
    &.error {
      color: red;
      font-size: 12px;
      input,
      textarea {
        border-color: #adadad;
        color: red;
      }
    }
  }
  .control {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 5px;
    padding-top: 8px;
    cursor: pointer;
    font-size: 16px;
  }
  .control input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .control_indicator {
    position: absolute;
    top: 14px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid #adadad;
    border-radius: 0px;
  }
  .control:hover input ~ .control_indicator,
  .control input:focus ~ .control_indicator {
    background: #fff;
  }

  .control input:checked ~ .control_indicator {
    background: ${(props) => props.theme.primary};
    border: none;
  }
  .control:hover input:not([disabled]):checked ~ .control_indicator,
  .control input:checked:focus ~ .control_indicator {
    background: ${(props) => props.theme.primary};
    border: none;
  }
  .control_indicator:after {
    box-sizing: unset;
    content: '';
    position: absolute;
    display: none;
  }
  .control input:checked ~ .control_indicator:after {
    display: block;
  }
  .control-checkbox .control_indicator:after {
    left: 8px;
    top: 4px;
    width: 3px;
    height: 8px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
  img {
    width: 100%;
  }
  @media (max-width: 768px) {
    .columns {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
    }
  }
`

const PopUpContainer = styled.div`
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 99999999999999;
  .modal-background {
    background: #fff;
  }
  .modal-content {
    width: 90%;
  }
  .modal-close {
    height: 50px;
    max-height: 50px;
    max-width: 50px;
    min-height: 50px;
    min-width: 50px;
    width: 50px;
    right: 10px;
    top: 10px;
    :after,
    :before {
      background-color: #000;
    }
  }
`

const ContactForm = ({ title }: { title?: string }) => {
  const ContactSchema = Yup.object().shape({
    name: Yup.string().required('Bitte Namen eintragen'),
    company: Yup.string(),
    phone: Yup.string().required('Bitte Telefonnummer eintragen'),
    email: Yup.string()
      .email('E-Mail-Adresse nicht gültig')
      .required('Bitte E-Mail eintragen'),
    agree: Yup.boolean().oneOf([true], 'Bitte akzeptieren Sie den Datenschutz')
  })
  const [sendingEmail, setSendingEmail] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const notify = () => {
    setShowNotification(true)
    document.documentElement.classList.add('is-clipped')
  }
  const hideNotification = () => {
    setShowNotification(false)
    document.documentElement.classList.remove('is-clipped')
  }

  return (
    <div className="container">
      {showNotification && (
        <PopUpContainer className="modal is-active">
          <div className="modal-background"></div>
          <div className="modal-content">
            <h2>Ihre Nachricht wurde erfolgreich gesendet</h2>
            <p>
              Ein Experte aus dem Team wird sich in Kürze mit Ihnen in
              Verbindung setzen.
            </p>
            <button
              onClick={() => hideNotification()}
              type="button"
              className="button is-primary">
              Alles klar!
            </button>
          </div>
          <button
            onClick={() => hideNotification()}
            className="modal-close"
            aria-label="close"></button>
        </PopUpContainer>
      )}
      <Title
        title={
          title
            ? title
            : 'Jeder Erfolg beginnt mit dem <span>ersten Schritt</span>'
        }
        dark={false}
        titleSize={0}
      />
      <ColumnsStyler>
        <div className="columns">
          <div className="column">
            <Formik
              initialValues={{
                name: '',
                email: '',
                phone: '',
                agree: false,
                company: '',
                message: ''
              }}
              validationSchema={ContactSchema}
              onSubmit={async (
                { name, email, phone, company, message },
                { resetForm }
              ) => {
                // same shape as initial values
                setSendingEmail(true)
                try {
                  const response = await sendContactForm({
                    name,
                    email,
                    phone,
                    company,
                    message
                  })
                  if (response) {
                    TagManager.dataLayer({
                      dataLayer: { event: 'contactSent' }
                    })
                    notify()
                    resetForm()
                  }
                  setSendingEmail(false)
                } catch (error) {
                  setSendingEmail(false)
                }
              }}>
              {({ errors, touched }) => (
                <Form>
                  <div
                    className={`field ${
                      errors.name && touched.name ? 'error' : ''
                    }`}>
                    <Field
                      name="name"
                      type="text"
                      className="input is-large"
                      placeholder="Name"
                    />
                    <ErrorMessage name="name" />
                  </div>
                  <div className="field">
                    <Field
                      name="company"
                      type="text"
                      className="input is-large"
                      placeholder="Firma"
                    />
                    <ErrorMessage name="company" />
                  </div>
                  <div
                    className={`field ${
                      errors.email && touched.email ? 'error' : ''
                    }`}>
                    <Field
                      name="email"
                      type="email"
                      placeholder="E-Mail"
                      className="input is-large"
                    />
                    <ErrorMessage name="email" />
                  </div>
                  <div
                    className={`field ${
                      errors.phone && touched.phone ? 'error' : ''
                    }`}>
                    <Field
                      name="phone"
                      type="text"
                      className="input is-large"
                      placeholder="Telefon"
                    />
                    <ErrorMessage name="phone" />
                  </div>
                  <div
                    className={`field ${
                      errors.message && touched.message ? 'error' : ''
                    }`}>
                    <Field
                      name="message"
                      as="textarea"
                      className="textarea is-large"
                      placeholder="Wie können wir helfen?"
                    />
                    <ErrorMessage name="message" />
                  </div>
                  <div className="field">
                    <Field name={'agree'} className="input is-large">
                      {({ field, form }: any) => (
                        <label className="control control-checkbox">
                          Ich bin mit der Verwendung meiner eingegebenen Daten
                          zur Verarbeitung meiner Anfrage einverstanden.
                          <input
                            type="checkbox"
                            checked={field.value}
                            onChange={() => {
                              form.setFieldValue('agree', !field.value)
                            }}
                            className="checkbox"
                          />
                          <div className="control_indicator"></div>
                        </label>
                      )}
                    </Field>
                    <div className={`field ${errors.agree ? 'error' : ''}`}>
                      <ErrorMessage name="agree" />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="button is-primary"
                    disabled={sendingEmail}>
                    {sendingEmail ? 'Bitte warten...' : 'Anfrage senden'}
                  </button>
                </Form>
              )}
            </Formik>
          </div>

          <div className="column">
            <ResponsiveImage
              src={'/images/contact/melik-su.jpg'}
              alt="Melik Su"
            />
            <span>
              <strong>Ihr Ansprechpartner:</strong> Melik Su
            </span>
          </div>
        </div>
      </ColumnsStyler>
    </div>
  )
}

export default ContactForm
