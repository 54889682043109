import Section from '@components/elements/Section'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

interface LinkProps {
  small?: boolean
  inline?: boolean
}

const Logo = styled.img`
  filter: saturate(0) invert(1);
  width: 100%;
  max-width: 200px;
`
const Label = styled.label`
  color: ${(props) => props.theme.primary};
`
const NavLinkCleaned = (props: any) => (
  <NavLink {...props} small={undefined} inline={undefined} />
)
const StyledLink = styled(NavLinkCleaned)<LinkProps>`
  color: #fff;
  display: ${(props) => (props.inline ? 'inline-block' : 'flex')};
  padding: ${(props) => (props.inline ? '0 15px 0 0' : '0')};
  font-size: ${(props) => (props.small ? '12px' : '16px')};
  margin: 0;
  &:hover {
    color: ${(props) => props.theme.primary};
  }
`

const FooterBox = styled.div`
  p {
    color: ${(props) => props.theme.primary};
    font-weight: bold;
    margin: 0 0 20px;
  }
  .item {
    a,
    p,
    span {
      color: #fff;
      font-weight: normal;
      margin: 0;
    }
    i {
      color: #fff;
      margin: 0 10px 0 0;
    }
    a {
      &:hover {
        color: ${(props) => props.theme.primary};
      }
    }
  }
`

const ProvenExpert = styled.div`
  span {
    line-height: 1 !important;
  }
  .pe_u,
  .pe_u span {
    color: #fff;
    font-size: 14px !important;
  }
  #pe_name {
    max-width: 150px;
  }
`
const Badges = styled.img`
  height: 70px;
  @media (max-width: 991px) {
    height: 50px;
  }
`

const Footer = () => {
  return (
    <Section>
      <FooterBox>
        <div className="container">
          <div className="columns">
            <div className="column is-4">
              <Logo
                src={require('../../assets/logo.svg').default}
                alt="Peakconcepts App Entwicklung Aalen"
              />
            </div>
          </div>
          <div className="columns">
            <div className="column is-4">
              <p>
                Sie möchten Kontakt zu uns aufnehmen? Dann schreiben Sie uns
                eine E-Mail oder rufen uns an!
              </p>
              <div className="item">
                <p>
                  <i className="material-icons">schedule</i>Montag-Freitag:
                  09:00 - 18:00 Uhr
                </p>
              </div>
              <div className="item">
                <i className="material-icons">phone</i>
                <a
                  href={'tel:' + process.env.REACT_APP_COMPANY_PHONE_FORMATTED}>
                  {process.env.REACT_APP_COMPANY_PHONE}
                </a>
              </div>
              <div className="item">
                <i className="material-icons">mail</i>
                <a href={'mailto:' + process.env.REACT_APP_COMPANY_EMAIL}>
                  {process.env.REACT_APP_COMPANY_EMAIL}
                </a>
              </div>
              <br />
              <div>
                <a
                  href="https://www.provenexpert.com/peakconcepts-gmbh/?utm_source=Widget&utm_medium=Widget&utm_campaign=Widget"
                  title="Kundenbewertungen &amp; Erfahrungen zu PEAKCONCEPTS GmbH. Mehr Infos anzeigen."
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    src="https://images.provenexpert.com/da/96/0a7a5b2fd3e0dbc9f691ee1a1207/widget_recommendation_465_0.png?t=1596781678516"
                    alt="Kundenbewertungen &amp; Erfahrungen zu PEAKCONCEPTS GmbH. Mehr Infos anzeigen."
                    style={{ border: 0, marginTop: 20, marginBottom: 10 }}
                  />
                </a>
              </div>
              <ProvenExpert className="pe-richsnippets"></ProvenExpert>
            </div>
            <div className="column is-offset-4 is-4">
              <div className="columns is-mobile">
                <div className="column is-6">
                  <Label>Informationen</Label>
                  <StyledLink to="/">Startseite</StyledLink>
                  <StyledLink to="/leistungen">Leistungen</StyledLink>
                  <StyledLink to="/agentur">Agentur</StyledLink>
                  <StyledLink to="/referenzen">Referenzen</StyledLink>
                  <StyledLink to="/kontakt">Kontakt</StyledLink>
                </div>
                <div className="column is-6">
                  <Label>Hilfe</Label>
                  <StyledLink to="/agb">AGB</StyledLink>
                  <StyledLink to="/jobs">Jobs</StyledLink>
                  <StyledLink to="/go-digital">Go-Digital</StyledLink>
                  <StyledLink to="/impressum">Impressum</StyledLink>
                  <StyledLink to="/datenschutz">Datenschutz</StyledLink>
                </div>
              </div>
              <div className="columns is-mobile">
                <div className="column">
                  <Badges
                    src={require('../../assets/logos/go-digital.svg').default}
                    alt="Go-Digital"
                  />
                </div>
                <div className="column">
                  <Badges
                    src={require('../../assets/logos/bwcon.svg').default}
                    alt="bwcon"
                  />
                </div>
              </div>
              <div className="columns is-mobile">
                <div className="column is-12">
                  <Label>Weitere Links</Label>
                  <br />
                  <StyledLink small inline to="/webentwicklung-agentur">
                    Webentwicklungsagentur
                  </StyledLink>
                  <StyledLink small inline to="/app-entwicklung-agentur">
                    Appentwicklungsagentur
                  </StyledLink>
                  <StyledLink small inline to="/digitalisierungs-agentur">
                    Digitalisierungsagentur
                  </StyledLink>
                  <StyledLink small inline to="/digitalagentur">
                    Digitalagentur
                  </StyledLink>
                  <StyledLink small inline to="/leadgenerierung-agentur">
                    Leadgenerierungsagentur
                  </StyledLink>
                  <StyledLink small inline to="/leadgenerierung">
                    Leadgenerierung
                  </StyledLink>
                  <StyledLink
                    small
                    inline
                    to="/leistungen/webentwicklung-stuttgart">
                    Webentwicklung Stuttgart
                  </StyledLink>
                  <StyledLink
                    small
                    inline
                    to="/leistungen/webdesign-schwaebisch-gmuend">
                    Webdesign Schwäbisch Gmünd
                  </StyledLink>
                  <StyledLink small inline to="/leistungen/webdesign-aalen">
                    Webdesign Aalen
                  </StyledLink>
                  <StyledLink small inline to="/leistungen/webdesign-ulm">
                    Webdesign Ulm
                  </StyledLink>
                  <StyledLink
                    small
                    inline
                    to="/leistungen/webdesign-goeppingen">
                    Webdesign Göppingen
                  </StyledLink>
                  <StyledLink
                    small
                    inline
                    to="/leistungen/webdesign-ludwigsburg">
                    Webdesign Ludwigsburg
                  </StyledLink>
                  <StyledLink
                    small
                    inline
                    to="/leistungen/webdesign-noerdlingen">
                    Webdesign Nördlingen
                  </StyledLink>
                  <StyledLink
                    small
                    inline
                    to="/leistungen/webdesign-reutlingen">
                    Webdesign Reutlingen
                  </StyledLink>
                  <StyledLink small inline to="/leistungen/webdesign-wuerzburg">
                    Webdesign Würzburg
                  </StyledLink>
                  <StyledLink small inline to="/leistungen/webdesign-augsburg">
                    Webdesign Augsburg
                  </StyledLink>
                  <StyledLink small inline to="/leistungen/webdesign-heilbronn">
                    Webdesign Heilbronn
                  </StyledLink>
                  <StyledLink
                    small
                    inline
                    to="/leistungen/webdesign-ingolstadt">
                    Webdesign Ingolstadt
                  </StyledLink>
                  <StyledLink small inline to="/leistungen/webdesign-ansbach">
                    Webdesign Ansbach
                  </StyledLink>
                  <StyledLink small inline to="/leistungen/webdesign-tuebingen">
                    Webdesign Tübingen
                  </StyledLink>
                  <StyledLink
                    small
                    inline
                    to="/leistungen/webdesign-schwaebisch-hall">
                    Webdesign Schwäbisch Hall
                  </StyledLink>
                  <StyledLink small inline to="/leistungen/webdesign-guenzburg">
                    Webdesign Günzburg
                  </StyledLink>
                  <StyledLink
                    small
                    inline
                    to="/leistungen/webdesign-donauwoerth">
                    Webdesign Donauwörth
                  </StyledLink>
                  <StyledLink
                    small
                    inline
                    to="/leistungen/app-entwicklung-stuttgart">
                    App Entwicklung Stuttgart
                  </StyledLink>
                  <StyledLink small inline to="/mitarbeitergewinnung">
                    Mitarbeitergewinnung
                  </StyledLink>
                  <StyledLink small inline to="/mitarbeitergewinnung-pflege">
                    Mitarbeitergewinnung Pflege
                  </StyledLink>
                  <StyledLink small inline to="/mitarbeitergewinnung-handwerk">
                    Mitarbeitergewinnung Handwerk
                  </StyledLink>
                  <StyledLink small inline to="/mitarbeiter-finden">
                    Mitarbeiter finden
                  </StyledLink>
                  <StyledLink small inline to="/employer-branding-agentur">
                    Employer Branding Agentur
                  </StyledLink>
                  <StyledLink small inline to="/marketing-agentur-aalen">
                    Marketing Agentur Aalen
                  </StyledLink>
                  <StyledLink small inline to="/social-recruiting-agentur">
                    Social Recruiting Agentur
                  </StyledLink>
                  <StyledLink small inline to="/recruiting-agentur">
                    Recruiting Agentur
                  </StyledLink>
                  <StyledLink small inline to="/linkedin-recruiting">
                    LinkedIn Recruiting
                  </StyledLink>
                  <StyledLink small inline to="/personalmarketing-agentur">
                    Personalmarketing
                  </StyledLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FooterBox>
    </Section>
  )
}

export default Footer
