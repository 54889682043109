import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/agentur/personalmarketing.json'
import Content from '@content/leistungen/personalmarketing.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))

const PersonalMarketingAgentur = () => {
  return (
    <>
      <SEOHeader id="lp-personalmarketing-agentur" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Warum eine Agentur für Personalmarketing?</h2>
          <br />
          <p>
            Im Vorfeld stellen sich viele unserer Kunden die Frage, warum eine
            Werbeagentur Personalmarketing beauftragen. Einerseits haben wir uns
            als Personalmarketing Agentur auf die professionelle Gewinnung von
            Talenten offline, aber natürlich vor allem online spezialisiert. Das
            bedeutet, dass Sie mit uns an Ihrer Seite zu jeder Zeit auf höchste
            Expertise vertrauen und den gesamten Recruiting-Prozess in
            zuverlässigen Händen wissen. Andererseits entlasten Sie wesentlich
            Ihre Personalmitarbeiter, die die nun wieder freie Kapazitäten
            sinnvoll nutzen können.
          </p>
          <p>
            Mit einer Personalmarketing Agentur wie uns beugen Sie rechtzeitig
            einem Personal- und Fachkräftemangel vor, stellen sich
            zukunftssicher am Markt auf und erhalten eine nachhaltige
            Möglichkeit, jederzeit eine schnelle Mitarbeitergewinnung zu
            aktivieren. In Zeiten des immer komplexer werdenden
            Personalmarketings ist die Zusammenarbeit mit einer
            Personalmarketing Agentur ein Erfolgsschlüssel.
          </p>
          <br />
          <h2>Welche Vorteile hat eine Werbeagentur Personalmarketing?</h2>
          <br />
          <p>
            Nutzen Sie die unschlagbaren Vorteile einer Agentur für
            Personalmarketing. Grundsätzlich startet bei uns eine Zusammenarbeit
            mit einem ausführlichen Beratungsgespräch und der Erfassung des
            Status quo. Wir setzen uns gemeinsam Ziele, entwickeln darauf
            basierend eine ganzheitliche Strategie, setzen die dabei definierten
            Maßnahmen um und sorgen für eine fortlaufende Evaluation sowie
            Optimierung.
          </p>
          <p>Ihre Vorteile mit einer Personalmarketing Agentur:</p>
          <li>höchste Fachkenntnis</li>
          <li>fundierte Strategieerstellung und Umsetzung</li>
          <li>Full-Service von Design bis zur Kommunikation</li>
          <li>Kontinuität und fortlaufende Optimierung der Kampagnen</li>
          <li>neue Ideen, Blickwinkel und Inspiration</li>
          <li>Zeit- und Arbeitsersparnis</li>
          <li>umfassendes Monitoring</li>
          <br />
          <h2>Die Tools einer Personalmarketing Agentur</h2>
          <br />
          <p>
            Als professionelle Personalmarketing Agentur nutzen wir
            selbstverständlich die gesamte Bandbreite an Tools und
            Möglichkeiten, die uns zur Verfügung stehen. Neben Karriereportalen
            bauen wir als Ihre Personalmarketing Agentur auf die Erstellung oder
            Optimierung Ihrer Webpräsenz, das Content-Marketing, das Social
            Recruiting und das Employer Branding. Die letzte Maßnahme ist
            besonders wichtig, um Sie als attraktiver Arbeitgeber auf dem
            Arbeitsmarkt zu positionieren.
          </p>
          <p>
            Erst, wenn Sie bei Ihrer Zielgruppe positiv als Arbeitgebermarke in
            Erscheinung treten, haben Sie bei der heutigen Mitarbeitergewinnung
            eine Chance, schneller als Ihre Mitbewerber im „War of Talents“ zu
            sein. Ziel des Employer Brandings ist es, die Wahrnehmung Ihres
            Unternehmens so zu beeinflussen, dass Sie Ihre Unternehmenskultur,
            Ihre Philosophie und Werte nach außen sichtbar kommunizieren und
            zeigen, dass man bei Ihnen gerne arbeitet. So kann beispielsweise
            Ihr Stammpersonal zu Markenbotschaftern werden.
          </p>
          <br />
          <h2>Peakconcepts als Ihre Personalmarketing Agentur</h2>
          <br />
          <p>
            Was tun wir als Ihre Personalmarketing Agentur für Sie? Für uns geht
            das Recruiting weit über das Schalten von Anzeigen hinaus. Als
            bodenständige Personalmarketing Agentur schauen wir dabei über den
            Tellerrand. Dazu gehört es auch zu schauen, wie Ihr aktuelles
            Recruiting aussieht, was Sie sich vorstellen, was sich Ihre
            potenziellen Mitarbeiter wünschen und was ein Bewerbungsprozess
            mitbringen muss, um nahtlos in Ihre Strukturen zu passen. Bei uns
            erwartet Sie ein Full-Service, bei dem wir als Personalmarketing
            Agentur eine kompromisslose Qualität sicherstellen.
          </p>
          <p>Das leisten wir als Personalmarketing Agentur für Sie:</p>
          <li>Mediaplanung der Personalmarketing-Kampagnen</li>
          <li>
            Stellenanzeigen Online, auf Social Media, in Print oder anderen
            Kanälen
          </li>
          <li>Content-Marketing</li>
          <li>Employer Branding</li>
          <li>Social Media Recruiting</li>
          <li>Recruiting-Videos</li>
          <li>Internetpräsenz</li>
          <li>Karriere-Webseite</li>
          <li>Karriereblog</li>
          <li>Azubimarketing</li>
          <p>
            Lassen Sie sich jetzt direkt unverbindlich von den Profis unserer
            Personalmarketing Agentur beraten. Gerne beantworten wir alle Ihre
            Fragen und nehmen uns Zeit für Ihr Anliegen. Hier finden Sie unseren{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontaktaufnahme</u>
              </b>
            </NavLink>
            . Setzen Sie von Beginn an auf uns als professionelle
            Personalmarketing Agentur und seien Sie Ihren Mitbewerbern einen
            entscheidenden Schritt voraus. Wir sorgen für Ihren personellen
            Erfolg.
          </p>
        </LPContent>
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default PersonalMarketingAgentur
