/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import SEOHeader from '@components/basics/SEOHeader'
import SimpleHeader from '@components/basics/SimpleHeader'
import Section from '@components/elements/Section'
import React, { useEffect } from 'react'
import styled from 'styled-components'

const DatenschutzBox = styled.div`
  h2 {
    margin: 60px 0 20px;
    &.first {
      margin: 0 0 20px;
    }
  }
  h3 {
    margin: 20px 0 20px;
  }
  h4 {
    margin: 20px 0 20px;
  }
`

function Datenschutz() {
  useEffect(() => {
    const script = document.createElement('script')
    script.id = 'CookieDeclaration'
    script.src =
      'https://consent.cookiebot.com/711ef6a7-8495-4dc5-b724-583ccf8ecbb7/cd.js'
    script.type = 'text/javascript'
    script.async = true

    document.getElementById('consentSciptTarget')?.appendChild(script)
    return () => {
      document.getElementById('consentSciptTarget')?.removeChild(script)
    }
  }, [])
  return (
    <>
      <SEOHeader id="datenschutz" />
      <SimpleHeader title="Datenschutz" />
      <Section dark={false}>
        <DatenschutzBox>
          <div className="container">
            <h2 className="first">1. Datenschutz auf einen Blick</h2>
            <h3>Allgemeine Hinweise</h3>{' '}
            <p>
              Die folgenden Hinweise geben einen einfachen Überblick darüber,
              was mit Ihren personenbezogenen Daten passiert, wenn Sie diese
              Website besuchen. Personenbezogene Daten sind alle Daten, mit
              denen Sie persönlich identifiziert werden können. Ausführliche
              Informationen zum Thema Datenschutz entnehmen Sie unserer unter
              diesem Text aufgeführten Datenschutzerklärung.
            </p>
            <h3>Datenerfassung auf dieser Website</h3>{' '}
            <h4>
              Wer ist verantwortlich für die Datenerfassung auf dieser Website?
            </h4>{' '}
            <p>
              Die Datenverarbeitung auf dieser Website erfolgt durch den
              Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
              „Hinweis zur Verantwortlichen Stelle“ in dieser
              Datenschutzerklärung entnehmen.
            </p>{' '}
            <h4>Wie erfassen wir Ihre Daten?</h4>{' '}
            <p>
              Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
              mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
              ein Kontaktformular eingeben.
            </p>{' '}
            <p>
              Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
              Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
              allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
              Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
              automatisch, sobald Sie diese Website betreten.
            </p>{' '}
            <h4>Wofür nutzen wir Ihre Daten?</h4>{' '}
            <p>
              Ein Teil der Daten wird erhoben, um eine fehlerfreie
              Bereitstellung der Website zu gewährleisten. Andere Daten können
              zur Analyse Ihres Nutzerverhaltens verwendet werden.
            </p>{' '}
            <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>{' '}
            <p>
              Sie haben jederzeit das Recht, unentgeltlich Auskunft über
              Herkunft, Empfänger und Zweck Ihrer gespeicherten
              personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht,
              die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie
              eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie
              diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem
              haben Sie das Recht, unter bestimmten Umständen die Einschränkung
              der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des
              Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen
              Aufsichtsbehörde zu.
            </p>{' '}
            <p>
              Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
              sich jederzeit an uns wenden.
            </p>
            <h3>Analyse-Tools und Tools von Dritt­anbietern</h3>{' '}
            <p>
              Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
              ausgewertet werden. Das geschieht vor allem mit sogenannten
              Analyseprogrammen.
            </p>{' '}
            <p>
              Detaillierte Informationen zu diesen Analyseprogrammen finden Sie
              in der folgenden Datenschutzerklärung.
            </p>
            <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
            <h3>Externes Hosting</h3>{' '}
            <p>
              Diese Website wird bei einem externen Dienstleister gehostet
              (Hoster). Die personenbezogenen Daten, die auf dieser Website
              erfasst werden, werden auf den Servern des Hosters gespeichert.
              Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta-
              und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
              Websitezugriffe und sonstige Daten, die über eine Website
              generiert werden, handeln.
            </p>{' '}
            <p>
              Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
              gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs.
              1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
              effizienten Bereitstellung unseres Online-Angebots durch einen
              professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
            </p>{' '}
            <p>
              Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies
              zur Erfüllung seiner Leistungspflichten erforderlich ist und
              unsere Weisungen in Bezug auf diese Daten befolgen.
            </p>{' '}
            <p>Wir setzen folgenden Hoster ein:</p>
            <p>
              ALL-INKL.COM – Neue Medien Münnich
              <br />
              Inhaber: René Münnich
              <br />
              Hauptstraße 68
              <br />
              D-02742 Friedersdorf
              <br />
              <br />
              Telefon:+49 35872 353-10
              <br />
              Telefax:+49 35872 353-30
              <br />
              E-Mail: info@all-inkl.com
              <br />
              <br />
              Umsatzsteuer-ID: DE 212657916
              <br />
              Steuernummer: 208/251/03091
            </p>
            <h4>Abschluss eines Vertrages über Auftragsverarbeitung</h4>{' '}
            <p>
              Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben
              wir einen Vertrag über Auftragsverarbeitung mit unserem Hoster
              geschlossen.
            </p>
            <h3>Google Cloud CDN</h3>{' '}
            <p>
              Wir nutzen das Content Delivery Network Google Cloud CDN. Anbieter
              ist die Google Ireland Limited („Google“), Gordon House, Barrow
              Street, Dublin 4, Irland.
            </p>{' '}
            <p>
              Google bietet ein weltweit verteiltes Content Delivery Network an.
              Dabei wird technisch der Informationstransfer zwischen Ihrem
              Browser und unserer Website über das Netzwerk von Google geleitet.
              Hierdurch können wir die weltweite Erreichbarkeit und die
              Leistungsfähigkeit unserer Website erhöhen.
            </p>{' '}
            <p>
              Der Einsatz von Google Cloud CDN beruht auf unserem berechtigten
              Interesse an einer möglichst fehlerfreien und sicheren
              Bereitstellung unseres Webangebotes (Art. 6 Abs. 1 lit. f DSGVO).
            </p>{' '}
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:{' '}
              <a
                href="https://cloud.google.com/terms/eu-model-contract-clause"
                target="_blank"
                rel="noopener noreferrer">
                https://cloud.google.com/terms/eu-model-contract-clause
              </a>
              .
            </p>{' '}
            <p>
              Weitere Informationen zu Google Cloud CDN finden Sie hier:{' '}
              <a
                href="https://cloud.google.com/cdn/docs/overview?hl=de"
                target="_blank"
                rel="noopener noreferrer">
                https://cloud.google.com/cdn/docs/overview?hl=de
              </a>
              .
            </p>
            <h4>Abschluss eines Vertrages über Auftragsverarbeitung</h4>{' '}
            <p>
              Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben
              wir einen Vertrag über Auftragsverarbeitung mit Google über den
              Einsatz von Google Cloudflare CDN geschlossen.
            </p>
            <h2>3. Allgemeine Hinweise und Pflicht­informationen</h2>
            <h3>Datenschutz</h3>{' '}
            <p>
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
              Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
              vertraulich und entsprechend der gesetzlichen
              Datenschutzvorschriften sowie dieser Datenschutzerklärung.
            </p>{' '}
            <p>
              Wenn Sie diese Website benutzen, werden verschiedene
              personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
              mit denen Sie persönlich identifiziert werden können. Die
              vorliegende Datenschutzerklärung erläutert, welche Daten wir
              erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu
              welchem Zweck das geschieht.
            </p>{' '}
            <p>
              Wir weisen darauf hin, dass die Datenübertragung im Internet
              (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken
              aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
              durch Dritte ist nicht möglich.
            </p>
            <h3>Hinweis zur verantwortlichen Stelle</h3>{' '}
            <p>
              Die verantwortliche Stelle für die Datenverarbeitung auf dieser
              Website ist:
            </p>{' '}
            <p>
              {process.env.REACT_APP_COMPANY_NAME}
              <br />
              {process.env.REACT_APP_COMPANY_STREET}
              <br />
              {process.env.REACT_APP_COMPANY_ZIPCODE}{' '}
              {process.env.REACT_APP_COMPANY_CITY}
              <br />
              E-Mail: {process.env.REACT_APP_COMPANY_EMAIL}
            </p>
            <p>
              Verantwortliche Stelle ist die natürliche oder juristische Person,
              die allein oder gemeinsam mit anderen über die Zwecke und Mittel
              der Verarbeitung von personenbezogenen Daten (z. B. Namen,
              E-Mail-Adressen o. Ä.) entscheidet.
            </p>
            <p style={{ fontWeight: 600 }}>
              Der Datenschutzbeauftragte des Verantwortlichen ist:
            </p>
            <p>
              <div style={{ fontWeight: 600, textDecoration: 'underline' }}>
                Kontaktdaten des Datenschutzbeauftragten:
              </div>
              PROLIANCE GmbH
              <br />
              www.datenschutzexperte.de
              <br />
              Leopoldstr. 21
              <br />
              80802 München
              <br />
              datenschutzbeauftragter@datenschutzexperte.de
            </p>
            <h3>Speicherdauer</h3>{' '}
            <p>
              Soweit innerhalb dieser Datenschutzerklärung keine speziellere
              Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen
              Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt.
              Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine
              Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
              gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für
              die Speicherung Ihrer personenbezogenen Daten haben (z.B. steuer-
              oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten
              Fall erfolgt die Löschung nach Fortfall dieser Gründe.
            </p>
            <h3>
              Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
            </h3>{' '}
            <p>
              Auf unserer Website sind unter anderem Tools von Unternehmen mit
              Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren
              Drittstaaten eingebunden. Wenn diese Tools aktiv sind, können Ihre
              personenbezogene Daten in diese Drittstaaten übertragen und dort
              verarbeitet werden. Wir weisen darauf hin, dass in diesen Ländern
              kein mit der EU vergleichbares Datenschutzniveau garantiert werden
              kann. Beispielsweise sind US-Unternehmen dazu verpflichtet,
              personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne
              dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten.
              Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z.B.
              Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
              Überwachungszwecken verarbeiten, auswerten und dauerhaft
              speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen
              Einfluss.
            </p>
            <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>{' '}
            <p>
              Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
              Einwilligung möglich. Sie können eine bereits erteilte
              Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum
              Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
              unberührt.
            </p>
            <h3>
              Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen
              sowie gegen Direktwerbung (Art. 21 DSGVO)
            </h3>{' '}
            <p>
              WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
              ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
              DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
              VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN;
              DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
              PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE
              VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG.
              WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
              PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR
              KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
              NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN
              ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER
              VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1
              DSGVO).
            </p>{' '}
            <p>
              WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG
              ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN
              DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM
              ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS
              PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG
              STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
              ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET
              (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
            </p>
            <h3>Beschwerde­recht bei der zuständigen Aufsichts­behörde</h3>{' '}
            <p>
              Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
              Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
              Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
              oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
              besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
              gerichtlicher Rechtsbehelfe.
            </p>
            <h3>Recht auf Daten­übertrag­barkeit</h3>{' '}
            <p>
              Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
              Einwilligung oder in Erfüllung eines Vertrags automatisiert
              verarbeiten, an sich oder an einen Dritten in einem gängigen,
              maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
              direkte Übertragung der Daten an einen anderen Verantwortlichen
              verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            </p>
            <h3>SSL- bzw. TLS-Verschlüsselung</h3>{' '}
            <p>
              Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
              Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
              oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
              SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
              erkennen Sie daran, dass die Adresszeile des Browsers von
              „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in
              Ihrer Browserzeile.
            </p>{' '}
            <p>
              Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
              Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
              werden.
            </p>
            <h3>Auskunft, Löschung und Berichtigung</h3>{' '}
            <p>
              Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
              jederzeit das Recht auf unentgeltliche Auskunft über Ihre
              gespeicherten personenbezogenen Daten, deren Herkunft und
              Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
              auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
              weiteren Fragen zum Thema personenbezogene Daten können Sie sich
              jederzeit an uns wenden.
            </p>
            <h3>Recht auf Einschränkung der Verarbeitung</h3>{' '}
            <p>
              Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen. Hierzu können Sie sich
              jederzeit an uns wenden. Das Recht auf Einschränkung der
              Verarbeitung besteht in folgenden Fällen:
            </p>{' '}
            <ul>
              {' '}
              <li>
                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                personenbezogenen Daten bestreiten, benötigen wir in der Regel
                Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
                das Recht, die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen.
              </li>{' '}
              <li>
                Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
                geschah/geschieht, können Sie statt der Löschung die
                Einschränkung der Datenverarbeitung verlangen.
              </li>{' '}
              <li>
                Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
                sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
                Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                Löschung die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen.
              </li>{' '}
              <li>
                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                haben, muss eine Abwägung zwischen Ihren und unseren Interessen
                vorgenommen werden. Solange noch nicht feststeht, wessen
                Interessen überwiegen, haben Sie das Recht, die Einschränkung
                der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </li>{' '}
            </ul>{' '}
            <p>
              Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
              eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
              abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
              der Rechte einer anderen natürlichen oder juristischen Person oder
              aus Gründen eines wichtigen öffentlichen Interesses der
              Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
            </p>
            <h3>Widerspruch gegen Werbe-E-Mails</h3>{' '}
            <p>
              Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
              Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter
              Werbung und Informationsmaterialien wird hiermit widersprochen.
              Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
              Schritte im Falle der unverlangten Zusendung von
              Werbeinformationen, etwa durch Spam-E-Mails, vor.
            </p>
            <h2>4. Datenerfassung auf dieser Website</h2>
            <h3>Cookies</h3>{' '}
            <p>
              Unsere Internetseiten verwenden so genannte „Cookies“. Cookies
              sind kleine Textdateien und richten auf Ihrem Endgerät keinen
              Schaden an. Sie werden entweder vorübergehend für die Dauer einer
              Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf
              Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres
              Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem
              Endgerät gespeichert, bis Sie diese selbst löschen oder eine
              automatische Löschung durch Ihren Webbrowser erfolgt.
            </p>{' '}
            <p>
              Teilweise können auch Cookies von Drittunternehmen auf Ihrem
              Endgerät gespeichert werden, wenn Sie unsere Seite betreten
              (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die
              Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B.
              Cookies zur Abwicklung von Zahlungsdienstleistungen).
            </p>{' '}
            <p>
              Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
              technisch notwendig, da bestimmte Websitefunktionen ohne diese
              nicht funktionieren würden (z.B. die Warenkorbfunktion oder die
              Anzeige von Videos). Andere Cookies dienen dazu, das
              Nutzerverhalten auszuwerten oder Werbung anzuzeigen.
            </p>{' '}
            <p>
              Cookies, die zur Durchführung des elektronischen
              Kommunikationsvorgangs (notwendige Cookies) oder zur
              Bereitstellung bestimmter, von Ihnen erwünschter Funktionen
              (funktionale Cookies, z. B. für die Warenkorbfunktion) oder zur
              Optimierung der Website (z.B. Cookies zur Messung des
              Webpublikums) erforderlich sind, werden auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
              Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
              berechtigtes Interesse an der Speicherung von Cookies zur
              technisch fehlerfreien und optimierten Bereitstellung seiner
              Dienste. Sofern eine Einwilligung zur Speicherung von Cookies
              abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies
              ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1
              lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.
            </p>{' '}
            <p>
              Sie können Ihren Browser so einstellen, dass Sie über das Setzen
              von Cookies informiert werden und Cookies nur im Einzelfall
              erlauben, die Annahme von Cookies für bestimmte Fälle oder
              generell ausschließen sowie das automatische Löschen der Cookies
              beim Schließen des Browsers aktivieren. Bei der Deaktivierung von
              Cookies kann die Funktionalität dieser Website eingeschränkt sein.
            </p>{' '}
            <p>
              Soweit Cookies von Drittunternehmen oder zu Analysezwecken
              eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
              Datenschutzerklärung gesondert informieren und ggf. eine
              Einwilligung abfragen.
            </p>
            <h3>Cookie-Einwilligung mit Cookiebot</h3>{' '}
            <p>
              Unsere Website nutzt die Cookie-Consent-Technologie von Cookiebot,
              um Ihre Einwilligung zur Speicherung bestimmter Cookies auf Ihrem
              Endgerät einzuholen und diese datenschutzkonform zu dokumentieren.
              Anbieter dieser Technologie ist Cybot A/S, Havnegade 39, 1058
              Kopenhagen, Dänemark (im Folgenden „Cookiebot“).
            </p>{' '}
            <p>
              Wenn Sie unsere Website betreten, wird eine Verbindung zu den
              Servern von Cookiebot hergestellt, um Ihre Einwilligungen und
              sonstigen Erklärungen zur Cookie-Nutzung einzuholen. Anschließend
              speichert Cookiebot einen Cookie in Ihrem Browser, um Ihnen die
              erteilten Einwilligungen bzw. deren Widerruf zuordnen zu können.
              Die so erfassten Daten werden gespeichert, bis Sie uns zur
              Löschung auffordern, den Cookiebot-Cookie selbst löschen oder der
              Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche
              Aufbewahrungspflichten bleiben unberührt.
            </p>{' '}
            <p>
              Der Einsatz von Cookiebot erfolgt, um die gesetzlich
              vorgeschriebenen Einwilligungen für den Einsatz von Cookies
              einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c
              DSGVO.
            </p>
            <h4>Vertrag über Auftragsverarbeitung</h4>{' '}
            <p>
              Wir haben einen Vertrag über Auftragsverarbeitung mit Cookiebot
              geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich
              vorgeschriebenen Vertrag, der gewährleistet, dass Cookiebot die
              personenbezogenen Daten unserer Websitebesucher nur nach unseren
              Weisungen und unter Einhaltung der DSGVO verarbeitet.
            </p>
            <div id="consentSciptTarget"></div>
            <h3>Server-Log-Dateien</h3>{' '}
            <p>
              Der Provider der Seiten erhebt und speichert automatisch
              Informationen in so genannten Server-Log-Dateien, die Ihr Browser
              automatisch an uns übermittelt. Dies sind:
            </p>{' '}
            <ul>
              {' '}
              <li>Browsertyp und Browserversion</li>{' '}
              <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>{' '}
              <li>Hostname des zugreifenden Rechners</li>{' '}
              <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{' '}
            </ul>{' '}
            <p>
              Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
              nicht vorgenommen.
            </p>{' '}
            <p>
              Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an der technisch fehlerfreien Darstellung und der Optimierung
              seiner Website – hierzu müssen die Server-Log-Files erfasst
              werden.
            </p>
            <h3>Kontaktformular</h3>{' '}
            <p>
              Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
              Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
              angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für
              den Fall von Anschlussfragen bei uns gespeichert. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
            </p>{' '}
            <p>
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
              Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
              Verarbeitung auf unserem berechtigten Interesse an der effektiven
              Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
              DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
              sofern diese abgefragt wurde.
            </p>{' '}
            <p>
              Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
              uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
              Speicherung widerrufen oder der Zweck für die Datenspeicherung
              entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
              Zwingende gesetzliche Bestimmungen – insbesondere
              Aufbewahrungsfristen – bleiben unberührt.
            </p>
            <h3>Anfrage per E-Mail, Telefon oder Telefax</h3>{' '}
            <p>
              Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
              Ihre Anfrage inklusive aller daraus hervorgehenden
              personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung
              Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
            </p>{' '}
            <p>
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
              Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
              Verarbeitung auf unserem berechtigten Interesse an der effektiven
              Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
              DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
              sofern diese abgefragt wurde.
            </p>{' '}
            <p>
              Die von Ihnen an uns per Kontaktanfragen übersandten Daten
              verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
              Einwilligung zur Speicherung widerrufen oder der Zweck für die
              Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
              Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
              insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
            </p>
            <h3>Calendly</h3>{' '}
            <p>
              Auf unserer Website haben Sie die Möglichkeit, Termine mit uns zu
              vereinbaren. Für die Terminbuchung nutzen wir das Tool „Calendly“.
              Anbieter ist die Calendly LLC, 271 17th St NW, 10th Floor,
              Atlanta, Georgia 30363, USA (nachfolgend „Calendly“).
            </p>{' '}
            <p>
              Zum Zweck der Terminbuchung geben Sie die abgefragten Daten und
              den Wunschtermin in die dafür vorgesehene Maske ein. Die
              eingegebenen Daten werden für die Planung, Durchführung und ggf.
              für die Nachbereitung des Termins verwendet. Die Termindaten
              werden für uns auf den Servern von Calendly gespeichert, dessen
              Datenschutzerklärung Sie hier einsehen können:{' '}
              <a
                href="https://calendly.com/de/pages/privacy"
                target="_blank"
                rel="noopener noreferrer">
                https://calendly.com/de/pages/privacy
              </a>
              .
            </p>{' '}
            <p>
              Die von Ihnen eingegebenen Daten verbleiben bei uns, bis Sie uns
              zur Löschung auffordern, Ihre Einwilligung zur Speicherung
              widerrufen oder der Zweck für die Datenspeicherung entfällt.
              Zwingende gesetzliche Bestimmungen – insbesondere
              Aufbewahrungsfristen – bleiben unberührt.
            </p>{' '}
            <p>
              Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f
              DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an
              einer möglichst unkomplizierten Terminvereinbarung mit
              Interessenten und Kunden. Sofern eine Einwilligung abgefragt
              wurde, ist Art. 6 Abs. 1 lit. a DSGVO die Rechtsgrundlage für die
              Datenverarbeitung; die Einwilligung ist jederzeit widerrufbar.
            </p>{' '}
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:{' '}
              <a
                href="https://calendly.com/pages/dpa"
                target="_blank"
                rel="noopener noreferrer">
                https://calendly.com/pages/dpa
              </a>
              .
            </p>
            <h4>Vertrag über Auftragsverarbeitung</h4>{' '}
            <p>
              Wir haben einen Vertrag über Auftragsverarbeitung mit Calendly
              geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich
              vorgeschriebenen Vertrag, der gewährleistet, dass Calendly die
              personenbezogenen Daten unserer Websitebesucher nur nach unseren
              Weisungen und unter Einhaltung der DSGVO verarbeitet.
            </p>
            <h3>Hubspot CRM</h3>{' '}
            <p>
              Wir nutzen Hubspot CRM auf dieser Website. Anbieter ist Hubspot
              Inc. 25 Street, Cambridge, MA 02141 USA (nachfolgend Hubspot CRM).
            </p>{' '}
            <p>
              Hubspot CRM ermöglicht es uns unter anderem, bestehende und
              potenzielle Kunden sowie Kundenkontakte zu verwalten. Mit Hilfe
              von Hubspot CRM sind wir in der Lage, Kundeninteraktionen per
              E-Mail, Social Media oder Telefon über verschiedene Kanäle hinweg
              zu erfassen, zu sortieren und zu analysieren. Die so erfassten
              personenbezogenen Daten können ausgewertet und für die
              Kommunikation mit dem potenziellen Kunden oder für
              Marketingmaßnahmen (z.B. Newslettermailings) verwendet werden. Mit
              Hubspot CRM sind wir ferner in der Lage, das Nutzerverhalten
              unserer Kontakte auf unserer Website zu erfassen und zu
              analysieren.
            </p>{' '}
            <p>
              Die Verwendung von Hubspot CRM erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an einer möglichst effizienten Kundenverwaltung und
              Kundenkommunikation. Sofern eine entsprechende Einwilligung
              abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
              Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
              jederzeit widerrufbar.
            </p>{' '}
            <p>
              Details entnehmen Sie der Datenschutzerklärung von Hubspot:{' '}
              <a
                href="https://legal.hubspot.com/de/privacy-policy"
                target="_blank"
                rel="noopener noreferrer">
                https://legal.hubspot.com/de/privacy-policy
              </a>
              .
            </p>{' '}
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:{' '}
              <a
                href="https://www.hubspot.de/data-privacy/privacy-shield"
                target="_blank"
                rel="noopener noreferrer">
                https://www.hubspot.de/data-privacy/privacy-shield
              </a>
              .
            </p>
            <h4>Vertrag über Auftragsverarbeitung</h4>{' '}
            <p>
              Wir haben einen Vertrag über Auftragsverarbeitung mit Hubspot CRM
              geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich
              vorgeschriebenen Vertrag, der gewährleistet, dass Hubspot CRM die
              personenbezogenen Daten unserer Websitebesucher nur nach unseren
              Weisungen und unter Einhaltung der DSGVO verarbeitet.
            </p>
            <h3>ProvenExpert</h3>{' '}
            <p>
              Wir haben Bewertungssiegel von ProvenExpert auf dieser Website
              eingebunden. Anbieter ist Expert Systems AG, Quedlinburger Str. 1,
              10589 Berlin,{' '}
              <a
                href="https://www.provenexpert.com"
                target="_blank"
                rel="noopener noreferrer">
                https://www.provenexpert.com
              </a>
              .
            </p>{' '}
            <p>
              Das ProvenExpert-Siegel ermöglicht es uns, Kundenbewertungen, die
              bei ProvenExpert zu unserem Unternehmen abgegeben wurden, auf
              unserer Website in einem Siegel darzustellen. Wenn Sie unsere
              Website besuchen, wird eine Verbindung mit ProvenExpert
              hergestellt, sodass ProvenExpert feststellen kann, dass Sie unsere
              Website besucht haben. Ferner erfasst ProvenExpert Ihre
              Spracheinstellungen, um das Siegel in der gewählten Landessprache
              anzuzeigen.
            </p>{' '}
            <p>
              Die Verwendung von ProvenExpert erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an einer möglichst nachvollziehbaren Darstellung von
              Kundenbewertungen. Sofern eine entsprechende Einwilligung
              abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
              Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
              jederzeit widerrufbar.
            </p>
            <h2>5. Soziale Medien</h2>
            <h3>Facebook Plugins (Like & Share-Button)</h3>{' '}
            <p>
              Auf dieser Website sind Plugins des sozialen Netzwerks Facebook
              integriert. Anbieter dieses Dienstes ist die Facebook Ireland
              Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten
              Daten werden nach Aussage von Facebook jedoch auch in die USA und
              in andere Drittländer übertragen.
            </p>{' '}
            <p>
              Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem
              „Like-Button“ („Gefällt mir“) auf dieser Website. Eine Übersicht
              über die Facebook Plugins finden Sie hier:{' '}
              <a
                href="https://developers.facebook.com/docs/plugins/?locale=de_DE"
                target="_blank"
                rel="noopener noreferrer">
                https://developers.facebook.com/docs/plugins/?locale=de_DE
              </a>
              .
            </p>{' '}
            <p>
              Wenn Sie diese Website besuchen, wird über das Plugin eine direkte
              Verbindung zwischen Ihrem Browser und dem Facebook-Server
              hergestellt. Facebook erhält dadurch die Information, dass Sie mit
              Ihrer IP-Adresse diese Website besucht haben. Wenn Sie den
              Facebook „Like-Button“ anklicken, während Sie in Ihrem
              Facebook-Account eingeloggt sind, können Sie die Inhalte dieser
              Website auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook
              den Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen
              darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
              Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook
              erhalten. Weitere Informationen hierzu finden Sie in der
              Datenschutzerklärung von Facebook unter:{' '}
              <a
                href="https://de-de.facebook.com/privacy/explanation"
                target="_blank"
                rel="noopener noreferrer">
                https://de-de.facebook.com/privacy/explanation
              </a>
              .
            </p>{' '}
            <p>
              Wenn Sie nicht wünschen, dass Facebook den Besuch dieser Website
              Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte
              aus Ihrem Facebook-Benutzerkonto aus.
            </p>{' '}
            <p>
              Die Verwendung der Facebook Plugins erfolgt auf Grundlage von Art.
              6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an einer möglichst umfangreichen Sichtbarkeit in den
              Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
              wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von
              Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
              widerrufbar.
            </p>{' '}
            <p>
              Soweit mit Hilfe des hier beschriebenen Tools personenbezogene
              Daten auf unserer Website erfasst und an Facebook weitergeleitet
              werden, sind wir und die Facebook Ireland Limited, 4 Grand Canal
              Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam für diese
              Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame
              Verantwortlichkeit beschränkt sich dabei ausschließlich auf die
              Erfassung der Daten und deren Weitergabe an Facebook. Die nach der
              Weiterleitung erfolgende Verarbeitung durch Facebook ist nicht
              Teil der gemeinsamen Verantwortung. Die uns gemeinsam obliegenden
              Verpflichtungen wurden in einer Vereinbarung über gemeinsame
              Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden
              Sie unter:{' '}
              <a
                href="https://www.facebook.com/legal/controller_addendum"
                target="_blank"
                rel="noopener noreferrer">
                https://www.facebook.com/legal/controller_addendum
              </a>
              . Laut dieser Vereinbarung sind wir für die Erteilung der
              Datenschutzinformationen beim Einsatz des Facebook-Tools und für
              die datenschutzrechtlich sichere Implementierung des Tools auf
              unserer Website verantwortlich. Für die Datensicherheit der
              Facebook-Produkte ist Facebook verantwortlich. Betroffenenrechte
              (z.B. Auskunftsersuchen) hinsichtlich der bei Facebook
              verarbeiteten Daten können Sie direkt bei Facebook geltend machen.
              Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir
              verpflichtet, diese an Facebook weiterzuleiten.
            </p>{' '}
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:{' '}
              <a
                href="https://www.facebook.com/legal/EU_data_transfer_addendum"
                target="_blank"
                rel="noopener noreferrer">
                https://www.facebook.com/legal/EU_data_transfer_addendum
              </a>
              ,{' '}
              <a
                href="https://de-de.facebook.com/help/566994660333381"
                target="_blank"
                rel="noopener noreferrer">
                https://de-de.facebook.com/help/566994660333381
              </a>{' '}
              und{' '}
              <a
                href="https://www.facebook.com/policy.php"
                target="_blank"
                rel="noopener noreferrer">
                https://www.facebook.com/policy.php
              </a>
              .
            </p>
            <h3>Instagram Plugin</h3>{' '}
            <p>
              Auf dieser Website sind Funktionen des Dienstes Instagram
              eingebunden. Diese Funktionen werden angeboten durch die Facebook
              Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin
              2, Irland integriert.
            </p>{' '}
            <p>
              Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie
              durch Anklicken des Instagram-Buttons die Inhalte dieser Website
              mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den
              Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen
              darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
              Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram
              erhalten.
            </p>{' '}
            <p>
              Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
              Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
              berechtigtes Interesse an einer möglichst umfangreichen
              Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende
              Einwilligung abgefragt wurde, erfolgt die Verarbeitung
              ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
              Einwilligung ist jederzeit widerrufbar.
            </p>{' '}
            <p>
              Soweit mit Hilfe des hier beschriebenen Tools personenbezogene
              Daten auf unserer Website erfasst und an Facebook bzw. Instagram
              weitergeleitet werden, sind wir und die Facebook Ireland Limited,
              4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
              gemeinsam für diese Datenverarbeitung verantwortlich (Art. 26
              DSGVO). Die gemeinsame Verantwortlichkeit beschränkt sich dabei
              ausschließlich auf die Erfassung der Daten und deren Weitergabe an
              Facebook bzw. Instagram. Die nach der Weiterleitung erfolgende
              Verarbeitung durch Facebook bzw. Instagram ist nicht Teil der
              gemeinsamen Verantwortung. Die uns gemeinsam obliegenden
              Verpflichtungen wurden in einer Vereinbarung über gemeinsame
              Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden
              Sie unter:{' '}
              <a
                href="https://www.facebook.com/legal/controller_addendum"
                target="_blank"
                rel="noopener noreferrer">
                https://www.facebook.com/legal/controller_addendum
              </a>
              . Laut dieser Vereinbarung sind wir für die Erteilung der
              Datenschutzinformationen beim Einsatz des Facebook- bzw.
              Instagram-Tools und für die datenschutzrechtlich sichere
              Implementierung des Tools auf unserer Website verantwortlich. Für
              die Datensicherheit der Facebook bzw. Instagram-Produkte ist
              Facebook verantwortlich. Betroffenenrechte (z.B.
              Auskunftsersuchen) hinsichtlich der bei Facebook bzw. Instagram
              verarbeiteten Daten können Sie direkt bei Facebook geltend machen.
              Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir
              verpflichtet, diese an Facebook weiterzuleiten.
            </p>{' '}
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:{' '}
              <a
                href="https://www.facebook.com/legal/EU_data_transfer_addendum"
                target="_blank"
                rel="noopener noreferrer">
                https://www.facebook.com/legal/EU_data_transfer_addendum
              </a>
              ,{' '}
              <a
                href="https://help.instagram.com/519522125107875"
                target="_blank"
                rel="noopener noreferrer">
                https://help.instagram.com/519522125107875
              </a>{' '}
              und{' '}
              <a
                href="https://de-de.facebook.com/help/566994660333381"
                target="_blank"
                rel="noopener noreferrer">
                https://de-de.facebook.com/help/566994660333381
              </a>
              .
            </p>{' '}
            <p>
              Weitere Informationen hierzu finden Sie in der
              Datenschutzerklärung von Instagram:{' '}
              <a
                href="https://instagram.com/about/legal/privacy/"
                target="_blank"
                rel="noopener noreferrer">
                https://instagram.com/about/legal/privacy/
              </a>
              .
            </p>
            <h3>LinkedIn Plugin</h3>{' '}
            <p>
              Diese Website nutzt Funktionen des Netzwerks LinkedIn. Anbieter
              ist die LinkedIn Ireland Unlimited Company, Wilton Plaza, Wilton
              Place, Dublin 2, Irland.
            </p>{' '}
            <p>
              Bei jedem Abruf einer Seite dieser Website, die Funktionen von
              LinkedIn enthält, wird eine Verbindung zu Servern von LinkedIn
              aufgebaut. LinkedIn wird darüber informiert, dass Sie diese
              Website mit Ihrer IP-Adresse besucht haben. Wenn Sie den
              „Recommend-Button“ von LinkedIn anklicken und in Ihrem Account bei
              LinkedIn eingeloggt sind, ist es LinkedIn möglich, Ihren Besuch
              auf dieser Website Ihnen und Ihrem Benutzerkonto zuzuordnen. Wir
              weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis
              vom Inhalt der übermittelten Daten sowie deren Nutzung durch
              LinkedIn haben.
            </p>{' '}
            <p>
              Die Verwendung des LinkedIn-Plugins erfolgt auf Grundlage von Art.
              6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an einer möglichst umfangreichen Sichtbarkeit in den
              Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
              wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von
              Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
              widerrufbar.
            </p>{' '}
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:{' '}
              <a
                href="https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=de"
                target="_blank"
                rel="noopener noreferrer">
                https://www.linkedin.com/help/linkedin/answer/62538/datenubertragung-aus-der-eu-dem-ewr-und-der-schweiz?lang=de
              </a>
            </p>{' '}
            <p>
              Weitere Informationen hierzu finden Sie in der
              Datenschutzerklärung von LinkedIn unter:{' '}
              <a
                href="https://www.linkedin.com/legal/privacy-policy"
                target="_blank"
                rel="noopener noreferrer">
                https://www.linkedin.com/legal/privacy-policy
              </a>
              .
            </p>
            <h3>XING Plugin</h3>{' '}
            <p>
              Diese Website nutzt Funktionen des Netzwerks XING. Anbieter ist
              die New Work SE, Dammtorstraße 30, 20354 Hamburg, Deutschland.
            </p>{' '}
            <p>
              Bei jedem Abruf einer unserer Seiten, die Funktionen von XING
              enthält, wird eine Verbindung zu Servern von XING hergestellt.
              Eine Speicherung von personenbezogenen Daten erfolgt dabei nach
              unserer Kenntnis nicht. Insbesondere werden keine IP-Adressen
              gespeichert oder das Nutzungsverhalten ausgewertet.
            </p>{' '}
            <p>
              Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
              Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
              berechtigtes Interesse an einer möglichst umfangreichen
              Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende
              Einwilligung abgefragt wurde, erfolgt die Verarbeitung
              ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
              Einwilligung ist jederzeit widerrufbar.
            </p>{' '}
            <p>
              Weitere Information zum Datenschutz und dem XING Share-Button
              finden Sie in der Datenschutzerklärung von XING unter:{' '}
              <a
                href="https://www.xing.com/app/share?op=data_protection"
                target="_blank"
                rel="noopener noreferrer">
                https://www.xing.com/app/share?op=data_protection
              </a>
              .
            </p>
            <h2>6. Analyse-Tools und Werbung</h2>
            <h3>Google Tag Manager</h3>{' '}
            <p>
              Wir setzen den Google Tag Manager ein. Anbieter ist die Google
              Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland.
            </p>{' '}
            <p>
              Der Google Tag Manager ist ein Tool, mit dessen Hilfe wir
              Tracking- oder Statistik-Tools und andere Technologien auf unserer
              Website einbinden können. Der Google Tag Manager selbst erstellt
              keine Nutzerprofile, speichert keine Cookies und nimmt keine
              eigenständigen Analysen vor. Er dient lediglich der Verwaltung und
              Ausspielung der über ihn eingebundenen Tools. Der Google Tag
              Manager erfasst jedoch Ihre IP-Adresse, die auch an das
              Mutterunternehmen von Google in die Vereinigten Staaten übertragen
              werden kann.
            </p>{' '}
            <p>
              Der Einsatz des Google Tag Managers erfolgt auf Grundlage von Art.
              6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an einer schnellen und unkomplizierten Einbindung und
              Verwaltung verschiedener Tools auf seiner Website. Sofern eine
              entsprechende Einwilligung abgefragt wurde, erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>
            <h3>Google Analytics</h3>{' '}
            <p>
              Diese Website nutzt Funktionen des Webanalysedienstes Google
              Analytics. Anbieter ist die Google Ireland Limited („Google“),
              Gordon House, Barrow Street, Dublin 4, Irland.
            </p>{' '}
            <p>
              Google Analytics ermöglicht es dem Websitebetreiber, das Verhalten
              der Websitebesucher zu analysieren. Hierbei erhält der
              Websitebetreiber verschiedene Nutzungsdaten, wie z.B.
              Seitenaufrufe, Verweildauer, verwendete Betriebssysteme und
              Herkunft des Nutzers. Diese Daten werden von Google ggf. in einem
              Profil zusammengefasst, das dem jeweiligen Nutzer bzw. dessen
              Endgerät zugeordnet ist.
            </p>{' '}
            <p>
              Google Analytics verwendet Technologien, die die Wiedererkennung
              des Nutzers zum Zwecke der Analyse des Nutzerverhaltens
              ermöglichen (z.B. Cookies oder Device-Fingerprinting). Die von
              Google erfassten Informationen über die Benutzung dieser Website
              werden in der Regel an einen Server von Google in den USA
              übertragen und dort gespeichert.
            </p>{' '}
            <p>
              Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
              Webangebot als auch seine Werbung zu optimieren. Sofern eine
              entsprechende Einwilligung abgefragt wurde (z. B. eine
              Einwilligung zur Speicherung von Cookies), erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>{' '}
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:{' '}
              <a
                href="https://privacy.google.com/businesses/controllerterms/mccs/"
                target="_blank"
                rel="noopener noreferrer">
                https://privacy.google.com/businesses/controllerterms/mccs/
              </a>
              .
            </p>{' '}
            <h4>IP Anonymisierung</h4>{' '}
            <p>
              Wir haben auf dieser Website die Funktion IP-Anonymisierung
              aktiviert. Dadurch wird Ihre IP-Adresse von Google innerhalb von
              Mitgliedstaaten der Europäischen Union oder in anderen
              Vertragsstaaten des Abkommens über den Europäischen
              Wirtschaftsraum vor der Übermittlung in die USA gekürzt. Nur in
              Ausnahmefällen wird die volle IP-Adresse an einen Server von
              Google in den USA übertragen und dort gekürzt. Im Auftrag des
              Betreibers dieser Website wird Google diese Informationen
              benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
              die Websiteaktivitäten zusammenzustellen und um weitere mit der
              Websitenutzung und der Internetnutzung verbundene Dienstleistungen
              gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von
              Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
              nicht mit anderen Daten von Google zusammengeführt.
            </p>
            <h4>Browser Plugin</h4>{' '}
            <p>
              Sie können die Erfassung und Verarbeitung Ihrer Daten durch Google
              verhindern, indem Sie das unter dem folgenden Link verfügbare
              Browser-Plugin herunterladen und installieren:{' '}
              <a
                href="https://tools.google.com/dlpage/gaoptout?hl=de"
                target="_blank"
                rel="noopener noreferrer">
                https://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              .
            </p>{' '}
            <p>
              Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics
              finden Sie in der Datenschutzerklärung von Google:{' '}
              <a
                href="https://support.google.com/analytics/answer/6004245?hl=de"
                target="_blank"
                rel="noopener noreferrer">
                https://support.google.com/analytics/answer/6004245?hl=de
              </a>
              .
            </p>
            <h4>Auftragsverarbeitung</h4>{' '}
            <p>
              Wir haben mit Google einen Vertrag zur Auftragsverarbeitung
              abgeschlossen und setzen die strengen Vorgaben der deutschen
              Datenschutzbehörden bei der Nutzung von Google Analytics
              vollständig um.
            </p>
            <h4>Demografische Merkmale bei Google Analytics</h4>{' '}
            <p>
              Diese Website nutzt die Funktion „demografische Merkmale“ von
              Google Analytics, um den Websitebesuchern passende Werbeanzeigen
              innerhalb des Google-Werbenetzwerks anzeigen zu können. Dadurch
              können Berichte erstellt werden, die Aussagen zu Alter, Geschlecht
              und Interessen der Seitenbesucher enthalten. Diese Daten stammen
              aus interessenbezogener Werbung von Google sowie aus Besucherdaten
              von Drittanbietern. Diese Daten können keiner bestimmten Person
              zugeordnet werden. Sie können diese Funktion jederzeit über die
              Anzeigeneinstellungen in Ihrem Google-Konto deaktivieren oder die
              Erfassung Ihrer Daten durch Google Analytics wie im Punkt
              „Widerspruch gegen Datenerfassung“ dargestellt generell
              untersagen.
            </p>
            <h4>Google Analytics E-Commerce-Tracking</h4>{' '}
            <p>
              Diese Website nutzt die Funktion „E-Commerce-Tracking“ von Google
              Analytics. Mit Hilfe von E-Commerce-Tracking kann der
              Websitebetreiber das Kaufverhalten der Websitebesucher zur
              Verbesserung seiner Online-Marketing-Kampagnen analysieren.
              Hierbei werden Informationen, wie zum Beispiel die getätigten
              Bestellungen, durchschnittliche Bestellwerte, Versandkosten und
              die Zeit von der Ansicht bis zum Kauf eines Produktes erfasst.
              Diese Daten können von Google unter einer Transaktions-ID
              zusammengefasst werden, die dem jeweiligen Nutzer bzw. dessen
              Gerät zugeordnet ist.
            </p>
            <h4>Speicherdauer</h4>{' '}
            <p>
              Bei Google gespeicherte Daten auf Nutzer- und Ereignisebene, die
              mit Cookies, Nutzerkennungen (z. B. User ID) oder Werbe-IDs (z. B.
              DoubleClick-Cookies, Android-Werbe-ID) verknüpft sind, werden nach
              26 Monaten anonymisiert bzw. gelöscht. Details hierzu ersehen Sie
              unter folgendem Link:{' '}
              <a
                href="https://support.google.com/analytics/answer/7667196?hl=de"
                target="_blank"
                rel="noopener noreferrer">
                https://support.google.com/analytics/answer/7667196?hl=de
              </a>
            </p>
            <h3>Hotjar</h3>{' '}
            <p>
              Diese Website nutzt Hotjar. Anbieter ist die Hotjar Ltd., Level 2,
              St Julians Business Centre, 3, Elia Zammit Street, St Julians STJ
              1000, Malta, Europe (Website:{' '}
              <a href="" target="_blank" rel="noopener noreferrer">
                https://www.hotjar.com
              </a>
              ).
            </p>{' '}
            <p>
              Hotjar ist ein Werkzeug zur Analyse Ihres Nutzerverhaltens auf
              dieser Website. Mit Hotjar können wir u. a. Ihre Maus- und
              Scrollbewegungen und Klicks aufzeichnen. Hotjar kann dabei auch
              feststellen, wie lange Sie mit dem Mauszeiger auf einer bestimmten
              Stelle verblieben sind. Aus diesen Informationen erstellt Hotjar
              sogenannte Heatmaps, mit denen sich feststellen lässt, welche
              Websitebereiche vom Websitebesucher bevorzugt angeschaut werden.
            </p>{' '}
            <p>
              Des Weiteren können wir feststellen, wie lange Sie auf einer Seite
              verblieben sind und wann Sie sie verlassen haben. Wir können auch
              feststellen, an welcher Stelle Sie Ihre Eingaben in ein
              Kontaktformular abgebrochen haben (sog. Conversion-Funnels).
            </p>{' '}
            <p>
              Darüber hinaus können mit Hotjar direkte Feedbacks von
              Websitebesuchern eingeholt werden. Diese Funktion dient der
              Verbesserung der Webangebote des Websitebetreibers.
            </p>{' '}
            <p>
              Hotjar verwendet Technologien, die die Wiedererkennung des Nutzers
              zum Zwecke der Analyse des Nutzerverhaltens ermöglichen (z.B.
              Cookies oder Einsatz von Device-Fingerprinting).
            </p>{' '}
            <p>
              Die Nutzung dieses Analysetools erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an der Analyse des Nutzerverhaltens, um sowohl sein
              Webangebot als auch seine Werbung zu optimieren. Sofern eine
              entsprechende Einwilligung abgefragt wurde (z. B. eine
              Einwilligung zur Speicherung von Cookies), erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>{' '}
            <h4>Deaktivieren von Hotjar</h4>{' '}
            <p>
              Wenn Sie die Datenerfassung durch Hotjar deaktivieren möchten,
              klicken Sie auf folgenden Link und folgen Sie den dortigen
              Anweisungen:{' '}
              <a
                href="https://www.hotjar.com/opt-out"
                target="_blank"
                rel="noopener noreferrer">
                https://www.hotjar.com/opt-out
              </a>
            </p>{' '}
            <p>
              Bitte beachten Sie, dass die Deaktivierung von Hotjar für jeden
              Browser bzw. für jedes Endgerät separat erfolgen muss.
            </p>{' '}
            <p>
              Nähere Informationen über Hotjar und zu den erfassten Daten
              entnehmen Sie der Datenschutzerklärung von Hotjar unter dem
              folgenden Link:{' '}
              <a
                href="https://www.hotjar.com/privacy"
                target="_blank"
                rel="noopener noreferrer">
                https://www.hotjar.com/privacy
              </a>
            </p>
            <h3>Google Ads</h3>{' '}
            <p>
              Der Websitebetreiber verwendet Google Ads. Google Ads ist ein
              Online-Werbeprogramm der Google Ireland Limited („Google“), Gordon
              House, Barrow Street, Dublin 4, Irland.
            </p>{' '}
            <p>
              Google Ads ermöglicht es uns Werbeanzeigen in der
              Google-Suchmaschine oder auf Drittwebseiten auszuspielen, wenn der
              Nutzer bestimmte Suchbegriffe bei Google eingibt
              (Keyword-Targeting). Ferner können zielgerichtete Werbeanzeigen
              anhand der bei Google vorhandenen Nutzerdaten (z.B. Standortdaten
              und Interessen) ausgespielt werden (Zielgruppen-Targeting). Wir
              als Websitebetreiber können diese Daten quantitativ auswerten,
              indem wir beispielsweise analysieren, welche Suchbegriffe zur
              Ausspielung unserer Werbeanzeigen geführt haben und wie viele
              Anzeigen zu entsprechenden Klicks geführt haben.
            </p>{' '}
            <p>
              Die Nutzung von Google Ads erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an einer möglichst effektiven Vermarktung seiner Dienstleistung
              Produkte.
            </p>{' '}
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:{' '}
              <a
                href="https://policies.google.com/privacy/frameworks"
                target="_blank"
                rel="noopener noreferrer">
                https://policies.google.com/privacy/frameworks
              </a>{' '}
              und{' '}
              <a
                href="https://privacy.google.com/businesses/controllerterms/mccs/"
                target="_blank"
                rel="noopener noreferrer">
                https://privacy.google.com/businesses/controllerterms/mccs/
              </a>
              .
            </p>
            <h3>Google AdSense</h3>{' '}
            <p>
              Diese Website nutzt Google AdSense, einen Dienst zum Einbinden von
              Werbeanzeigen. Anbieter ist die Google Ireland Limited („Google“),
              Gordon House, Barrow Street, Dublin 4, Irland.
            </p>{' '}
            <p>
              Mit Hilfe von Google Adsense können wir zielgerichtete
              Werbeanzeigen von Drittunternehmen auf unserer Seite einblenden
              lassen. Die Inhalte der Werbeanzeigen richten sich hierbei nach
              Ihren Interessen, die Google anhand Ihres bisherigen
              Nutzerverhaltens ermittelt. Des Weiteren werden bei der Auswahl
              der passenden Werbeanzeige auch Kontextinformationen, wie Ihr
              Standort, der Inhalt der besuchten Webseite oder die von Ihnen
              eingegeben Google-Suchbegriffe berücksichtigt.
            </p>{' '}
            <p>
              Google AdSense verwendet Cookies, Web Beacons (unsichtbare
              Grafiken) und vergleichbare Wiedererkennungstechnologien. Hiermit
              können Informationen wie der Besucherverkehr auf diesen Seiten
              ausgewertet werden.
            </p>{' '}
            <p>
              Die von Google Adsense erfassten Informationen über die Benutzung
              dieser Website (einschließlich Ihrer IP-Adresse) und Auslieferung
              von Werbeformaten werden an einen Server von Google in den USA
              übertragen und dort gespeichert. Diese Informationen können von
              Google an Vertragspartner von Google weitergegeben werden. Google
              wird Ihre IP-Adresse jedoch nicht mit anderen von Ihnen
              gespeicherten Daten zusammenführen.
            </p>{' '}
            <p>
              Die Nutzung von AdSense erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an einer möglichst effektiven Vermarktung seiner Website. Sofern
              eine entsprechende Einwilligung abgefragt wurde, erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>{' '}
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:{' '}
              <a
                href="https://privacy.google.com/businesses/controllerterms/mccs/"
                target="_blank"
                rel="noopener noreferrer">
                https://privacy.google.com/businesses/controllerterms/mccs/
              </a>
              .
            </p>
            <h3>Google Remarketing</h3>{' '}
            <p>
              Diese Website nutzt die Funktionen von Google Analytics
              Remarketing. Anbieter ist die Google Ireland Limited („Google“),
              Gordon House, Barrow Street, Dublin 4, Irland.
            </p>{' '}
            <p>
              Google Remarketing analysiert Ihr Nutzerverhalten auf unserer
              Website (z.B. Klick auf bestimmte Produkte), um Sie in bestimmte
              Werbe-Zielgruppen einzuordnen und Ihnen anschließend beim Besuch
              von anderen Onlineangeboten passende Webebotschaften auszuspielen
              (Remarketing bzw. Retargeting).
            </p>{' '}
            <p>
              Des Weiteren können die mit Google Remarketing erstellten
              Werbe-Zielgruppen mit den geräteübergreifenden Funktionen von
              Google verknüpft werden. Auf diese Weise können
              interessenbezogene, personalisierte Werbebotschaften, die in
              Abhängigkeit Ihres früheren Nutzungs- und Surfverhaltens auf einem
              Endgerät (z. B. Handy) an Sie angepasst wurden auch auf einem
              anderen Ihrer Endgeräte (z. B. Tablet oder PC) angezeigt werden.
            </p>{' '}
            <p>
              Wenn Sie über einen Google-Account verfügen, können Sie der
              personalisierten Werbung unter folgendem Link widersprechen:{' '}
              <a
                href="https://www.google.com/settings/ads/onweb/"
                target="_blank"
                rel="noopener noreferrer">
                https://www.google.com/settings/ads/onweb/
              </a>
              .
            </p>{' '}
            <p>
              Die Nutzung von Google Remarketing erfolgt auf Grundlage von Art.
              6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an einer möglichst effektiven Vermarktung seiner
              Produkte. Sofern eine entsprechende Einwilligung abgefragt wurde,
              erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6
              Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>{' '}
            <p>
              Weitergehende Informationen und die Datenschutzbestimmungen finden
              Sie in der Datenschutzerklärung von Google unter:{' '}
              <a
                href="https://policies.google.com/technologies/ads?hl=de"
                target="_blank"
                rel="noopener noreferrer">
                https://policies.google.com/technologies/ads?hl=de
              </a>
              .
            </p>
            <h3>Google Conversion-Tracking</h3>{' '}
            <p>
              Diese Website nutzt Google Conversion Tracking. Anbieter ist die
              Google Ireland Limited („Google“), Gordon House, Barrow Street,
              Dublin 4, Irland.
            </p>{' '}
            <p>
              Mit Hilfe von Google-Conversion-Tracking können Google und wir
              erkennen, ob der Nutzer bestimmte Aktionen durchgeführt hat. So
              können wir beispielsweise auswerten, welche Buttons auf unserer
              Website wie häufig geklickt und welche Produkte besonders häufig
              angesehen oder gekauft wurden. Diese Informationen dienen dazu,
              Conversion-Statistiken zu erstellen. Wir erfahren die Gesamtanzahl
              der Nutzer, die auf unsere Anzeigen geklickt haben und welche
              Aktionen sie durchgeführt haben. Wir erhalten keine Informationen,
              mit denen wir den Nutzer persönlich identifizieren können. Google
              selbst nutzt zur Identifikation Cookies oder vergleichbare
              Wiedererkennungstechnologien.
            </p>{' '}
            <p>
              Die Nutzung von Google Conversion-Tracking erfolgt auf Grundlage
              von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
              berechtigtes Interesse an der Analyse des Nutzerverhaltens, um
              sowohl sein Webangebot als auch seine Werbung zu optimieren.
              Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine
              Einwilligung zur Speicherung von Cookies), erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>{' '}
            <p>
              Mehr Informationen zu Google Conversion-Tracking finden Sie in den
              Datenschutzbestimmungen von Google:{' '}
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer">
                https://policies.google.com/privacy?hl=de
              </a>
              .
            </p>
            <h3>Facebook Pixel</h3>{' '}
            <p>
              Diese Website nutzt zur Konversionsmessung der
              Besucheraktions-Pixel von Facebook. Anbieter dieses Dienstes ist
              die Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2,
              Irland. Die erfassten Daten werden nach Aussage von Facebook
              jedoch auch in die USA und in andere Drittländer übertragen.
            </p>{' '}
            <p>
              So kann das Verhalten der Seitenbesucher nachverfolgt werden,
              nachdem diese durch Klick auf eine Facebook-Werbeanzeige auf die
              Website des Anbieters weitergeleitet wurden. Dadurch können die
              Wirksamkeit der Facebook-Werbeanzeigen für statistische und
              Marktforschungszwecke ausgewertet werden und zukünftige
              Werbemaßnahmen optimiert werden.
            </p>{' '}
            <p>
              Die erhobenen Daten sind für uns als Betreiber dieser Website
              anonym, wir können keine Rückschlüsse auf die Identität der Nutzer
              ziehen. Die Daten werden aber von Facebook gespeichert und
              verarbeitet, sodass eine Verbindung zum jeweiligen Nutzerprofil
              möglich ist und Facebook die Daten für eigene Werbezwecke,
              entsprechend der{' '}
              <a
                href="https://de-de.facebook.com/about/privacy/"
                target="_blank"
                rel="noopener noreferrer">
                Facebook-Datenverwendungsrichtlinie
              </a>{' '}
              verwenden kann. Dadurch kann Facebook das Schalten von
              Werbeanzeigen auf Seiten von Facebook sowie außerhalb von Facebook
              ermöglichen. Diese Verwendung der Daten kann von uns als
              Seitenbetreiber nicht beeinflusst werden.
            </p>{' '}
            <p>
              Die Nutzung von Facebook-Pixel erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an effektiven Werbemaßnahmen unter Einschluss der
              sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
              wurde (z. B. eine Einwilligung zur Speicherung von Cookies),
              erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6
              Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>{' '}
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:{' '}
              <a
                href="https://www.facebook.com/legal/EU_data_transfer_addendum"
                target="_blank"
                rel="noopener noreferrer">
                https://www.facebook.com/legal/EU_data_transfer_addendum
              </a>{' '}
              und{' '}
              <a
                href="https://de-de.facebook.com/help/566994660333381"
                target="_blank"
                rel="noopener noreferrer">
                https://de-de.facebook.com/help/566994660333381
              </a>
              .
            </p>{' '}
            <p>
              Soweit mit Hilfe des hier beschriebenen Tools personenbezogene
              Daten auf unserer Website erfasst und an Facebook weitergeleitet
              werden, sind wir und die Facebook Ireland Limited, 4 Grand Canal
              Square, Grand Canal Harbour, Dublin 2, Irland gemeinsam für diese
              Datenverarbeitung verantwortlich (Art. 26 DSGVO). Die gemeinsame
              Verantwortlichkeit beschränkt sich dabei ausschließlich auf die
              Erfassung der Daten und deren Weitergabe an Facebook. Die nach der
              Weiterleitung erfolgende Verarbeitung durch Facebook ist nicht
              Teil der gemeinsamen Verantwortung. Die uns gemeinsam obliegenden
              Verpflichtungen wurden in einer Vereinbarung über gemeinsame
              Verarbeitung festgehalten. Den Wortlaut der Vereinbarung finden
              Sie unter:{' '}
              <a
                href="https://www.facebook.com/legal/controller_addendum"
                target="_blank"
                rel="noopener noreferrer">
                https://www.facebook.com/legal/controller_addendum
              </a>
              . Laut dieser Vereinbarung sind wir für die Erteilung der
              Datenschutzinformationen beim Einsatz des Facebook-Tools und für
              die datenschutzrechtlich sichere Implementierung des Tools auf
              unserer Website verantwortlich. Für die Datensicherheit der
              Facebook-Produkte ist Facebook verantwortlich. Betroffenenrechte
              (z.B. Auskunftsersuchen) hinsichtlich der bei Facebook
              verarbeiteten Daten können Sie direkt bei Facebook geltend machen.
              Wenn Sie die Betroffenenrechte bei uns geltend machen, sind wir
              verpflichtet, diese an Facebook weiterzuleiten.
            </p>{' '}
            <p>
              In den Datenschutzhinweisen von Facebook finden Sie weitere
              Hinweise zum Schutz Ihrer Privatsphäre:{' '}
              <a
                href="https://de-de.facebook.com/about/privacy/"
                target="_blank"
                rel="noopener noreferrer">
                https://de-de.facebook.com/about/privacy/
              </a>
              .
            </p>{' '}
            <p>
              Sie können außerdem die Remarketing-Funktion „Custom Audiences“ im
              Bereich Einstellungen für Werbeanzeigen unter{' '}
              <a
                href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
                target="_blank"
                rel="noopener noreferrer">
                https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen
              </a>{' '}
              deaktivieren. Dazu müssen Sie bei Facebook angemeldet sein.
            </p>{' '}
            <p>
              Wenn Sie kein Facebook Konto besitzen, können Sie nutzungsbasierte
              Werbung von Facebook auf der Website der European Interactive
              Digital Advertising Alliance deaktivieren:{' '}
              <a
                href="http://www.youronlinechoices.com/de/praferenzmanagement/"
                target="_blank"
                rel="noopener noreferrer">
                http://www.youronlinechoices.com/de/praferenzmanagement/
              </a>
              .
            </p>
            <h3>LinkedIn Insight Tag</h3>{' '}
            <p>
              Diese Website nutzt das Insight-Tag von LinkedIn. Anbieter dieses
              Dienstes ist die LinkedIn Ireland Unlimited Company, Wilton Plaza,
              Wilton Place, Dublin 2, Irland.
            </p>{' '}
            <h4>Datenverarbeitung durch LinkedIn Insight Tag</h4>{' '}
            <p>
              Mit Hilfe des LinkedIn Insight Tags erhalten wir Informationen
              über die Besucher unserer Website. Ist ein Websitebesucher bei
              LinkedIn registriert, können wir u. a. die beruflichen Eckdaten
              (z. B. Karrierestufe, Unternehmensgröße, Land, Standort, Branche
              und Berufsbezeichnung) unserer Websitebesucher analysieren und so
              unsere Seite besser auf die jeweiligen Zielgruppen ausrichten.
              Ferner können wir mit Hilfe von LinkedIn Insight Tags messen, ob
              die Besucher unserer Webseiten einen Kauf oder eine sonstige
              Aktion tätigen (Conversion-Messung). Die Conversion-Messung kann
              auch geräteübergreifend erfolgen (z.B. von PC zu Tablet). LinkedIn
              Insight Tag bietet außerdem eine Retargeting-Funktion an, mit
              deren Hilfe wir den Besuchern unserer Website zielgerichtete
              Werbung außerhalb der Website anzeigen lassen können, wobei laut
              LinkedIn keine Identifikation des Werbeadressaten stattfindet.
            </p>{' '}
            <p>
              LinkedIn selbst erfasst außerdem sogenannte Logfiles (URL,
              Referrer-URL, IP-Adresse, Geräte- und Browsereigenschaften und
              Zeitpunkt des Zugriffs). Die IP-Adressen werden gekürzt oder
              (sofern sie genutzt werden, um LinkedIn-Mitglieder
              geräteübergreifend zu erreichen) gehasht (pseudonymisiert). Die
              direkten Kennungen der LinkedIn-Mitglieder werden nach sieben
              Tagen von LinkedIn gelöscht. Die verbleibenden pseudonymisierten
              Daten werden dann innerhalb von 180 Tagen gelöscht.
            </p>{' '}
            <p>
              Die von LinkedIn erhobenen Daten können von uns als
              Websitebetreiber nicht bestimmten Einzelpersonen zugeordnet
              werden. LinkedIn wird die erfassten personenbezogenen Daten der
              Websitebesucher auf seinen Servern in den USA speichern und sie im
              Rahmen eigener Werbemaßnahmen nutzen. Details entnehmen Sie der
              Datenschutzerklärung von LinkedIn unter{' '}
              <a
                href="https://www.linkedin.com/legal/privacy-policy#choices-oblig"
                target="_blank"
                rel="noopener noreferrer">
                https://www.linkedin.com/legal/privacy-policy#choices-oblig
              </a>
              .
            </p>{' '}
            <h4>Rechtsgrundlage</h4>{' '}
            <p>
              Die Nutzung von LinkedIn Insight erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an effektiven Werbemaßnahmen unter Einschluss der
              sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
              wurde (z. B. eine Einwilligung zur Speicherung von Cookies),
              erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6
              Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>{' '}
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:{' '}
              <a
                href="https://www.linkedin.com/legal/l/dpa"
                target="_blank"
                rel="noopener noreferrer">
                https://www.linkedin.com/legal/l/dpa
              </a>{' '}
              und{' '}
              <a
                href="https://www.linkedin.com/legal/l/eu-sccs"
                target="_blank"
                rel="noopener noreferrer">
                https://www.linkedin.com/legal/l/eu-sccs
              </a>
              .
            </p>{' '}
            <h4>Widerspruch gegen die Verwendung von LinkedIn Insight Tag</h4>{' '}
            <p>
              Der Analyse des Nutzungsverhaltens sowie zielgerichtete Werbung
              durch LinkedIn unter folgendem Link widersprechen:{' '}
              <a
                href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                target="_blank"
                rel="noopener noreferrer">
                https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
              </a>
              .
            </p>{' '}
            <p>
              Des Weiteren können Mitglieder von LinkedIn die Nutzung ihrer
              personenbezogenen Daten zu Werbezwecken in den Kontoeinstellungen
              steuern. Um einer Verknüpfung von auf unserer Website erhobenen
              Daten durch LinkedIn und Ihrem LinkedIn Account zu vermeiden,
              müssen Sie sich aus Ihrem LinkedIn-Account ausloggen bevor Sie
              unsere Website aufsuchen.
            </p>
            <h2>7. Plugins und Tools</h2>
            <h3>Vimeo ohne Tracking (Do-Not-Track)</h3>{' '}
            <p>
              Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist
              die Vimeo Inc., 555 West 18th Street, New York, New York 10011,
              USA.
            </p>{' '}
            <p>
              Wenn Sie eine unserer mit Vimeo-Videos ausgestatteten Seiten
              besuchen, wird eine Verbindung zu den Servern von Vimeo
              hergestellt. Dabei wird dem Vimeo-Server mitgeteilt, welche
              unserer Seiten Sie besucht haben. Zudem erlangt Vimeo Ihre
              IP-Adresse. Wir haben Vimeo jedoch so eingestellt, dass Vimeo Ihre
              Nutzeraktivitäten nicht nachverfolgen und keine Cookies setzen
              wird.
            </p>{' '}
            <p>
              Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden
              Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
              Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
              entsprechende Einwilligung abgefragt wurde, erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </p>{' '}
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission sowie nach Aussage von
              Vimeo auf „berechtigte Geschäftsinteressen“ gestützt. Details
              finden Sie hier:{' '}
              <a
                href="https://vimeo.com/privacy"
                target="_blank"
                rel="noopener noreferrer">
                https://vimeo.com/privacy
              </a>
              .
            </p>{' '}
            <p>
              Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
              Datenschutzerklärung von Vimeo unter:{' '}
              <a
                href="https://vimeo.com/privacy"
                target="_blank"
                rel="noopener noreferrer">
                https://vimeo.com/privacy
              </a>
              .
            </p>
            <h3>Google Web Fonts</h3>{' '}
            <p>
              Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
              so genannte Web Fonts, die von Google bereitgestellt werden. Beim
              Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in
              ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.
            </p>{' '}
            <p>
              Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung
              zu den Servern von Google aufnehmen. Hierdurch erlangt Google
              Kenntnis darüber, dass über Ihre IP-Adresse diese Website
              aufgerufen wurde. Die Nutzung von Google WebFonts erfolgt auf
              Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat
              ein berechtigtes Interesse an der einheitlichen Darstellung des
              Schriftbildes auf seiner Website. Sofern eine entsprechende
              Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
              Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich
              auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
              jederzeit widerrufbar.
            </p>{' '}
            <p>
              Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine
              Standardschrift von Ihrem Computer genutzt.
            </p>{' '}
            <p>
              Weitere Informationen zu Google Web Fonts finden Sie unter{' '}
              <a
                href="https://developers.google.com/fonts/faq"
                target="_blank"
                rel="noopener noreferrer">
                https://developers.google.com/fonts/faq
              </a>{' '}
              und in der Datenschutzerklärung von Google:{' '}
              <a
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
                rel="noopener noreferrer">
                https://policies.google.com/privacy?hl=de
              </a>
              .
            </p>
            <h3>Font Awesome</h3>{' '}
            <p>
              Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
              und Symbolen Font Awesome. Anbieter ist die Fonticons, Inc., 6
              Porter Road Apartment 3R, Cambridge, Massachusetts, USA.
            </p>{' '}
            <p>
              Beim Aufruf einer Seite lädt Ihr Browser die benötigten Fonts in
              ihren Browsercache, um Texte, Schriftarten und Symbole korrekt
              anzuzeigen. Zu diesem Zweck muss der von Ihnen verwendete Browser
              Verbindung zu den Servern von Font Awesome aufnehmen. Hierdurch
              erlangt Font Awesome Kenntnis darüber, dass über Ihre IP-Adresse
              diese Website aufgerufen wurde. Die Nutzung von Font Awesome
              erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben
              ein berechtigtes Interesse an der einheitlichen Darstellung des
              Schriftbildes auf unserer Website. Sofern eine entsprechende
              Einwilligung abgefragt wurde (z. B. eine Einwilligung zur
              Speicherung von Cookies), erfolgt die Verarbeitung ausschließlich
              auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
              jederzeit widerrufbar.
            </p>{' '}
            <p>
              Wenn Ihr Browser Font Awesome nicht unterstützt, wird eine
              Standardschrift von Ihrem Computer genutzt.
            </p>{' '}
            <p>
              Weitere Informationen zu Font Awesome finden Sie und in der
              Datenschutzerklärung von Font Awesome unter:{' '}
              <a
                href="https://fontawesome.com/privacy"
                target="_blank"
                rel="noopener noreferrer">
                https://fontawesome.com/privacy
              </a>
              .
            </p>
            <h2>8. Audio- und Videokonferenzen</h2>
            <h4>Datenverarbeitung</h4>{' '}
            <p>
              Für die Kommunikation mit unseren Kunden setzen wir unter anderen
              Online-Konferenz-Tools ein. Die im Einzelnen von uns genutzten
              Tools sind unten aufgelistet. Wenn Sie mit uns per Video- oder
              Audiokonferenz via Internet kommunizieren, werden Ihre
              personenbezogenen Daten von uns und dem Anbieter des jeweiligen
              Konferenz-Tools erfasst und verarbeitet.
            </p>{' '}
            <p>
              Die Konferenz-Tools erfassen dabei alle Daten, die Sie zur Nutzung
              der Tools bereitstellen/einsetzen (E-Mail-Adresse und/oder Ihre
              Telefonnummer). Ferner verarbeiten die Konferenz-Tools die Dauer
              der Konferenz, Beginn und Ende (Zeit) der Teilnahme an der
              Konferenz, Anzahl der Teilnehmer und sonstige
              „Kontextinformationen“ im Zusammenhang mit dem
              Kommunikationsvorgang (Metadaten).
            </p>{' '}
            <p>
              Des Weiteren verarbeitet der Anbieter des Tools alle technischen
              Daten, die zur Abwicklung der Online-Kommunikation erforderlich
              sind. Dies umfasst insbesondere IP-Adressen, MAC-Adressen,
              Geräte-IDs, Gerätetyp, Betriebssystemtyp und -version,
              Client-Version, Kameratyp, Mikrofon oder Lautsprecher sowie die
              Art der Verbindung.
            </p>{' '}
            <p>
              Sofern innerhalb des Tools Inhalte ausgetauscht, hochgeladen oder
              in sonstiger Weise bereitgestellt werden, werden diese ebenfalls
              auf den Servern der Tool-Anbieter gespeichert. Zu solchen Inhalten
              zählen insbesondere Cloud-Aufzeichnungen, Chat-/
              Sofortnachrichten, Voicemails hochgeladene Fotos und Videos,
              Dateien, Whiteboards und andere Informationen, die während der
              Nutzung des Dienstes geteilt werden.
            </p>{' '}
            <p>
              Bitte beachten Sie, dass wir nicht vollumfänglich Einfluss auf die
              Datenverarbeitungsvorgänge der verwendeten Tools haben. Unsere
              Möglichkeiten richten sich maßgeblich nach der Unternehmenspolitik
              des jeweiligen Anbieters. Weitere Hinweise zur Datenverarbeitung
              durch die Konferenztools entnehmen Sie den Datenschutzerklärungen
              der jeweils eingesetzten Tools, die wir unter diesem Text
              aufgeführt haben.
            </p>{' '}
            <h4>Zweck und Rechtsgrundlagen</h4>{' '}
            <p>
              Die Konferenz-Tools werden genutzt, um mit angehenden oder
              bestehenden Vertragspartnern zu kommunizieren oder bestimmte
              Leistungen gegenüber unseren Kunden anzubieten (Art. 6 Abs. 1 lit.
              b DSGVO). Des Weiteren dient der Einsatz der Tools der allgemeinen
              Vereinfachung und Beschleunigung der Kommunikation mit uns bzw.
              unserem Unternehmen (berechtigtes Interesse im Sinne von Art. 6
              Abs. 1 lit. f DSGVO). Soweit eine Einwilligung abgefragt wurde,
              erfolgt der Einsatz der betreffenden Tools auf Grundlage dieser
              Einwilligung; die Einwilligung ist jederzeit mit Wirkung für die
              Zukunft widerrufbar.
            </p>{' '}
            <h4>Speicherdauer</h4>{' '}
            <p>
              Die unmittelbar von uns über die Video- und Konferenz-Tools
              erfassten Daten werden von unseren Systemen gelöscht, sobald Sie
              uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
              widerrufen oder der Zweck für die Datenspeicherung entfällt.
              Gespeicherte Cookies verbleiben auf Ihrem Endgerät, bis Sie sie
              löschen. Zwingende gesetzliche Aufbewahrungsfristen bleiben
              unberührt.
            </p>{' '}
            <p>
              Auf die Speicherdauer Ihrer Daten, die von den Betreibern der
              Konferenz-Tools zu eigenen Zwecken gespeichert werden, haben wir
              keinen Einfluss. Für Einzelheiten dazu informieren Sie sich bitte
              direkt bei den Betreibern der Konferenz-Tools.
            </p>{' '}
            <h4>Eingesetzte Konferenz-Tools</h4>{' '}
            <p>Wir setzen folgende Konferenz-Tools ein:</p>
            <h3>Zoom</h3>{' '}
            <p>
              Wir nutzen Zoom. Anbieter dieses Dienstes ist die Zoom
              Communications Inc., San Jose, 55 Almaden Boulevard, 6th Floor,
              San Jose, CA 95113, USA. Details zur Datenverarbeitung entnehmen
              Sie der Datenschutzerklärung von Zoom:{' '}
              <a
                href="https://zoom.us/de-de/privacy.html"
                target="_blank"
                rel="noopener noreferrer">
                https://zoom.us/de-de/privacy.html
              </a>
              .
            </p>{' '}
            <p>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:{' '}
              <a
                href="https://zoom.us/de-de/privacy.html"
                target="_blank"
                rel="noopener noreferrer">
                https://zoom.us/de-de/privacy.html
              </a>
              .
            </p>
            <h4>Abschluss eines Vertrags über Auftragsverarbeitung</h4>{' '}
            <p>
              Wir haben mit dem Anbieter von Zoom einen Vertrag zur
              Auftragsverarbeitung abgeschlossen und setzen die strengen
              Vorgaben der deutschen Datenschutzbehörden bei der Nutzung von
              Zoom vollständig um.
            </p>
            <h2>9. Eigene Dienste</h2>
            <h3>Umgang mit Bewerberdaten</h3>{' '}
            <p>
              Wir bieten Ihnen die Möglichkeit, sich bei uns zu bewerben (z. B.
              per E-Mail, postalisch oder via Online-Bewerberformular). Im
              Folgenden informieren wir Sie über Umfang, Zweck und Verwendung
              Ihrer im Rahmen des Bewerbungsprozesses erhobenen
              personenbezogenen Daten. Wir versichern, dass die Erhebung,
              Verarbeitung und Nutzung Ihrer Daten in Übereinstimmung mit
              geltendem Datenschutzrecht und allen weiteren gesetzlichen
              Bestimmungen erfolgt und Ihre Daten streng vertraulich behandelt
              werden.
            </p>{' '}
            <h4>Umfang und Zweck der Datenerhebung </h4>{' '}
            <p>
              Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre
              damit verbundenen personenbezogenen Daten (z. B. Kontakt- und
              Kommunikationsdaten, Bewerbungsunterlagen, Notizen im Rahmen von
              Bewerbungsgesprächen etc.), soweit dies zur Entscheidung über die
              Begründung eines Beschäftigungsverhältnisses erforderlich ist.
              Rechtsgrundlage hierfür ist § 26 BDSG-neu nach deutschem Recht
              (Anbahnung eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit.
              b DSGVO (allgemeine Vertragsanbahnung) und – sofern Sie eine
              Einwilligung erteilt haben – Art. 6 Abs. 1 lit. a DSGVO. Die
              Einwilligung ist jederzeit widerrufbar. Ihre personenbezogenen
              Daten werden innerhalb unseres Unternehmens ausschließlich an
              Personen weitergegeben, die an der Bearbeitung Ihrer Bewerbung
              beteiligt sind.
            </p>{' '}
            <p>
              Sofern die Bewerbung erfolgreich ist, werden die von Ihnen
              eingereichten Daten auf Grundlage von § 26 BDSG-neu und Art. 6
              Abs. 1 lit. b DSGVO zum Zwecke der Durchführung des
              Beschäftigungsverhältnisses in unseren Datenverarbeitungssystemen
              gespeichert.
            </p>{' '}
            <h4>Aufbewahrungsdauer der Daten</h4>{' '}
            <p>
              Sofern wir Ihnen kein Stellenangebot machen können, Sie ein
              Stellenangebot ablehnen oder Ihre Bewerbung zurückziehen, behalten
              wir uns das Recht vor, die von Ihnen übermittelten Daten auf
              Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f
              DSGVO) bis zu 6 Monate ab der Beendigung des Bewerbungsverfahrens
              (Ablehnung oder Zurückziehung der Bewerbung) bei uns
              aufzubewahren. Anschließend werden die Daten gelöscht und die
              physischen Bewerbungsunterlagen vernichtet. Die Aufbewahrung dient
              insbesondere Nachweiszwecken im Falle eines Rechtsstreits. Sofern
              ersichtlich ist, dass die Daten nach Ablauf der 6-Monatsfrist
              erforderlich sein werden (z.B. aufgrund eines drohenden oder
              anhängigen Rechtsstreits), findet eine Löschung erst statt, wenn
              der Zweck für die weitergehende Aufbewahrung entfällt.
            </p>{' '}
            <p>
              Eine längere Aufbewahrung kann außerdem stattfinden, wenn Sie eine
              entsprechende Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) erteilt
              haben oder wenn gesetzliche Aufbewahrungspflichten der Löschung
              entgegenstehen.
            </p>
          </div>
        </DatenschutzBox>
      </Section>
    </>
  )
}

export default Datenschutz
