import SEOHeader from '@components/basics/SEOHeader'
import TagManager from 'react-gtm-module'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import Content from '@content/leistungen/design.json'
import React, { Suspense, useEffect } from 'react'

const ContentBox = React.lazy(() => import('@components/sections/ContentBox'))
const LeistungenShort = React.lazy(
  () => import('@components/sections/LeistungenShort')
)
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const KontaktShort = React.lazy(
  () => import('@components/sections/KontaktShort')
)
const Liste = React.lazy(() => import('@components/sections/Liste'))
const Quote = React.lazy(() => import('@components/sections/Quote'))
const Technologien = React.lazy(
  () => import('@components/sections/Technologien')
)
const Keyfacts = React.lazy(() => import('@components/sections/Keyfacts'))
const Referenzen = React.lazy(() => import('@components/sections/Referenzen'))

const Design = () => {
  useEffect(() => {
    TagManager.dataLayer({ dataLayer: { event: 'design' } })
  }, [])
  return (
    <>
      <SEOHeader id="design" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <ContentBox item={Content.content} />
        <LeistungenShort item={Content.leistungen} titleSize={1} />
        <Referenzen category="design" />
        <Kundenmeinungen category="design" />
        <Liste
          title={Content.projektablauf.title}
          items={Content.projektablauf.items}
        />
        <KontaktShort item={Content.contactShort} />
        <Keyfacts item={Content.keyfacts} />
        <Technologien technologie="design" />
        <Liste
          title={Content.why.title}
          dark={false}
          dots={false}
          items={Content.why.items}
        />
        <Quote title={Content.quote.title} autor={Content.quote.autor} />
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default Design
