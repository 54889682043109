// @ts-nocheck
import Seo from '@content/seo.json'
import React from 'react'
import { Helmet } from 'react-helmet'

const SEOHeader = ({ id }: { id: any }) => {
  return (
    <Helmet>
      <title>{Seo[id].title + ' ' + Seo.template}</title>
      <meta name="description" content={Seo[id].description} />
      <meta name="keywords" content={Seo[id].keywords} />
      <meta name="author" content={Seo.env.author} />
      <meta name="publisher" content={Seo.env.publisher} />
      <meta name="revisit-after" content={Seo.env.revisitAfter} />

      {/* Facebook */}
      <meta name="og:type" content={Seo.env.type} />
      <meta name="og:title" content={Seo[id].title + ' ' + Seo.template} />
      <meta name="og:description" content={Seo[id].description} />
      <meta name="og:url" content={window.location.href} />
      <meta
        name="og:image"
        content={`https://www.peakconcepts.de/images/social/${
          Seo[id].image || 'basic.jpg'
        }`}
      />
      <meta name="og:image:width" content="1200" />
      <meta name="og:image:height" content="630" />
      <meta name="og:site_name" content={Seo.env.site} />

      {/* Twitter */}
      <meta name="twitter:card" content={Seo.env.twitterCard} />
      <meta name="twitter:site" content={Seo.env.site} />
      <meta name="twitter:creator" content={Seo.env.creator} />
      <meta name="twitter:title" content={Seo[id].title + ' ' + Seo.template} />
      <meta name="twitter:description" content={Seo[id].description} />
      <meta
        name="twitter:image"
        content={`https://www.peakconcepts.de/images/social/${
          Seo[id].image || 'basic.jpg'
        }`}
      />

      {/* Google */}
      <meta itemprop="name" content={Seo[id].title + ' ' + Seo.template} />
      <meta itemprop="description" content={Seo[id].description} />
      <meta
        itemprop="image"
        content={`https://www.peakconcepts.de/images/social/${
          Seo[id].image || 'basic.jpg'
        }`}
      />
    </Helmet>
  )
}

export default SEOHeader
