import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/agentur/webentwicklung.json'
import Content from '@content/leistungen/webentwicklung.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)
const Technologien = React.lazy(
  () => import('@components/sections/Technologien')
)

const Webentwicklungsagentur = () => {
  return (
    <>
      <SEOHeader id="lp-agentur-webentwicklung" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <p>Was eine gute für Webentwicklung Agentur ausmacht?</p>
          <li>Planung und Entwurf von interaktiven Websites</li>
          <li>niveauvolle und individuelle Webdesigns</li>
          <li>dynamische Inhalte, die Zielgruppen einen Mehrwert bieten</li>
          <li>eine auf Besucher zugeschnittene Customer Journey</li>
          <li>
            nutzerfreundliche Seitenstruktur mit einer intuitiven Navigation
          </li>
          <li>professionelles Webhosting</li>
          <li>
            regelmäßige Wartungen und Weiterentwicklungen nach aktuellen
            Webstandards
          </li>
          <br />
          <p>
            Eine Webentwicklung Agentur kümmert sich sowohl um die Entwicklung
            von Webseiten als auch um Online-Shops und Web-Apps. Durch
            Schnittstellen entsteht eine kanalübergreifende Internetpräsenz, die
            Kunden wirklich erreicht und bei Ihrer Online- Präsentation
            Einheitlichkeit schafft.
          </p>
          <p>
            Die Webentwicklung einer Agentur umfasst die Arbeit am Frontend und
            die Organisation des Backends. Beim Frontend liegt der Fokus auf
            allem, was der Kunde am Ende auf der Webseite sieht und über den
            Browser abrufen kann. Eine Webentwicklung Agentur stellt sicher,
            dass alle Webanwendungen userfreundlich geplant und entwickelt
            werden. Im Backend geht es um die Arbeit hinter einer Webseite, etwa
            der Verwaltung von Daten in Datenbanken.
          </p>
          <p>
            Die Arbeit einer Agentur für Webentwicklung Agentur kann
            untergliedert werden in:
          </p>
          <li>geschäftliche oder private Webseitengestaltung</li>
          <li>Entwicklung von Online-Shops</li>
          <li>die Einbindung von Marktplätzen</li>
          <li>Entwicklung und Realisierung von mobilen Apps</li>
          <li>Betreuung, Wartung und Weiterentwicklung von Websites</li>
          <li>Implementierung von Softwarelösungen</li>
          <li>Arbeiten am Frontend und Backend</li>

          <p>
            Ziel einer professionelle Webentwicklung Agentur ist es, das
            Unternehmen mit allen Maßnahmen dabei zu unterstützen,
            unternehmerischen Erfolg durch eine umfassende Webpräsenz
            sicherzustellen. Bedenken Sie immer, dass Sie heute nur eine Chance
            haben, um mit Ihrer Webseite zu überzeugen. Die digitale Welt dreht
            sich immer schneller und eine neue Technologie jagt die nächste. Wir
            als Ihre Webentwicklung Agentur behalten den Überblick für Sie und
            unterstützen Sie langfristig beim Aufbau von Sichtbarkeit und
            Reichweite. Dabei arbeiten wir als Webentwicklung Agentur bei jedem
            Schritt mit Ihnen zusammen und gehen gemeinsam in Richtung Zukunft.
          </p>

          <br />
          <h2>
            Warum Sie sich für eine professionelle Webentwicklung Agentur
            entscheiden sollten
          </h2>
          <br />
          <p>
            Im Internet gibt es heute Milliarden Internetseiten. Die
            Informationsflut stellt User vor große Herausforderungen, sodass
            diese eine immer geringere Aufmerksamkeitspanne mitbringen und Wert
            auf Qualität statt Quantität legen. Ein Beispiel: Sobald eine Seite
            länger als drei Sekunden benötigt, um sich zu öffnen, verlassen
            bereits 40 % der Besucher diese wieder. Bei der Webentwicklung{' '}
            <NavLink to="/agentur">
              <b>
                <u>Agentur</u>
              </b>
            </NavLink>{' '}
            geht es also nicht nur darum, ein schönes Design zu gestalten. Es
            spielen hier zahlreiche Funktionen eine große Rolle, damit am Ende
            die berühmte Customer Journey wirklich zu einem gelungenen Erlebnis
            wird.
          </p>

          <p>
            Unsere Webentwicklung Agentur bringt dazu das Know-how, die
            technischen Mittel und die Erfahrung mit, die dafür sorgen, dass
            Ihre Webseite erfolgreich wird. Wir beherrschen das Zusammenspiel
            von Design, Funktionalität, intuitiver Navigation, durchdachter
            Architektur und dem schmalen Grat beim Setzen der Call-to-Action.
            Abgestimmt auf Ihre Zielgruppe und im Einklang mit Ihren
            Vorstellungen bieten wir als professionelle Webentwicklung Agentur
            Ihnen eine Webentwicklung auf höchstem Niveau.
          </p>

          <br />
          <h2>
            Webentwicklung Agentur für Ihre Firma: Das sind unsere Leistungen
            für Sie
          </h2>
          <br />
          <p>
            Im Bereich der Webentwicklung einer Agentur für Ihre Firma verlassen
            Sie sich unter anderem auf folgende{' '}
            <NavLink to="/leistungen">
              <b>
                <u>Leistungen</u>
              </b>
            </NavLink>
            :
          </p>
          <li>
            personalisiertes{' '}
            <NavLink to="/leistungen/design">
              <b>
                <u>Webdesign</u>
              </b>
            </NavLink>{' '}
            für Ihren individuellen Auftritt
          </li>
          <li>
            Webentwicklung mit innovativen Technologien für Ihre smarte
            Performance
          </li>
          <li>
            volle Dynamik mit responsiven Webseiten für die mobile Zukunft
          </li>
          <li>
            umfassender Service und Support mit Hosting, Pflege und laufender
            Optimierung
          </li>
          <p>
            Unser Ziel als Webentwicklung Agentur ist es, Ihre Webseite zu Ihrer
            digitalen Visitenkarte zu machen, die sofort überzeugt. Wir streben
            dabei danach, langfristige Erfolge sicherzustellen und fortlaufend
            weiterzuentwickeln, damit Sie immer der Dynamik des Markts gerecht
            werden.
          </p>
          <br />
          <h2>
            Strategisch und durchdacht: Webentwicklung Agentur Peakconcepts
          </h2>
          <br />
          <p>
            Erfolg ist planbar – auch bei der Webentwicklung einer Agentur. Und
            so beginnt jede Zusammenarbeit bei uns als professionelle
            Webentwicklung Agentur mit einem individuellen Beratungsgespräch, in
            dem wir uns viel Zeit nehmen, um Sie, Ihre Wünsche und Vorstellungen
            kennenzulernen und Ihnen die Möglichkeit geben, sich von uns als
            Agentur für Webentwicklung zu überzeugen. Das Gespräch ist zudem die
            Basis für die anschließende Strategieerstellung, bei der wir Ihre
            Mitbewerber unter die Lupe nehmen und Ihre Zielgruppe analysieren.
            Danach erstellen wir gemeinsam ein Konzept mit Zeitplan,
            Meilensteinen und dem Gerüst der Webseite. Schon nach wenigen Tagen
            präsentieren wir Ihnen ein Wireframe, das Ihnen eine grobe
            Skizzierung der späteren Webseite aufzeigt. Sind Sie zufrieden,
            setzen wir das Webdesign direkt um. Als innovative Agentur haben Sie
            über unser Tool für Prototyping stets Einblick in die Phasen der
            Webentwicklung einer Agentur. Nach Fertigstellung und Ihrer
            Zustimmung über den Look, die Struktur und Funktionen machen wir uns
            an die Programmierung und bringen die Webseite nach umfassenden
            Tests, natürlich DSGVO-konform, live.
          </p>
          <br />
          <h2>Was können wir als Webentwicklung Agentur für Sie tun?</h2>
          <br />
          <p>
            Als Webentwicklung Agentur unterstützen wir Sie mit Know-how,
            Technologien und unserer jahrelangen Erfahrung. Wir bieten
            bodenständige Leistungen und begleiten Sie von Beginn an bis weit
            über die Fertigstellung hinaus bei der Webentwicklung. Sie möchten
            eine Webseite, die mit durchdachter Qualität in jedem Bereich
            überzeugt? Dann sind wir Ihre Webentwickler Agentur.
          </p>
          <p>
            Nehmen Sie jetzt{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            zu uns auf und sorgen Sie für Ihre beste Performance im Netz.
          </p>
        </LPContent>
        <Kundenmeinungen category="app" />
        <Technologien technologie="app" />
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default Webentwicklungsagentur
