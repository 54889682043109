import Section from '@components/elements/Section'
import parse from 'html-react-parser'
import React from 'react'
import styled from 'styled-components'

interface QuoteProps {
  dark?: boolean
}

const QuoteDiv = styled.div<QuoteProps>`
  background: ${(props) => (props.dark ? '#000' : '#FFF')};
  padding: -20px 0;
  text-align: center;
  p.headline-2 {
    color: ${(props) => (props.dark ? '#FFF' : '#000')};
    font-size: 2rem;
    span {
      display: inline-block;
      color: ${(props) => (props.dark ? '#FFF' : '#000')};
      border-bottom: 4px solid ${(props) => props.theme.primary};
      padding-bottom: 0px;
      margin: 0;
    }
  }
  .autor {
    color: ${(props) => (props.dark ? '#FFF' : '#000')};
    margin: 20px 0 0 0;
    font-style: italic;
  }
  @media (max-width: 600px) {
    p.headline-2,
    p.headline-2 span {
      font-size: 20px;
      line-height: 26px;
    }
  }
`

const Quote = ({
  dark = true,
  title,
  autor
}: {
  dark?: boolean
  title: string
  autor?: string
}) => {
  return (
    <Section dark={dark}>
      <QuoteDiv dark={dark}>
        <div className="container">
          <p className="headline-2">„{parse(title, { trim: true })}“</p>
          {autor && <p className="autor">- {autor}</p>}
        </div>
      </QuoteDiv>
    </Section>
  )
}

export default Quote
