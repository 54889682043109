import Section from '@components/elements/Section'
import Title from '@components/elements/Title'
import parse from 'html-react-parser'
import React from 'react'

const ContentBox = ({
  titleSize = 2,
  item,
  dark = false
}: {
  titleSize?: number
  item: {
    title: string
    items: Array<string>
    list?: Array<string>
  }
  dark?: boolean
}) => {
  return (
    <div>
      <Section dark={dark}>
        <div className="container">
          <Title title={item.title} dark={dark} titleSize={titleSize} />
          {item.items.map((item, i) => (
            <p key={i}>{parse(item, { trim: true })}</p>
          ))}
          {item.list &&
            item.list.map((item, i) => (
              <div key={i}>
                <p>{parse(item, { trim: true })}</p>
              </div>
            ))}
        </div>
      </Section>
    </div>
  )
}

export default ContentBox
