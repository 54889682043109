import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/webdesign/tuebingen.json'
import Content from '@content/leistungen/design.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const KontaktShort = React.lazy(
  () => import('@components/sections/KontaktShort')
)
const Quote = React.lazy(() => import('@components/sections/Quote'))
const Technologien = React.lazy(
  () => import('@components/sections/Technologien')
)
const Keyfacts = React.lazy(() => import('@components/sections/Keyfacts'))
const Referenzen = React.lazy(() => import('@components/sections/Referenzen'))

const LPWebdesignTuebingen = () => {
  return (
    <>
      <SEOHeader id="lp-webdesign-tuebingen" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>
            Auf diese Leistungen des Webdesigns in Tübingen können Sie sich
            verlassen
          </h2>
          <br />
          <p>
            Bei uns erhalten Sie für Ihr Webdesign in Tübingen umfassende
            Serviceleistungen und das aus einer Hand:
          </p>
          <li>
            Sichtbarkeit, Performance und Erfolg mit einem professionellen
            Webdesign
          </li>
          <li>
            <NavLink to="/leistungen/webentwicklung">
              <b>
                <u>Webentwicklung</u>
              </b>
            </NavLink>{' '}
            mit echter Leistungsstärke
          </li>
          <li>
            Responsive Webseiten für das Webdesign in Tübingen auf allen
            Endgeräten
          </li>
          <li>
            Service und Support für sorgenfreies Hosting, Pflege und Optimierung
          </li>
          <p>
            Mit unserem Rundum-sorglos-Paket des Webdesigns in Tübingen
            übernehmen wir Ihren digitalen Erfolg und Sie konzentrieren sich auf
            Ihre Kernarbeit.
          </p>
          <br />
          <h2>
            Das Konzept beim Webdesign in Tübingen – weil Erfolge planbar sind
          </h2>
          <br />
          <p>
            Gemeinsam starten wir beim Webdesign in Tübingen mit einem
            umfassenden Beratungsgespräch, bei dem es um Ihre Wünsche und
            Vorstellungen geht. Wir lassen dabei unser fachliches Know-how
            einfließen und legen damit den ersten Meilenstein für Ihre
            Digitalisierung in Tübingen. Wir setzen zusammen Ziele, analysieren
            Ihre Zielgruppe sowie den Markt und Ihre Mitbewerber. Im Anschluss
            geht es bei Ihrem Webdesign in Tübingen in die Konzeptphase. Wir
            erstellen einen Zeitplan und lassen die erste Struktur Wirklichkeit
            werden. Unsere Experten kreieren ein Wireframe, das als erste
            Skizzierung Ihres späteren Webauftritts dient. Sind Sie damit für
            Ihr Webdesign in Tübingen zufrieden, folgt unmittelbar der
            Umsetzungsprozess, bei dem unser innovatives Prototyping-Tool zum
            Einsatz kommt, sodass Sie über alle Schritte der Webentwicklung
            stets informiert sind. Optional übernehmen wir bei Ihrem Webdesign
            in Tübingen die Programmierung und schalten Ihre Seite nach einigen
            ausführlichen Testphasen live.
          </p>
          <br />
          <h2>
            Webdesign in Tübingen – starten Sie jetzt in Ihre digitale Zukunft
          </h2>
          <br />
          <p>
            Für uns sind bei Ihrem Webdesign in Tübingen fachliche Kompetenz,
            technische Expertise und eine strategische Planung
            selbstverständlich. Was bei uns zählt, sind zudem Ehrlichkeit,
            Zuverlässigkeit, Transparenz und die persönliche Nähe zu unseren
            Kunden. Bodenständige Leistungen und eine hohe Qualität sind unsere
            Stärken bei Ihrem Webdesign in Tübingen. Gerne unterstützen wir Sie
            umfänglich und professionell auf dem Weg der Digitalisierung in
            Tübingen.
          </p>
          <p>
            Lassen Sie uns jetzt den Weg gemeinsam gehen. Nehmen Sie dazu
            unverbindlich{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            auf. Ihr erster Schritt in Richtung Zukunft beginnt mit einem
            persönlichen Webdesign in Tübingen und uns als Ihr Partner.
          </p>
        </LPContent>
        <Referenzen category="design" />
        <Kundenmeinungen category="design" />
        <KontaktShort item={Content.contactShort} />
        <Keyfacts item={Content.keyfacts} />
        <Technologien technologie="web" />
        <Quote title={Content.quote.title} autor={Content.quote.autor} />
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default LPWebdesignTuebingen
