import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/agentur/mitarbeiterFinden.json'
import Content from '@content/leistungen/mitarbeiterFinden.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))

const MitarbeiterFinden = () => {
  return (
    <>
      <SEOHeader id="lp-agentur-mitarbeiter-finden" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Warum eigentlich Bewerber digital finden?</h2>
          <br />
          <p>
            Digitalisierung und Globalisierung haben dazu geführt, dass wir viel
            Zeit in der digitalen Welt verbringen – ob beruflich oder privat. In
            dem Zuge haben auch digitale Kampagnen an Bedeutung gewonnen, wenn
            es um das Mitarbeiter Finden geht. Heute ist es der effektivste und
            schnellste Weg, neue Talente zu finden, zu rekrutieren und sogar
            langfristig zu binden.
          </p>
          <p>
            Das Schöne ist, dass Ihnen eine Vielzahl an Möglichkeiten
            offenstehen. Wichtig ist dabei, sich für jene zu entscheiden, die
            wirklich relevant für Ihren Betrieb sind. Genau dazu holen Sie uns
            ins Boot. Wir stellen sicher, dass Sie mit den richtigen Maßnahmen
            Mitarbeiter finden und sich genau in jenen Online-Börsen,
            Jobportalen und Plattformen platzieren, in denen sich Ihre
            potenziellen Kandidaten aufhalten.
          </p>
          <p>
            Darüber hinaus kommt es darauf an, ansprechende Stellenanzeigen zu
            gestalten, die sich in Jobportalen von der Masse abheben. Digitale
            Jobbörsen nehmen, trotz der großen Konkurrenz, immer noch einen
            zentralen Stellenwert ein, um neue Mitarbeiter zu finden. In diesen
            suchen Fachkräfte mit entsprechenden Qualifikationen nach einem
            neuen Job – sicher, aber nicht nur.
          </p>
          <p>
            Unabdingbar, wenn es um das Mitarbeiter Finden geht, ist das Social
            Recruiting geworden. Facebook, Instagram, YouTube und WhatsApp
            gehören fest zum Alltag Ihrer Zielgruppe und potenzieller
            Mitarbeiter. Sie erreichen mit überzeugendem Content nicht nur
            Bewerber, die aktuell auf der Suche sind, sondern auch diejenigen,
            die noch gar nicht wissen, dass sie einen neuen Job suchen. Auf
            Facebook und Instagram gewinnen Sie die Aufmerksamkeit von Talenten
            vorwiegend über Content,{' '}
            <NavLink to="/leistungen/design">
              <b>
                <u>Design</u>
              </b>
            </NavLink>
            , Videos und Fotos und natürlich über bezahlte Kampagnen.
          </p>
          <p>
            Anders gestaltet sich das Mitarbeiter Finden auf XING und LinkedIn.
            Auf diesen Kanälen begegnen Sie talentierten Nachwuchskräften,
            interessanten Absolventen und hochwertigen Fachkräften. Effektives
            Mitarbeiter Finden gestaltet sich hier über eine LinkedIn
            Karriereseite, Filtereinstellungen oder Stellenanzeigen, um nur
            einige Methoden zu nennen.
          </p>
          <p>
            Greifen Sie beim Mitarbeiter Finden auf unsere Erfahrung und
            Expertise zurück. Auf Grundlage Ihrer Ziele und Ansprüche bringen
            wir Sie mit professionellen Kampagnen zum richtigen Zeitpunkt auf
            den richtigen Kanälen voran. Mit uns haben Sie einen professionellen
            Partner für Ihre{' '}
            <NavLink to="/leistungen/webentwicklung">
              <b>
                <u>Webentwicklung</u>
              </b>
            </NavLink>{' '}
            und Ihr{' '}
            <NavLink to="/leistungen/online-marketing">
              <b>
                <u>Online-Marketing</u>
              </b>
            </NavLink>
            .
          </p>
          <p>Ihre Vorteile beim Mitarbeiter Finden mit Social Recruiting:</p>
          <li>zielgruppengerechte Ansprache</li>
          <li>Entwicklung von Strategien und Kampagnen</li>
          <li>maßgeschneiderte Anzeigen</li>
          <li>sofortige Kommunikation</li>
          <li>Steigerung der Aufmerksamkeit</li>
          <li>Erhöhung der Sichtbarkeit</li>
          <li>konsistente Bewerbungsprozesse</li>
          <li>automatisierte Vorselektion</li>
          <li>fachlich passende Bewerber</li>
          <br />

          <h2>Mitarbeiter finden mit dem Employer Branding</h2>
          <br />
          <p>
            Mitarbeiter finden Sie heute kaum mehr, ohne sich als attraktiver
            Arbeitgeber auf dem Arbeitsmarkt zu präsentieren. Daher setzen wir
            mit einem durchdachten Employer Branding dort an, wo andere bereits
            aufhören. Ziel der Bildung einer Arbeitgebermarke ist es, das
            gesamte Unternehmen in der Öffentlichkeit ins rechte Licht zu
            rücken, Ihre Unternehmenskultur nach außen sichtbar zu machen und
            Sie als Betrieb zu platzieren, in dem man gerne arbeitet. So
            schaffen Sie einen zuverlässigen Anreiz für Bewerber, die sich mit
            Ihrer authentischen Philosophie identifizieren können und können
            Mitarbeiter finden, die wirklich zu Ihnen passen.
          </p>
          <p>
            Eine Maßnahme beim Employer Branding ist es, unter anderem Ihre
            aktuellen Mitarbeiter zu Markenbotschaftern zu machen.
          </p>
          <p>
            Ferner zeigt sich beim Mitarbeiter Finden über das Employer Branding
            ein Vorteil hinsichtlich der Langfristigkeit. Haben Sie einmal eine
            positive Arbeitgebermarke aufgebaut und pflegen diese kompromisslos,
            werden Sie immer dann Bewerber finden können, wenn Sie wirklich neue
            Stellen besetzen müssen.
          </p>
          <p>Ihre Vorteile beim Mitarbeiter Finden mit Employer Branding:</p>
          <li>Aufbau einer positiven Arbeitgebermarke</li>
          <li>strategische Maßnahmenplanung</li>
          <li>nachhaltige Implementierung eines Systems</li>
          <li>laufender Bewerberfluss, skalierbar und planbar</li>
          <li>Kostentransparenz</li>
          <li>
            Aufmerksamkeit bei qualifizierten Fachkräften aus der Region,
            deutschland- oder europaweit
          </li>
          <li>Entlastung der Personalabteilung</li>
          <li>Steigerung der Qualität durch Vorqualifizierung</li>
          <li>erhöhte Reichweite</li>
          <li>gleichzeitige Mitarbeiterbindung</li>
          <br />

          <h2>
            Bereit zum Mitarbeiter Finden auf effektiven Wegen? Wir freuen uns
            auf Sie!
          </h2>
          <br />
          <p>
            Mitarbeiter finden muss nicht zwangsläufig zur Herausforderung
            werden, sofern Sie sich rechtzeitig dafür entscheiden, die
            Mitarbeitergewinnung in professionelle Hände zu legen. Wir sind
            dabei Ihr Partner im zukunftsfähigen Recruiting und legen das
            Fundament für Ihre starke Personalstruktur. Lassen Sie uns gemeinsam
            Mitarbeiter finden, die langfristig zu Ihrem Erfolgsfaktor werden.
          </p>
          <p>Nehmen Sie jetzt unverbindlich Kontakt zu uns auf!</p>
        </LPContent>
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default MitarbeiterFinden
