import SEOHeader from '@components/basics/SEOHeader'
import HeroHeader from '@components/sections/HeroHeader'
import Content from '@content/referenzen.json'
import React, { Suspense } from 'react'
const Title = React.lazy(() => import('@components/elements/Title'))
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const Section = React.lazy(() => import('@components/elements/Section'))
const ReferenzenSection = React.lazy(
  () => import('@components/sections/Referenzen')
)
const Kundenlogos = React.lazy(() => import('@components/sections/Kundenlogos'))

const Referenzen = () => {
  return (
    <>
      <SEOHeader id="referenzen" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <Section dark={false}>
          <div className="container">
            <Title
              title={Content.textBox.title}
              margin={false}
              dark={false}
              titleSize={1}
            />
            <p className="has-text-centered mt-5">{Content.textBox.text}</p>
          </div>
        </Section>
        <ReferenzenSection category="all" showTitle={false} />
        <Kundenmeinungen />
        <Kundenlogos />
        <Kontakt />
      </Suspense>
    </>
  )
}

export default Referenzen
