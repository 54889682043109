import ResponsiveImage from '@components/basics/ResponsiveImage'
import Section from '@components/elements/Section'
import Title from '@components/elements/Title'
import Content from '@content/general.json'
import React, { useState } from 'react'
import styled from 'styled-components'

interface ReviewProps {
  index: number
  showAll: boolean
}

const ReviewItem = styled.div<ReviewProps>`
  margin-bottom: 80px !important;
  img {
    height: 180px;
    width: 180px;
    filter: grayscale(100%);
  }

  .image-container {
    text-align: center;
  }
  p {
    font-style: italic;
    margin: 0 0 20px;
  }
  @media (max-width: 768px) {
    .is-centered {
      display: flex;
      justify-content: center;
    }
  }
  @media (max-width: 767px) {
    display: ${(props) =>
      props.showAll
        ? 'inline-block'
        : props.index < 3
        ? 'inline-block'
        : 'none !important'};
    margin-bottom: 40px !important;
    div:first-of-type {
      text-align: center;
    }
    img {
      height: auto;
    }
  }
`

const ReviewButton = styled.button`
  width: 100%;
  @media (min-width: 768px) {
    display: none;
  }
`
const Kundenmeinungen = ({
  dark = false,
  category = 'all'
}: {
  dark?: boolean
  category?:
    | 'app'
    | 'web'
    | 'design'
    | 'consulting'
    | 'marketing'
    | 'agency'
    | 'home'
    | 'all'
}) => {
  const [showAll, setShowAll] = useState(false)

  const data =
    category === 'all'
      ? Content.reviews
      : Content.reviews.filter((r) => r.categories.includes(category))

  return (
    <Section dark={dark}>
      <div className="container">
        <Title
          title="Das sagen unsere <span>Kunden</span>"
          dark={dark}
          titleSize={0}
        />
        {data.map((item, i) => (
          <ReviewItem className="columns" index={i} key={i} showAll={showAll}>
            <div className="column is-3 is-centered">
              <div className="image-container">
                <ResponsiveImage
                  customSizes={[2000, 1800, 1400, 1200, 1]}
                  src={`/images/reviews/${item.img}`}
                  alt={item.name}
                />
              </div>
            </div>
            <div className="column is-9">
              <p>„{item.text}“</p>
              <label>
                <strong>{item.name}</strong>
                <br />
                {item.position}
                <br />
                <span>&#11088;&#11088;&#11088;&#11088;&#11088;</span>
              </label>
            </div>
          </ReviewItem>
        ))}
        {!showAll && data.length > 3 && (
          <ReviewButton
            className="button is-primary"
            onClick={() => setShowAll(true)}>
            Mehr Kundenstimmen anzeigen
          </ReviewButton>
        )}
      </div>
    </Section>
  )
}

export default Kundenmeinungen
