import SEOHeader from '@components/basics/SEOHeader'
// Sections
import HeroHeader from '@components/sections/HeroHeader'
import LandingpageContent from '@content/landingpages/webdesign/ulm.json'
import Content from '@content/leistungen/design.json'
import React, { Suspense } from 'react'
import { NavLink } from 'react-router-dom'
const LPContent = React.lazy(() => import('@components/sections/LPContent'))
const Kundenmeinungen = React.lazy(
  () => import('@components/sections/Kundenmeinungen')
)
const Kontakt = React.lazy(() => import('@components/sections/Kontakt'))
const KontaktShort = React.lazy(
  () => import('@components/sections/KontaktShort')
)
const Liste = React.lazy(() => import('@components/sections/Liste'))
const Quote = React.lazy(() => import('@components/sections/Quote'))
const Technologien = React.lazy(
  () => import('@components/sections/Technologien')
)
const Keyfacts = React.lazy(() => import('@components/sections/Keyfacts'))
const Referenzen = React.lazy(() => import('@components/sections/Referenzen'))

const LPWebDesignUlm = () => {
  return (
    <>
      <SEOHeader id="lp-webdesign-ulm" />
      <HeroHeader item={Content.header} />
      <Suspense
        fallback={
          <div className="full-page-suspense">
            <img
              width="200"
              src="/logos/peakconcepts.svg"
              alt="PEAKCONCEPTS Logo"
            />
          </div>
        }>
        <LPContent content={LandingpageContent}>
          <h2>Warum eine Webdesign Agentur in Ulm beauftragen?</h2>
          <br />

          <p>
            Im Internet buhlen zahlreiche Unternehmen mit ihren Webseiten um
            Besucher. Während es im Jahr 1992 gerade einmal 10 gab, geht es
            heute in die Milliarden. Ein starkes Webdesign in Ulm ist wichtiger
            denn je, denn nur wenn Marken mit einem unverwechselbaren Look,
            einer hohen Nutzerfreundlichkeit und einer durchdachten Struktur
            Besucher auf eine überzeugende Customer Journey schicken, besteht
            die Chance, deren Herzen dauerhaft höherschlagen zu lassen. Die
            Frage nach dem „ob“ stellt sich heute nicht mehr, sondern nur noch
            nach dem „wie“.
          </p>

          <p>
            Wir als Webdesign Agentur in Ulm übernehmen die Planung,
            Strukturierung und Implementierung Ihrer Webseite und legen damit
            das Fundament Ihrer erfolgreichen Sichtbarkeit. Wir orientieren uns
            dabei an Ihren Vorstellungen und bringen diese in Einklang mit den
            Ansprüchen Ihrer Zielgruppe. Zusammen kreieren wir ein starkes
            Markenimage, dem wir mit dem Webdesign Leben einhauchen.
          </p>

          <br />
          <h2>Unsere Leistungen im Bereich Webentwicklung und Co. in Ulm</h2>
          <br />
          <p>
            Bei uns von{' '}
            <NavLink to="/">
              <b>
                <u>Peakconcepts</u>
              </b>
            </NavLink>{' '}
            verlassen Sie sich auf einzigartige{' '}
            <NavLink to="/leistungen">
              <b>
                <u>Leistungen</u>
              </b>
            </NavLink>{' '}
            im Bereich Webentwicklung in Ulm:
          </p>
          <li>
            Individuelles Webdesign, das Ihre Marke gekonnt ins Spotlight setzt.
          </li>
          <li>
            Innovative Webentwicklung für die beste Performance Ihrer Seite.
          </li>
          <li>
            Responsive Webseiten für die volle Dynamik auf jedem Endgerät.
          </li>
          <li>
            Service und Support zu Ihrer Entlastung mit Hosting, Pflege und
            Optimierung.
          </li>
          <p>
            Wir bieten Ihnen das <b>Rundum-sorglos-Paket</b> in Sachen
            Webentwicklung in Ulm und schaffen damit langfristigen Erfolg statt
            kurzfristigem Glanz.
          </p>
          <br />
          <h2>
            Webdesign Agentur in Ulm mit Köpfen – durchdachtes Konzept für
            planbare Erfolge
          </h2>
          <br />
          <p>
            Den Start macht beim Webdesign in Ulm ein Auftaktgespräch, bei dem
            Sie uns Ihre Wünsche und Vorstellungen verraten. Zusammen mit
            unserer Expertise entsteht daraus die Grundlage für die
            Webseitenentwicklung. Im Anschluss definieren wir Ihre Ziele,
            grenzen Zielgruppen ein und analysieren Mitbewerber. Diese
            Ausarbeitung mündet in der Konzeptphase, in der wir das Grundgerüst
            inklusive Zeitplan, Meilensteinen und Struktur der Webseite
            aufstellen. Darauf aufbauend erstellen unsere kreativen Köpfe das
            Wireframe (grobe Skizzierung der späteren Webseite), das wir Ihnen
            unmittelbar vorstellen. Sind alle Entscheidungen getroffen, geht es
            für unsere Profis an die Umsetzung Ihres Webdesigns in Ulm.
            Regelmäßig halten wir Sie mit einem Tool für Prototyping auf dem
            Laufenden. Steht am Ende das Webdesign setzen wir auf Wunsch die
            Programmierung um und bringen nach einer umfangreichen Testphase
            Ihre Webseite live.
          </p>
          <br />
          <h2>Was können wir für Sie tun?</h2>
          <br />
          <p>
            Professionelles Webdesign und Webentwicklung in Ulm, auf das Sie
            sich verlassen können. Wir arbeiten seit vielen Jahren mit
            renommierten Unternehmen unterschiedlicher Branchen zusammen und
            unterstützen diese mit bodenständigen Leistungen. Darüber hinaus
            bieten wir weitere Serviceleistungen im Bereich Digitalisierung und
            App Entwicklung in Ulm an. Ganzheitlich gedacht liegt uns besonders
            gut!
          </p>
          <br />
          <p>
            Nehmen Sie jetzt{' '}
            <NavLink to="/kontakt">
              <b>
                <u>Kontakt</u>
              </b>
            </NavLink>{' '}
            zu Ihrer Webdesign Agentur in Ulm auf und lassen Sie uns gemeinsam
            Ihre Marke nicht im Netz, sondern in den Köpfen Ihrer Kunden
            platzieren.
          </p>
        </LPContent>
        <Referenzen category="web" />
        <Kundenmeinungen category="web" />
        <KontaktShort item={Content.contactShort} />
        <Keyfacts item={Content.keyfacts} />
        <Technologien technologie="web" />
        <Liste
          title={LandingpageContent.why.title}
          dark={false}
          dots={false}
          items={LandingpageContent.why.items}
        />
        <Quote title={Content.quote.title} autor={Content.quote.autor} />
        <Kontakt title={Content.contact.title} />
      </Suspense>
    </>
  )
}

export default LPWebDesignUlm
